import { FacebookShareButton, TwitterShareButton, ViberShareButton } from "react-share";
import React from "react";
import styles from "./SocialMediaShare.module.css";

const SocialMediaShare = ({ url }) => {
  return (
    <div style={{ display: "flex", flexDirection: "row", gap: "0.25em" }}>
      <FacebookShareButton
        children={<img src="/static/logo/facebook.svg" className={styles.icon} alt="" />}
        url={url}
      />
      <TwitterShareButton
        children={<img src="/static/logo/twitter.svg" className={styles.icon} alt="" />}
        url={url}
      />
      <ViberShareButton
        children={<img src="/static/logo/viber.png" className={styles.icon} alt="" />}
        url={url}
      />
    </div>
  );
};

export default SocialMediaShare;
