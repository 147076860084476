import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useRef, useState } from "react";
import { Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AiTwotoneCalendar } from "react-icons/ai";
import { Link } from "react-router-dom";
import { notifyError } from "../../App";
import { LoadingCard } from "../../components";
import Hero from "../../components/Hero/Hero";
import Pagination from "../../components/Pagination/Pagination";
import { SocialMediaShare } from "../../components/Share";
import { getBlogs } from "../../utils/api/blogs";
import { axiosErrorHandler } from "../../utils/errorHandle/axiosErrorHandler";
import { timer } from "../../utils/timer/timer";
import styles from "./Blogs.module.css";
import "./style.scss";

function Blogs() {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const lastPage = useRef(1);
  const { i18n } = useTranslation();
  const { t } = useTranslation("common");

  const assetUrl = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let mounted = true;
    let language = i18n.language === "en" ? "english" : "nepali";
    const fetchBlogs = async () => {
      setLoading(true);
      await getBlogs(page, language)
        .then((res) => {
          if (res.status === "success") {
            if (page === 1) {
              lastPage.current = res.data.last_page;
              assetUrl.current = res.data.asset_url;
            } else {
            }
            setBlogs(res.data.blogs);
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          axiosErrorHandler(err, notifyError);
        });
    };
    if (mounted) {
      fetchBlogs();
    }
    return () => (mounted = false);
  }, [page, i18n.language]);

  const renderTooltip = ({ title, props }) => (
    <Tooltip id="button-tooltip" {...props}>
      {title}
    </Tooltip>
  );

  return (
    <main className={styles.containerWrapper}>
      <Hero title={t("newsTitle")} />
      <Container>
        <div className={styles.cardContainer}>
          {loading ? (
            [...Array(blogs.length > 0 ? blogs.length : 5)].map((element, index) => (
              <LoadingCard
                key={index}
                cardStyle={styles.card}
                cardDetailsStyle={styles.eventDetails}
                photoStyle={styles.photos}
              />
            ))
          ) : blogs.length > 0 ? (
            blogs.map((blog, index) => {
              return (
                <div className={styles.card} key={index}>
                  <div className={styles.photos}>
                    <Link to={`/blog/${blog.id}`} state={{ blogData: blog }}>
                      <img
                        src={
                          blog.images.length > 0
                            ? `${assetUrl.current}/${blog.images[0].image}`
                            : ""
                        }
                        alt=""
                        className={styles.images}
                      />
                    </Link>
                  </div>
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={(props) => renderTooltip({ title: blog.title, props: props })}
                  >
                    <div className={styles.eventDetails}>
                      <Link to={`/blog/${blog.id}`}>
                        <p className={styles.eventTitle}>
                          {blog.title.length > 20
                            ? blog.title.substring(0, 19) + "....."
                            : blog.title}
                        </p>
                        <div className={styles.eventDate}>
                          <AiTwotoneCalendar />
                          {timer(blog.created_at, i18n.language)}
                        </div>
                      </Link>
                    </div>
                  </OverlayTrigger>
                  <div className={styles.socials}>
                    <SocialMediaShare url={process.env.REACT_APP_URL + "/blog/" + blog.id + "/"} />
                  </div>
                </div>
              );
            })
          ) : (
            <div className="no-data-to-show">{t("noMoreUpcoming")}</div>
          )}
        </div>
        <Pagination
          page={page}
          setPage={setPage}
          lastPage={lastPage.current}
          language={i18n.language}
        />
      </Container>
    </main>
  );
}

export default Blogs;
