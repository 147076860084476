import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { footerItems } from "./footerItems";
import styles from "./footer.module.css";

function Footer() {
  const { t } = useTranslation("footer");

  return (
    <>
      <div className="footer">
        <Container fluid>
          <Row>
            <Col md={3} style={{ marginBottom: "2em" }}>
              <div style={{ width: "150px" }} className={styles.flag}>
                <img
                  src="/static/img/flag.png"
                  style={{ width: "100%", objectFit: "cover" }}
                  alt=""
                />
              </div>
            </Col>
            {footerItems.map((element) => {
              return (
                <Col key={element.header} md={element.md}>
                  <div className="footer-link">
                    <h3>{t(element.header)}</h3>
                    <ul>
                      {element.list.map((listItem) => {
                        if (listItem.path) {
                          return (
                            <li key={listItem.path}>
                              <NavLink
                                to={listItem.path}
                                onClick={() => {
                                  window.scrollTo(0, 0);
                                }}
                              >
                                {t(listItem.title)}
                              </NavLink>
                            </li>
                          );
                        } else {
                          return (
                            <li key={listItem.value}>
                              <strong>{t(listItem.title) + ": "}</strong>
                              <span>{t(listItem.value)}</span>
                            </li>
                          );
                        }
                      })}
                    </ul>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Container>

        <div className="bg-primary bottom-footer p-3 px-5 d-flex justify-content-between w-100">
          <p className="text-white m-0">
            <span>© 2021 Nepali Congress Pokhara - 6.</span>
            <span>All rights reserved.</span>
          </p>

          <p className="text-white m-0">
            <span>Powered By: </span>
            <a href="https://www.twoacesolutions.com.np/" className="text-white m-0">
              Two Ace Solutions Pvt. Ltd.
            </a>
          </p>
        </div>
      </div>
    </>
  );
}

export default Footer;
