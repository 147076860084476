import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getContributions, getResponsibilities } from "../../utils/api/users";
import { getCookie } from "../../utils/cookie/cookies";
import { timer } from "../../utils/timer/timer";
import styles from "./Contribution.module.css";

function Contribution() {
  const [contributions, setContributions] = useState([]);
  const [responsibilities, setResponsibilities] = useState([]);
  const { i18n } = useTranslation();
  const { t } = useTranslation("userProfile");

  useEffect(() => {
    getContributions(getCookie("user_id"))
      .then((res) => {
        setContributions(res.data);
      })
      .catch((err) => {});

    getResponsibilities(getCookie("user_id")).then((res) => {
      setResponsibilities(res.data);
    });
  }, []);
  return (
    <>
      <Container className={styles.contributionContainer}>
        <div style={{ padding: "" }}>
          <h4 className={styles.conthead}>{t("party_contribution")}</h4>
          <div className={styles.hr} />
          {contributions.length < 1 && (
            <div className={styles.nodata}>No Contributions</div>
          )}

          {contributions.map((element) => (
            <Row className={styles.contributionrow}>
              <Col md={3} className={styles.contributioncol}>
                <p style={{ display: "flex", flexDirection: "column" }}>
                  <div> {timer(element.date, i18n.language)} </div>
                  <div>
                    {i18n.language === "en" ? "Role: " : "पदः "}
                    {i18n.language === "en"
                      ? element.eng_responsibility
                      : element.nep_responsibility}
                  </div>
                </p>
              </Col>
              <Col md={9}>
                <p>
                  {18n.language === "en"
                    ? element.eng_contribution
                    : element.nep_contribution}
                </p>
              </Col>
            </Row>
          ))}
          <h4 className={styles.roleshead}>{t("roles_responsibilities")}</h4>
          <div className={styles.hr} />

          {responsibilities.length < 1 && (
            <div className={styles.nodata}>No Responsibilities</div>
          )}

          {responsibilities.map((element) => (
            <Row className={styles.contributionrow} key={element.id}>
              <Col md={3} className={styles.contributioncol}>
                <p style={{ display: "flex", flexDirection: "column" }}>
                  <div>From: {timer(element.start_date, i18n.language)} </div>
                  <div>To: {timer(element.end_date, i18n.language)}</div>
                </p>
              </Col>
              <Col md={9}>
                <p>
                  {i18n.language === "en"
                    ? element.eng_responsibility
                    : element.nep_responsibility}
                </p>
              </Col>
            </Row>
          ))}
        </div>
      </Container>
    </>
  );
}

export default Contribution;
