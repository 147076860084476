import { baseGetRequest } from "./base";

const getPressReleases = async (page, language) => {
  var url = `/api/pressreleases?language=${language}&page=${page}`;
  var response = await baseGetRequest(url);
  return response;
};

const getPressReleaseById = async (id) => {
  var response = await baseGetRequest("/api/pressreleases/" + id);
  return response;
};

export { getPressReleases, getPressReleaseById };
