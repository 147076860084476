const partyItems = [
  {
    enTitle: "Constitution of Nepali Congress",
    npTitle: "नेपाली कांग्रेसको बिधान",
    enDate: "October 28th, 2020",
    npDate: "कार्तिक १२, २०७७",
    id: "1",
    path: "/static/pdf/constitution_of_nepali_congress.pdf",
    shouldTranslate: true,
  },
  {
    enTitle: "Nepali Congress Parliamentary Party's Constitution (Amendment and Revised, 2074 BS)",
    npTitle: "नेपाली कांग्रेस संसदीय दलको विधान (संशोधन तथा परिमार्जन सहित, २०७४)",
    enDate: "October 28th, 2020",
    npDate: "कार्तिक १२, २०७७",
    id: "2",
    path: "/static/pdf/nepali_congress_parliamentary_party_constitution.pdf",
    shouldTranslate: true,
  },
  {
    enTitle: "Nepali Congress Province Assembly Parliamentary Party's Constitution, 2074 BS",
    npTitle: "नेपाली कांग्रेस प्रदेश सभा संसदीय दलको विधान, २०७४",
    enDate: "October 28th, 2020",
    npDate: "कार्तिक १२, २०७७",
    id: "3",
    path: "/static/pdf/nepali_congress_province_assembly_parliamentary_party_constitution.pdf",
    shouldTranslate: true,
  },
];

export { partyItems };
