import axios from "axios";
import { getCookie } from "../cookie/cookies";

const axiosInstance = (token, options) => {
  return axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
    headers: {
      Authorization: `Bearer ${token ? token : getCookie("token")}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    ...options,
  });
};

export default axiosInstance;
