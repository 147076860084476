import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "./PartyHistory.module.css";

function PartyHistory() {
  const { t } = useTranslation("aboutParty");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Container>
        <div className={styles.philosophycont}>
          <h3 className={styles.title}>{t("partyHistory")}</h3>
          <div></div>
          <p>{t("paragrapha")}</p>
          <p>{t("paragraphb")}</p>
          <Row className={styles.imgc}>
            <Col md={4}>
              <div className={styles.imageholder}>
                <img src="/static/img/history7.jpg" className={styles.image} alt="" />
              </div>
            </Col>
            <Col md={4}>
              <div className={styles.imageholder}>
                <img src="/static/img/history3.jpg" className={styles.image} alt="" />
              </div>
            </Col>
            <Col md={4}>
              <div className={styles.imageholder}>
                <img src="/static/img/history4.jfif" className={styles.image} alt="" />
              </div>
            </Col>
          </Row>
          <p>{t("paragraphc")}</p>
          <p>{t("paragraphd")}</p>
          <p>{t("paragraphe")}</p>

          <Row className={styles.imgc}>
            <Col md={4}>
              <div className={styles.imageholder}>
                <img src="/static/img/history1.jpg" className={styles.image} alt="" />
              </div>
            </Col>
            <Col md={4}>
              <div className={styles.imageholder}>
                <img src="/static/img/history2.jpg" className={styles.image} alt="" />
              </div>
            </Col>
            <Col md={4}>
              <div className={styles.imageholder}>
                <img src="/static/img/history3.jpg" className={styles.image} alt="" />
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
}

export default PartyHistory;
