import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AiTwotoneCalendar } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { notifyError } from "../../App";
import useWindowDimensions from "../../common/useWindowDimensions";
import { LoadingCard } from "../../components";
import Hero from "../../components/Hero/Hero";
import Pagination from "../../components/Pagination/Pagination";
import { SocialMediaShare } from "../../components/Share";
//events data api
import { getEvents } from "../../utils/api/events";
import { axiosErrorHandler } from "../../utils/errorHandle/axiosErrorHandler";
import styles from "./Events.module.css";
import "./style.scss";

function Events() {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const lastPage = useRef(1);
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { t } = useTranslation("common");
  const { width } = useWindowDimensions();
  const assetUrl = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let mounted = true;
    let language = i18n.language === "en" ? "english" : "nepali";
    const fetchEvents = async () => {
      setLoading(true);
      await getEvents(page, language)
        .then((res) => {
          if (res.status === "success") {
            if (page === 1) {
              lastPage.current = res.data.last_page;
              assetUrl.current = res.data.asset_url;
            } else {
            }
            setEvents(res.data.events);
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          axiosErrorHandler(err, notifyError);
        });
    };
    if (mounted) {
      fetchEvents();
    }
    return () => (mounted = false);
  }, [page, i18n.language]);

  return (
    <main className={styles.containerWrapper}>
      <Hero title={t("eventTitle")} />

      <Container>
        <div className={styles.cardContainer}>
          {loading ? (
            [...Array(events.length > 0 ? events.length : 5)].map((element, index) => (
              <LoadingCard
                key={index}
                cardStyle={styles.card}
                cardDetailsStyle={styles.eventDetails}
                photoStyle={styles.photos}
              />
            ))
          ) : events.length > 0 ? (
            events.map((event, index) => {
              return (
                <div
                  className={styles.card}
                  key={index}
                  style={index % 2 !== 0 ? { backgroundColor: "#efefef" } : null}
                >
                  <div className={styles.photos}>
                    <Link to={`/event/${event.id}`} state={{ eventData: event }}>
                      <img
                        src={
                          event?.images?.length > 0
                            ? `${assetUrl?.current}/${event?.images[0]?.image}`
                            : ""
                        }
                        className={styles.images}
                        alt=""
                      />
                    </Link>
                  </div>
                  <div className={styles.eventDetails}>
                    <div className={styles.detailsContainer}>
                      <div
                        className={styles.eventTitle}
                        onClick={() => {
                          if (width <= 600) {
                            navigate(`/event/${event.id}`);
                          }
                        }}
                      >
                        {event.title}
                      </div>
                      <div className={styles.eventDate}>
                        <AiTwotoneCalendar />
                        {/* {timer(event.date, i18n.language)} */}
                      </div>
                    </div>
                    <div
                      className={styles.eventDescription}
                      dangerouslySetInnerHTML={{
                        __html: i18n.language === "en" ? event.description : event.description,
                      }}
                    ></div>
                    <div className={styles.bottomDiv}>
                      <Link to={`/event/${event?.id}`} state={{ eventData: event }}>
                        <div className={styles.button}>{t("readMore")}</div>
                      </Link>

                      <div>
                        <SocialMediaShare
                          url={process.env.REACT_APP_URL + "/events/" + event.id + "/"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="no-data-to-show">{t("noMoreUpcoming")}</div>
          )}
        </div>
        <Pagination page={page} setPage={setPage} lastPage={lastPage.current} />
      </Container>
    </main>
  );
}

export default Events;
