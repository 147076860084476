import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./WardPresident.module.css";
import photo from "../../assets/image/santoshpoudel.jpg";
import { useTranslation } from "react-i18next";

function WardPresident() {
  const { t } = useTranslation("aboutUs");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Container className={styles.wardpresidentCont}>
        <Row>
          <Col md={5}>
            <div className={styles.presidentPhoto}>
              <img src={photo} className={styles.presidentImg} alt="" />
            </div>
          </Col>
          <Col md={7}>
            <div className={styles.textSection}>
              <div className="section-title">
                <h3>{t("pmessage")}</h3>
                <h4>{t("presidentname")}</h4>
                <h6 className="text-primary">{t("jaynepal")}</h6>
              </div>

              <p className="pt-2">{t("messagefrompresidenta")} </p>

              <p className="">{t("messagefrompresidentb")}</p>
              <h6 className="text-primary">{t("jaynepal")}</h6>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default WardPresident;
