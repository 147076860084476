import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useRef, useState } from "react";
import { Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AiTwotoneCalendar } from "react-icons/ai";
import Lightbox from "react-image-lightbox";
import { notifyError } from "../../App";
import { LoadingCard } from "../../components";
import Hero from "../../components/Hero/Hero";
import Pagination from "../../components/Pagination/Pagination";
import { getPressReleases } from "../../utils/api/pressReleases";
import { axiosErrorHandler } from "../../utils/errorHandle/axiosErrorHandler";
import { timer } from "../../utils/timer/timer";
import styles from "./PressReleases.module.css";
import "./style.scss";

function PressReleases() {
  const [pressReleases, setPressReleases] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const lastPage = useRef(1);
  const { i18n } = useTranslation();
  const { t } = useTranslation("common");
  const assetUrl = useRef(null);

  const handleClick = (id) => {
    setIsOpen(true);
    setCurrentItem(id);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let mounted = true;
    let language = i18n.language === "en" ? "english" : "nepali";
    const fetchPressReleases = async () => {
      setLoading(true);
      await getPressReleases(page, language)
        .then((res) => {
          if (res.status === "success") {
            if (page === 1) {
              lastPage.current = res.data.last_page;
              assetUrl.current = res.data.asset_url;
            } else {
            }
            setPressReleases(res.data.press_releases);
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          axiosErrorHandler(err, notifyError);
        });
    };
    if (mounted) {
      fetchPressReleases();
    }
    return () => (mounted = false);
  }, [page, i18n.language]);
  const renderTooltip = ({ title, props }) => (
    <Tooltip id="button-tooltip" {...props}>
      {title}
    </Tooltip>
  );

  return (
    <main className={styles.containerWrapper}>
      <Hero title={t("pressRelease")} />
      <Container>
        <div className={styles.cardContainer}>
          {loading ? (
            [...Array(pressReleases.length > 0 ? pressReleases.length : 5)].map((element, index) => (
              <LoadingCard
                key={index}
                cardStyle={styles.card}
                photoStyle={styles.photos}
                cardDetailsStyle={styles.eventDetails}
              />
            ))
          ) : pressReleases.length > 0 ? (
            pressReleases.map((pressRelease, index) => {
              return (
                <div className={styles.card} key={index} onClick={() => handleClick(index)}>
                  <div className={styles.photos}>
                    <img
                      src={pressRelease.images.length > 0 ? `${assetUrl.current}/${pressRelease.images[0].image}` : ""}
                      alt=""
                      className={styles.images}
                    />
                  </div>
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={(props) =>
                      renderTooltip({
                        title: pressRelease.title,
                        props: props,
                      })
                    }
                  >
                    <div className={styles.eventDetails}>
                      <p className={styles.eventTitle}>
                        {pressRelease.title.length > 40
                          ? pressRelease.title.substring(0, 39) + "....."
                          : pressRelease.title}
                      </p>
                      <div className={styles.eventDate}>
                        <AiTwotoneCalendar />
                        {timer(pressRelease.created_at, i18n.language)}
                      </div>
                    </div>
                  </OverlayTrigger>
                </div>
              );
            })
          ) : (
            <div>
              <div></div>
              <div className="no-data-to-show">{t("noMoreUpcoming")}</div>
            </div>
          )}
          {isOpen && (
            <Lightbox
              style={{ zIndex: "2000", padding: "1em" }}
              mainSrc={new URL(pressReleases[currentItem].images[0].image, assetUrl.current).href}
              onCloseRequest={() => setIsOpen(false)}
              imageTitle={pressReleases[currentItem].title}
            />
          )}
        </div>
        <Pagination page={page} setPage={setPage} lastPage={lastPage.current} />
      </Container>
    </main>
  );
}

export default PressReleases;
