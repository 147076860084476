import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { notifyError } from "../../App";
import { Loading } from "../../components/Loading";
import { login } from "../../utils/api/auth";
import { setCookie } from "../../utils/cookie/cookies";
import { axiosErrorHandler } from "../../utils/errorHandle/axiosErrorHandler";
import styles from "./Login.module.css";
import "./style.scss";

const Login = (props) => {
  const { t } = useTranslation("login");

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loginData, setloginData] = useState({
    identification_number: "",
    password: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    setloginData({
      ...loginData,
      [e.target.id]: e.target.value,
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await login(loginData)
      .then((res) => {
        if (res.data.approval_status === "approved") {
          setCookie("token", res.data.token);
          setCookie("user_id", res.data.id);
          setCookie("identification_number", res.data.identification_number);
          setCookie("np_name", res.data.np_name);
          setCookie("eng_name", res.data.eng_name);
          if (res.data.profile_picture) {
            setCookie("profile_picture", res.data.asset_url + res.data.profile_picture);
          }
          setLoading(false);
          navigate("/auth/user/details/");
        } else if (res.data.approval_status === "pending") {
          navigate("/underreview/");
        } else if (res.data.approval_status === "rejected") {
          //navigate to resubmit form page with token on state
          navigate("/resubmit/", {
            state: { token: res.data.token, userId: res.data.id },
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        axiosErrorHandler(err, notifyError);
      });
  };

  return loading ? (
    <Loading />
  ) : (
    <section className={styles.mainContainer}>
      <Container>
        <Row>
          <Col md={8} className="m-auto">
            <div className="cc-login-form-wrapper">
              <form onSubmit={handleFormSubmit}>
                <div>
                  <label htmlFor="identification_number" style={{ fontSize: "12px" }}>
                    {t("id_num")}
                  </label>
                  <input
                    type="text"
                    className="w-100 p-2 mb-4"
                    style={{
                      border: "1px solid #ddd",
                      background: "#e8f0fe",
                      fontSize: "14px",
                    }}
                    id="identification_number"
                    placeholder={t("your_ID")}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div>
                  <label htmlFor="password" style={{ fontSize: "12px" }}>
                    {t("password")}
                  </label>
                  <input
                    type="password"
                    className="w-100 p-2 mb-2"
                    style={{
                      border: "1px solid #ddd",
                      background: "#e8f0fe",
                      fontSize: "14px",
                    }}
                    id="password"
                    placeholder={t("your_password")}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className={styles.forgotPassword} onClick={() => navigate("/resetPassword/")}>
                  {t("forgot_password")}
                </div>

                <div className="d-flex justify-content-center align-items-center mt-6 mb-4">
                  <Button type="submit">{t("login")}</Button>
                </div>
                <div className={styles.signUpContainer}>
                  {t("account")}
                  <span className={styles.signUp} onClick={() => navigate("/signup/")}>
                    {t("sign_up")}
                  </span>
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Login;
