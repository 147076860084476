import { baseGetRequest } from "./base";

const getEvents = async (page, language) => {
  var url = `/api/events?language=${language}&page=${page}`;
  var response = await baseGetRequest(url);
  return response;
};

const getEventById = async (id) => {
  var response = await baseGetRequest("/api/events/" + id);
  return response;
};

const getUpcomingEvents = async (page, language) => {
  var url = `/api/events/upcoming?language=${language}&page=${page}`;
  var response = await baseGetRequest(url);
  return response;
};

export { getEvents, getEventById, getUpcomingEvents };
