import React from "react";
import styles from "./videosAndFbpost.module.css";
import videos from "../../assets/json/videos.json";

function VideosandFbpost() {
  React.useLayoutEffect(() => {
    console.log(window.innerWidth);
  });
  return (
    <div className="container">
      <div className={styles.container}>
        <div className={styles.left}>
          {videos.map((element, index) => (
            <div className={styles.video} key={index}>
              <video width="100%" height="200" controls>
                <source src={element.video} type="video/mp4"></source>
              </video>
            </div>
          ))}
        </div>
        <div className={styles.right}>
          <div
            className={styles.fbPost}
            class="fb-page"
            data-href="https://www.facebook.com/nckaski"
            data-tabs="timeline"
            data-width={window.innerWidth > 500 ? "500" : `${window.innerWidth - 100}`}
            data-height={window.innerWidth > 500 ? "700" : `400`}
            data-small-header="true"
            data-hide-cover="false"
            data-show-facepile="true"
          >
            <blockquote cite="https://www.facebook.com/facebook" class="fb-xfbml-parse-ignore">
              <a href="https://www.facebook.com/facebook">Facebook</a>
            </blockquote>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideosandFbpost;
