import React from "react";
import styles from "./Loading.module.css";

function Loading({ loaderStyle }) {
  return (
    <div className={loaderStyle ? loaderStyle : styles.loaderContainer}>
      <img
        src="/static/img/loading.gif"
        style={{
          height: "300px",
        }}
        img=""
      />
    </div>
  );
}

export default Loading;
