import { baseGetRequest } from "./base";

const getVideos = async (page, language) => {
  var url = `/api/videos?page=${page}&language=${language}`;
  var response = await baseGetRequest(url);
  return response;
};

const getVideoById = async (id) => {
  var response = await baseGetRequest("/api/videos/" + id);
  return response;
};

export { getVideos, getVideoById };
