import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styles from "./PresidentMessage.module.css";

function PresidentMessage() {
  const { i18n } = useTranslation();
  const { t } = useTranslation("home");
  const { id } = useParams();
  const [item, setItem] = useState({});

  useEffect(() => {
    const temp = t("presidentMessage", { returnObjects: true }).filter(
      (item) => item.id === parseInt(id)
    );
    if (temp.length > 0) {
      setItem(temp[0]);
    }
  }, [i18n.language]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Container className={styles.wardpresidentCont}>
        <Row>
          <Col md={5}>
            <div className={styles.presidentPhoto}>
              <img src={item.image} className={styles.presidentImg} />
            </div>
          </Col>
          <Col md={7}>
            <div className={styles.textSection}>
              <div className="section-title">
                <h3>{item.title}</h3>
                <h4>{item.name}</h4>
              </div>

              {item.descriptions?.map((dat, i) => (
                <p className="pt-2" key={i}>
                  {dat}
                </p>
              ))}

              {item?.info?.map((dat, i) => (
                <>
                  <text className="pt-2" key={i}>
                    {dat}
                  </text>
                  <br />
                </>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default PresidentMessage;
