import cx from "classnames";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { notifyError, notifySuccess } from "../../../App";
import { updatePassword } from "../../../utils/api/auth";
import { axiosErrorHandler } from "../../../utils/errorHandle/axiosErrorHandler";
import styles from "./EditPassword.module.css";

const EditPassword = ({ loading, setLoading }) => {
  const [data, setData] = useState({
    newPassword: "",
    confirmPassword: "",
    oldPassword: "",
  });
  const [error, setError] = useState({});

  const handleSubmit = (e) => {
    setError({});
    e.preventDefault();
    let err = {};
    if (data.newPassword.length < 8) {
      err.newPassword = "Password must be atleast 8 characters long.";
    }
    if (data.confirmPassword !== data.newPassword) {
      err.confirmPassword = "Passwords donot match.";
    }
    if (data.oldPassword.length === 0) {
      err.oldPassword = "Password is required";
    }

    if (Object.keys(err).length > 0) {
      setError(err);
      return;
    }

    setLoading(true);
    updatePassword({
      old_password: data.oldPassword,
      password: data.newPassword,
    })
      .then((res) => {
        if ((res.status = "success")) {
          setLoading(false);
          notifySuccess(res.message);
          // navigate("/auth/user/profile");
        }
      })
      .catch((err) => {
        setLoading(false);
        axiosErrorHandler(err, notifyError);
      });
  };

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const { t } = useTranslation("userProfile");

  return (
    <div>
      <form className={styles.forms} autoComplete="off" onSubmit={handleSubmit}>
        <h5 className={styles.editTitle}>Edit Password</h5>
        <div className={styles.formGroup}>
          <input
            className={cx(styles.inputs, error.newPassword ? styles.error : null)}
            type="password"
            id="newPassword"
            name="newPassword"
            placeholder={t("new_password")}
            value={data.newPassword}
            onChange={handleChange}
            required
          />
          <label for="newPassword" className={styles.labels}>
            {t("new_password")}
          </label>
          {error.newPassword && <div className={styles.errorTitle}>{error.newPassword}</div>}
        </div>
        <div className={styles.formGroup}>
          <input
            className={cx(styles.inputs, error.confirmPassword ? styles.error : null)}
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            placeholder={t("confirm_password")}
            onChange={handleChange}
            value={data.confirmPassword}
            required
          />
          <label for="confirmPassword" className={styles.labels}>
            {t("confirm_password")}
          </label>
          {error.confirmPassword && (
            <div className={styles.errorTitle}>{error.confirmPassword}</div>
          )}
        </div>
        <div className={styles.formGroup}>
          <input
            className={cx(styles.inputs, error.oldPassword ? styles.error : null)}
            type="password"
            id="oldPassword"
            name="oldPassword"
            placeholder={t("password")}
            value={data.oldPassword}
            onChange={handleChange}
            required
          />
          <label for="oldPassword" className={styles.labels}>
            {t("password")}
          </label>
          {error.oldPassword && <div className={styles.errorTitle}>{error.oldPassword}</div>}
        </div>
        <div className={styles.formGroup}>
          <Button onClick={handleSubmit}>{t("submit")}</Button>
        </div>
      </form>
    </div>
  );
};

export default EditPassword;
