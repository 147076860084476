import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiTwotoneCalendar } from "react-icons/ai";
import Lightbox from "react-image-lightbox";
import { Link, useParams } from "react-router-dom";
import { LoadingCard } from "..";
import { notifyError } from "../../App";
import { Loading } from "../../components/Loading";
import {
  getHighProfileById,
  getHighProfiles,
} from "../../utils/api/highProfiles";
import { axiosErrorHandler } from "../../utils/errorHandle/axiosErrorHandler";
import { timer } from "../../utils/timer/timer";
import styles from "./Index.module.css";

function HighProfile() {
  const assetUrl = useRef(null);
  const [highProfileData, setHighProfileData] = useState({
    name: "",
    description: "",
    address: "",
    image: "",
    contributions: [],
  });
  const [data, setData] = useState([]);
  const { i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [t] = useTranslation("common");
  const [upcomingHighProfileLoading, setUpcomingHighProfileLoading] =
    useState(false);

  const { id } = useParams();

  const ageCalculate = (dob) => {
    const date = new Date() - new Date(dob);
    const diffdays = date / 1000 / (60 * 60 * 24);
    return Math.floor(diffdays / 365.25);
  };

  const fetchHighProfileData = (id) => {
    setLoading(true);
    getHighProfileById(id, i18n.language === "en" ? "english" : "nepali")
      .then((res) => {
        assetUrl.current = res.data.asset_url;
        setHighProfileData(res.data.highProfile);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        axiosErrorHandler(err, notifyError);
      });
  };

  useEffect(() => {
    setUpcomingHighProfileLoading(true);
    getHighProfiles(i18n.language === "en" ? "english" : "nepali")
      .then((res) => {
        assetUrl.current = res.data.asset_url;
        setData(res.data.high_profiles);
        setUpcomingHighProfileLoading(false);
      })
      .catch((err) => {
        setUpcomingHighProfileLoading(false);
      });
  }, [i18n.language]);

  useEffect(() => {
    fetchHighProfileData(id, i18n.language === "en" ? "english" : "nepali");
  }, [id, i18n.language]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <section className={styles.containerWrapper}>
      <div className={styles.container}>
        <div className={styles.profileDetails}>
          <div className={styles.leftProfile}>
            <div className={styles.leftp}>
              <div className={styles.pplImage}>
                <img
                  src={`${assetUrl.current}/${highProfileData.image}`}
                  alt=""
                  className={styles.image}
                />
              </div>
              <div className={styles.detailContain}>
                <div className={styles.name}>{highProfileData.name}</div>
                <div className={styles.details}>
                  <p>
                    {highProfileData.address && (
                      <span>
                        {" "}
                        Address:{highProfileData.address} <br />
                      </span>
                    )}
                    {highProfileData.email && (
                      <span>
                        {" "}
                        Email:{highProfileData.email} <br />
                      </span>
                    )}
                    Age: {ageCalculate(highProfileData?.date_of_birth)}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.rightProfile}>
            <div className={styles.contribution}>
              <div className={styles.contribution1}>
                {i18n.language === "en" ? "Description" : "विवरण"}
              </div>
              <div
                className={styles.responsibility}
                dangerouslySetInnerHTML={{
                  __html: highProfileData.description,
                }}
              ></div>
            </div>
            <div className={styles.contribution}>
              {highProfileData.contributions.length > 0 ? (
                <div className={styles.contribution1}>
                  {i18n.language === "en" ? "Contribution" : "योगदान"}
                </div>
              ) : null}
              <div>
                {highProfileData.contributions.map((dat, i) => (
                  <div className={styles.contributionMapContain}>
                    <div className={styles.headerContribution}>
                      <span> {dat.date.split("-")[0]}</span>-
                      <span className={styles.responsibility}>
                        {dat.responsibility}
                      </span>
                    </div>
                    <div className={styles.hr} />
                    <div className={styles.contributionText}>
                      {dat.contribution}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.upcomingEventsContainer}>
          <div className={styles.upcomingEventsTitle}>{t("highProfile")}</div>
          {upcomingHighProfileLoading
            ? [...Array(data.length > 0 ? data.length : 5)].map(
                (element, index) => (
                  <LoadingCard
                    key={index}
                    cardStyle={styles.card}
                    cardDetailsStyle={styles.eventDetails}
                    photoStyle={styles.photos}
                  />
                )
              )
            : data.map((data, index) => (
                <div
                  className={styles.uCard}
                  key={index}
                  style={
                    index % 2 !== 0 ? { backgroundColor: "#efefef" } : null
                  }
                >
                  <div className={styles.uPhotos}>
                    <img
                      src={`${assetUrl.current}/${data.image}`}
                      className={styles.uImages}
                      alt=""
                    />
                  </div>

                  <div className={styles.uEventDetails}>
                    <div className={styles.uDetailsContainer}>
                      <div className={styles.uEventTitle}>{data.name}</div>
                      <div className={styles.uEventDate}>
                        <AiTwotoneCalendar />
                        {timer(data.created_at, i18n.language)}
                      </div>
                    </div>
                    <div
                      className={styles.uEventDescription}
                      dangerouslySetInnerHTML={{
                        __html:
                          i18n.language === "en"
                            ? data.description
                            : data.description,
                      }}
                    ></div>
                    <Link
                      to={`/about/highprofile/${data.id}`}
                      onClick={() => {
                        window.scrollTo(0, 0);
                      }}
                    >
                      <button
                        className={styles.button}
                        style={
                          index % 2 !== 0
                            ? { backgroundColor: "#efefef" }
                            : null
                        }
                      >
                        Read More
                      </button>
                    </Link>
                  </div>
                </div>
              ))}
        </div>
        {isOpen && (
          <Lightbox
            style={{ zIndex: "2000", padding: "1em" }}
            mainSrc={new URL(highProfileData.image, assetUrl.current).href}
            onCloseRequest={() => setIsOpen(false)}
          />
        )}
      </div>
    </section>
  );
}

export default HighProfile;
