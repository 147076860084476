import cx from "classnames";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { FileUploader } from "react-drag-drop-files";
import { useTranslation } from "react-i18next";
import { AiFillEye } from "react-icons/ai";
import Lightbox from "react-image-lightbox";
import NepaliInput from "../../components/Input/NepaliInput";
import { NumberInput } from "../Input";
import styles from "./UserDetailsForm.module.css";

export default function UserDetailsForm({
  data,
  setData,
  file,
  handleFileChange,
  error,
  setError,
  signup,
  onSubmit,
  resubmit,
}) {
  const { t } = useTranslation("userProfile");
  const [isOpen, setIsOpen] = useState(false);

  return (
    <form className={styles.forms}>
      {signup && <div className={styles.signUpTitle}>Fill the details to create an account</div>}
      <div className={styles.title}>General Information</div>
      <div className={styles.formGroup}>
        <div className={styles.formItem}>
          <input
            className={cx(styles.inputs, error.name ? styles.error : null)}
            type="text"
            id="engName"
            placeholder="Name"
            value={data.english.name}
            onChange={(e) =>
              setData({
                ...data,
                english: { ...data.english, name: e.target.value },
              })
            }
          ></input>
          <label for="engName" className={styles.labels}>
            Name
          </label>
          {error.enName && <div className={styles.errorTitle}>{error.enName}</div>}
        </div>
        <div className={styles.formItem}>
          <NepaliInput
            inputClass={cx(styles.inputs, error.name ? styles.error : null)}
            id="npName"
            placeholder="नाम"
            text={data.nepali.name}
            setText={(val) => {
              setData({ ...data, nepali: { ...data.nepali, name: val } });
            }}
          />
          <label for="npName" className={styles.labels}>
            नाम
          </label>
          {error.npName && <div className={styles.errorTitle}>{error.npName}</div>}
        </div>
      </div>
      <div className={styles.formGroup}>
        <div className={styles.formItem}>
          <input
            className={cx(styles.inputs, error.name ? styles.error : null)}
            type="text"
            id="enFatherName"
            placeholder="Father's name"
            value={data.english.father_name}
            onChange={(e) =>
              setData({
                ...data,
                english: { ...data.english, father_name: e.target.value },
              })
            }
          ></input>
          <label for="enFatherName" className={styles.labels}>
            Father's Name
          </label>
          {/* {error.name && (
      <div className={styles.errorTitle}>{error.name}</div>
    )} */}
        </div>
        <div className={styles.formItem}>
          <NepaliInput
            inputClass={cx(styles.inputs, error.name ? styles.error : null)}
            id="npFatherName"
            placeholder="नाम"
            text={data.nepali.father_name}
            setText={(val) => {
              setData({
                ...data,
                nepali: { ...data.nepali, father_name: val },
              });
            }}
          />
          <label for="npFatherName" className={styles.labels}>
            बुबाको नाम
          </label>
          {/* {error.name && (
      <div className={styles.errorTitle}>{error.name}</div>
    )} */}
        </div>
      </div>
      <div className={styles.formGroup}>
        <div className={styles.formItem}>
          <input
            className={cx(styles.inputs, error.name ? styles.error : null)}
            type="text"
            id="engMotherName"
            placeholder="Mother's Name"
            value={data.english.mother_name}
            onChange={(e) =>
              setData({
                ...data,
                english: { ...data.english, mother_name: e.target.value },
              })
            }
          ></input>
          <label for="engMotherName" className={styles.labels}>
            Mother's Name
          </label>
          {/* {error.name && (
      <div className={styles.errorTitle}>{error.name}</div>
    )} */}
        </div>
        <div className={styles.formItem}>
          <NepaliInput
            inputClass={cx(styles.inputs, error.name ? styles.error : null)}
            id="npMotherName"
            placeholder="आमाको नाम"
            text={data.nepali.mother_name}
            setText={(val) => {
              setData({
                ...data,
                nepali: { ...data.nepali, mother_name: val },
              });
            }}
          />
          <label for="npMotherName" className={styles.labels}>
            आमाको नाम
          </label>
          {/* {error.name && (
      <div className={styles.errorTitle}>{error.name}</div>
    )} */}
        </div>
      </div>
      <div className={styles.formGroup}>
        <div className={styles.formItem}>
          <input
            className={cx(styles.inputs, error.name ? styles.error : null)}
            type="text"
            id="engGrandfatherName"
            placeholder="Grandfather's Name"
            value={data.english.grandfather_name}
            onChange={(e) =>
              setData({
                ...data,
                english: {
                  ...data.english,
                  grandfather_name: e.target.value,
                },
              })
            }
          ></input>
          <label for="engGrandfatherName" className={styles.labels}>
            Grandfather's Name
          </label>
          {/* {error.name && (
      <div className={styles.errorTitle}>{error.name}</div>
    )} */}
        </div>
        <div className={styles.formItem}>
          <NepaliInput
            inputClass={cx(styles.inputs, error.name ? styles.error : null)}
            id="npGrandfatherName"
            placeholder="हजुरबुबाको नाम"
            text={data.nepali.grandfather_name}
            setText={(val) => {
              setData({
                ...data,
                nepali: { ...data.nepali, grandfather_name: val },
              });
            }}
          />
          <label for="npGrandfatherName" className={styles.labels}>
            हजुरबुवाको नाम
          </label>
          {/* {error.name && (
      <div className={styles.errorTitle}>{error.name}</div>
    )} */}
        </div>
      </div>
      <div className={styles.formGroup}>
        <div className={styles.formItem}>
          <input
            className={cx(styles.inputs, error.name ? styles.error : null)}
            type="text"
            id="engGrandmotherName"
            placeholder="Grandmother's Name"
            value={data.english.grandmother_name}
            onChange={(e) =>
              setData({
                ...data,
                english: {
                  ...data.english,
                  grandmother_name: e.target.value,
                },
              })
            }
          ></input>
          <label for="engGrandmotherName" className={styles.labels}>
            Grandmother's Name
          </label>
          {/* {error.name && (
      <div className={styles.errorTitle}>{error.name}</div>
    )} */}
        </div>
        <div className={styles.formItem}>
          <NepaliInput
            inputClass={cx(styles.inputs, error.name ? styles.error : null)}
            id="npGrandmotherName"
            placeholder="हजुरआमाको नाम"
            text={data.nepali.grandmother_name}
            setText={(val) => {
              setData({
                ...data,
                nepali: { ...data.nepali, grandmother_name: val },
              });
            }}
          />
          <label for="npGrandmotherName" className={styles.labels}>
            हजुरआमाको नाम
          </label>
          {/* {error.name && (
      <div className={styles.errorTitle}>{error.name}</div>
    )} */}
        </div>
      </div>
      <div className={styles.formGroup}>
        <div className={styles.formItem}>
          <input
            className={cx(styles.inputs, error.name ? styles.error : null)}
            type="text"
            id="engPartnerName"
            placeholder="Partner's Name"
            value={data.english.partner_name}
            onChange={(e) =>
              setData({
                ...data,
                english: {
                  ...data.english,
                  partner_name: e.target.value,
                },
              })
            }
          ></input>
          <label for="engPartnerName" className={styles.labels}>
            Partner's Name
          </label>
          {/* {error.name && (
      <div className={styles.errorTitle}>{error.name}</div>
    )} */}
        </div>
        <div className={styles.formItem}>
          <NepaliInput
            inputClass={cx(styles.inputs, error.name ? styles.error : null)}
            id="npPartnerName"
            placeholder="पति/पत्नीको नाम"
            text={data.nepali.partner_name}
            setText={(val) => {
              setData({
                ...data,
                nepali: { ...data.nepali, partner_name: val },
              });
            }}
          />
          <label for="npPartnerName" className={styles.labels}>
            पति/पत्नीको नाम
          </label>
          {/* {error.name && (
      <div className={styles.errorTitle}>{error.name}</div>
    )} */}
        </div>
      </div>
      <div className={styles.personalInfo}>
        <div className={styles.title}>Personal Information</div>
        <div className={styles.formGroup}>
          <div className={styles.formItem}>
            <input
              className={cx(styles.inputs, error.name ? styles.error : null)}
              type="text"
              id="engAddress"
              placeholder="Address"
              value={data.english.address}
              onChange={(e) =>
                setData({
                  ...data,
                  english: {
                    ...data.english,
                    address: e.target.value,
                  },
                })
              }
            ></input>
            <label for="engAddress" className={styles.labels}>
              Address
            </label>
            {/* {error.name && (
      <div className={styles.errorTitle}>{error.name}</div>
    )} */}
          </div>
          <div className={styles.formItem}>
            <NepaliInput
              inputClass={cx(styles.inputs, error.name ? styles.error : null)}
              id="npAddress"
              placeholder="ठेगाना"
              text={data.nepali.address}
              setText={(val) => {
                setData({
                  ...data,
                  nepali: { ...data.nepali, address: val },
                });
              }}
            />
            <label for="npAddress" className={styles.labels}>
              ठेगाना
            </label>
            {/* {error.name && (
      <div className={styles.errorTitle}>{error.name}</div>
    )} */}
          </div>
        </div>
        <div className={styles.formGroup}>
          <div className={styles.formItem}>
            <input
              className={cx(styles.inputs, error.name ? styles.error : null)}
              type="date"
              id="dob"
              placeholder="Date of birth"
              value={data.date_of_birth || ""}
              onChange={(e) =>
                setData({
                  ...data,
                  date_of_birth: e.target.value,
                })
              }
            ></input>
            <label for="dob" className={styles.labels}>
              Date of birth
            </label>
            {error.dob && <div className={styles.errorTitle}>{error.dob}</div>}
          </div>
          <div className={styles.formItem}>
            <input
              className={cx(styles.inputs, error.name ? styles.error : null)}
              type="date"
              id="doj"
              placeholder="Date of joining"
              value={data.date_of_joining}
              onChange={(e) =>
                setData({
                  ...data,
                  date_of_joining: e.target.value,
                })
              }
            ></input>
            <label for="doj" className={styles.labels}>
              Date of joining
            </label>
            {/* {error.name && (
      <div className={styles.errorTitle}>{error.name}</div>
    )} */}
          </div>
        </div>
        <div className={styles.formGroup}>
          <div className={styles.formItem}>
            <input
              className={cx(styles.inputs, error.name ? styles.error : null)}
              type="text"
              id="familyContact"
              placeholder="Family Contact"
              value={data.family_contact_number}
              onChange={(e) => {
                if (!isNaN(e.target.value)) {
                  setData({
                    ...data,
                    family_contact_number: e.target.value,
                  });
                }
              }}
            ></input>
            <label for="familyContact" className={styles.labels}>
              Family Contact
            </label>
            {/* {error.name && (
      <div className={styles.errorTitle}>{error.name}</div>
    )} */}
          </div>
          <div className={styles.formItem}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                color: "black",
              }}
            >
              <label>Gender</label>

              <div
                style={{
                  gap: "1.5em",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {["male", "female", "others"].map((element, index) => (
                  <div
                    style={{
                      gap: "0.5em",
                      display: "flex",
                      alignItems: "center",
                    }}
                    key={index}
                  >
                    <input
                      type="radio"
                      value={element}
                      id={element}
                      name={element}
                      checked={data.gender.toLocaleUpperCase() === element.toLocaleUpperCase()}
                      onChange={(e) => {
                        setData({ ...data, gender: e.target.value });
                      }}
                    />
                    <label for={element} style={{ textTransform: "capitalize" }}>
                      {t(element)}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.formGroup}>
          <div className={styles.formItem}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                gap: ".5em",
              }}
            >
              <label for="category">Category</label>
              <select
                className={cx(styles.dropdown, styles.select)}
                name="category"
                id="category"
                value={data.category}
                onChange={(e) =>
                  setData({
                    ...data,
                    category: e.target.value,
                  })
                }
              >
                <option value="female">Female (महिला)</option>
                <option value="dalit">Dalit (दलित)</option>
                <option value="janajati">Janajati (जनजाति)</option>
                <option value="Khas arya">Khas Arya (खस् आर्य)</option>
                <option value="dalit">Dalit (दलित)</option>
                <option value="alpashankhya">Alpashankhya (अल्पसङ्ख्य)</option>
                <option value="others">Others (अन्य)</option>
              </select>
            </div>
          </div>
        </div>
        <div className="d-flex">
          <div>
            <label for="category">Children</label>
          </div>
          <div
            className={styles.addd}
            onClick={() => {
              const datta = { ...data };
              datta.children = [
                ...datta.children,
                {
                  english: { name: "" },
                  nepali: { name: "" },
                },
              ];
              setData(datta);
            }}
          >
            <Button style={{ fontSize: "1.2rem" }}>+</Button>
          </div>
        </div>
        {data.children.map((dat, i) => (
          <div key={i} className={`${styles.formGroup} ${styles.animation}`}>
            <div className={styles.formItem}>
              <input
                className={cx(styles.inputs, error.name ? styles.error : null)}
                type="text"
                id={`childrenName${i}`}
                placeholder="Children Name"
                value={dat.english.name}
                onChange={(e) => {
                  const datta = { ...data };
                  datta.children[i].english.name = e.target.value;
                  datta.children[i].submit = true;
                  setData(datta);
                }}
              ></input>
              <label for={`childrenName${i}`} className={styles.labels}>
                Children Name
              </label>
              {error?.children[i]?.english?.length > 0 ? (
                <div className={styles.errorTitle}>{error.children[i]?.english}</div>
              ) : null}
            </div>
            <div className={styles.formItem}>
              <NepaliInput
                inputClass={cx(styles.inputs, error.name ? styles.error : null)}
                id={`npAddress${i}`}
                placeholder="ठेगाना"
                text={dat.nepali.name}
                setText={(val) => {
                  const datta = { ...data };
                  datta.children[i].nepali.name = val;
                  datta.children[i].submit = true;
                  setData(datta);
                }}
              />
              <label for={`npAddress${i}`} className={styles.labels}>
                बालबालिकाको नाम
              </label>

              {error?.children[i]?.nepali?.length > 0 && (
                <div className={styles.errorTitle}>{error.children[i].nepali}</div>
              )}
            </div>
            <div
              className={styles.addd}
              onClick={() => {
                const datta = { ...data };
                const arrayChildren = datta.children;

                const r = arrayChildren.splice(i, 1);

                if (r[0].id) {
                  // deleteId.current.push(r[0].id);
                  // datta.removedId.push(r[0].id);
                  datta.removeId = [...(datta.removeId || []), r[0].id];
                }

                datta.children = arrayChildren;
                setData(datta);
              }}
            >
              <Button style={{ fontSize: "2rem" }}>-</Button>
            </div>
          </div>
        ))}
        <div className={styles.formGroup} style={{ marginTop: "1.5em" }}>
          <div className={styles.formItem}>
            <FileUploader
              handleChange={handleFileChange}
              name="file"
              types={["jpg", "png", "gif"]}
              label="Upload your citizenship document"
              onTypeError={(file) => {
                setError({
                  ...error,
                  file: "Select a file of jpg, png or gif format.",
                });
              }}
              onSizeError={(file) => {
                setError({ ...error, file: "Select a file less than 5MB." });
              }}
              onSelect={() => {
                setError({ ...error, file: null });
              }}
              maxSize={5}
            />
            {error?.file && (
              <div className={styles.errorTitle} style={{ marginTop: "1em" }}>
                {error.file}
              </div>
            )}
          </div>
          <div className={styles.formItem}>
            {file.preview && (
              <div className={styles.photoIconContainer} onClick={() => setIsOpen(true)}>
                <AiFillEye className={styles.photoIcon} />
              </div>
            )}
            {isOpen && (
              <Lightbox
                style={{ zIndex: "2000", padding: "1em" }}
                mainSrc={file.preview}
                onCloseRequest={() => setIsOpen(false)}
              />
            )}
          </div>
        </div>
      </div>
      {signup && (
        <div className={styles.userProfile}>
          <div className={styles.title}>User Profile</div>
          <div className={styles.formGroup}>
            <div className={styles.formItem}>
              <input
                className={cx(styles.inputs, error.name ? styles.error : null)}
                id="identificationNumber"
                placeholder="Identification/क्रियाशील number"
                value={data.identification_number}
                onChange={(e) =>
                  setData({
                    ...data,
                    identification_number: e.target.value,
                  })
                }
              ></input>
              <label for="identificationNumber" className={styles.labels}>
                Identification/क्रियाशील number
              </label>
              {error.identificationNumber && (
                <div className={styles.errorTitle}>{error.identificationNumber}</div>
              )}
            </div>
          </div>
          <div className={styles.formGroup}>
            <div className={styles.formItem}>
              <NumberInput
                inputClass={cx(styles.inputs, error.name ? styles.error : null)}
                type="text"
                id="phoneNumber"
                placeholder="Phone Number"
                text={data.phone_number}
                setText={(val) =>
                  setData({
                    ...data,
                    phone_number: val,
                  })
                }
              />
              <label for="phoneNumber" className={styles.labels}>
                Phone Number
              </label>
              {error.phoneNumber && <div className={styles.errorTitle}>{error.phoneNumber}</div>}
            </div>
            <div className={styles.formItem}>
              <input
                className={cx(styles.inputs, error.name ? styles.error : null)}
                type="text"
                id="email"
                placeholder="Email"
                value={data.email}
                onChange={(e) =>
                  setData({
                    ...data,
                    email: e.target.value,
                  })
                }
              ></input>
              <label for="email" className={styles.labels}>
                Email
              </label>
              {error.email && <div className={styles.errorTitle}>{error.email}</div>}
            </div>
          </div>
          {!resubmit && (
            <div className={styles.formGroup}>
              <div className={styles.formItem}>
                <input
                  className={cx(styles.inputs, error.name ? styles.error : null)}
                  type="password"
                  id="password"
                  placeholder="Password"
                  value={data.password}
                  onChange={(e) =>
                    setData({
                      ...data,
                      password: e.target.value,
                    })
                  }
                ></input>
                <label for="password" className={styles.labels}>
                  Password
                </label>
                {error.password && <div className={styles.errorTitle}>{error.password}</div>}
              </div>
              <div className={styles.formItem}>
                <input
                  className={cx(styles.inputs, error.name ? styles.error : null)}
                  type="password"
                  id="confirmPassword"
                  placeholder="Confirm Password"
                  value={data.confirm_password}
                  onChange={(e) =>
                    setData({
                      ...data,
                      confirm_password: e.target.value,
                    })
                  }
                ></input>
                <label for="confirmPassword" className={styles.labels}>
                  Confirm Password
                </label>
                {error.confirmPassword && (
                  <div className={styles.errorTitle}>{error.confirmPassword}</div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
      <div>
        {Object.keys(error)?.length > 1 || error.children?.length > 0 ? (
          <div style={{ marginTop: "1em" }} className={styles.errorTitle}>
            {t("Please fill all the task")}
          </div>
        ) : null}
        <Button style={{ marginTop: "0em" }} onClick={onSubmit}>
          {t("submit")}
        </Button>
      </div>
    </form>
  );
}
