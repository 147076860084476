import React, { useEffect, useRef, useState } from "react";
import { Container, Placeholder } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AiFillVideoCamera } from "react-icons/ai";
import { notifyError } from "../../App";
import Hero from "../../components/Hero/Hero";
import Pagination from "../../components/Pagination/Pagination";
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";
import { getVideos } from "../../utils/api/videoGallery";
import { axiosErrorHandler } from "../../utils/errorHandle/axiosErrorHandler";
import { timer } from "../../utils/timer/timer";
import styles from "./Video.module.css";
// import YouTube from "react-youtube";

function Video() {
  const [videos, setVideos] = useState([]);
  const [currentVideo, setCurrentVideo] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const lastPage = useRef(1);
  const { i18n } = useTranslation();
  const { t } = useTranslation(["navbar", "common"]);

  const assetUrl = useRef(null);

  const handleClick = (index) => {
    setCurrentVideo(index);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let mounted = true;
    let language = i18n.language === "en" ? "english" : "nepali";
    const fetchVideos = () => {
      setLoading(true);
      getVideos(page, language)
        .then((res) => {
          if (res.status === "success") {
            if (page === 1) {
              lastPage.current = res.data.last_page;
              assetUrl.current = res.data.asset_url;
            } else {
            }
            setCurrentVideo(0);
            setVideos(res.data.videos);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          axiosErrorHandler(err, notifyError);
        });
    };
    if (mounted) {
      fetchVideos();
    }
    return () => (mounted = false);
  }, [page, i18n.language]);

  return (
    <>
      <Hero title={t("video_gallery")} />
      {videos.length > 0 ? (
        <div className={styles.containerWrapper}>
          <Container>
            <div className={styles.container}>
              <div className={styles.preview}>
                {videos.length > 0 ? (
                  <div>
                    <VideoPlayer
                      src={videos[currentVideo].video}
                      type="video/mp4"
                      key={videos[currentVideo].id}
                    />
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div style={{ fontSize: "1.7rem" }}>{videos[currentVideo].title}</div>
                      <div style={{ fontSize: ".8rem" }}>
                        {timer(videos[currentVideo].created_at, i18n.language)}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className={styles.videoList}>
                <div className={styles.videoHeaderTitle}>
                  {i18n.language === "en" ? "Videos List" : "भिडियो पुस्तिका"}
                </div>
                <div>
                  {videos.map((video, index) => (
                    <div
                      key={video.id}
                      onClick={() => {
                        if (!loading) {
                          handleClick(index);
                        }
                      }}
                      className={styles.videoItems}
                      style={currentVideo === index ? { color: "red" } : null}
                    >
                      {loading ? (
                        <Placeholder xs={3} size="lg" style={{ background: "#c1c1c1" }} />
                      ) : (
                        <AiFillVideoCamera className={styles.videoIcon} />
                      )}
                      <div className={styles.videoDetails}>
                        <div className={styles.videoTitle}>
                          {loading ? (
                            <Placeholder
                              xs={6}
                              size="sm"
                              style={{ width: "100%", background: "#c1c1c1" }}
                            />
                          ) : (
                            video.title
                          )}
                        </div>
                        <div className={styles.videoDate}>
                          {loading ? (
                            <Placeholder xs={6} style={{ width: "80%", background: "#c1c1c1" }} />
                          ) : (
                            timer(video.created_at, i18n.language)
                          )}
                        </div>
                        {currentVideo === index && (
                          <span className={styles.nowPlaying}>
                            {loading ? (
                              <Placeholder
                                xs={6}
                                size="xs"
                                style={{ width: "35%", background: "#c1c1c1" }}
                              />
                            ) : (
                              "Now playing..."
                            )}
                          </span>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                <Pagination page={page} setPage={setPage} lastPage={lastPage.current} />
              </div>
            </div>
          </Container>
        </div>
      ) : (
        <div className="no-data-to-show">{t("noMoreUpcoming", { ns: "common" })}</div>
      )}
    </>
  );
}

export default Video;
