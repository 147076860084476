import React, { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { notifyError } from "../../App";
import useWindowDimensions from "../../common/useWindowDimensions";
import Hero from "../../components/Hero/Hero";
import { Loading } from "../../components/Loading";
import { SocialMediaShare } from "../../components/Share";
import { getHighProfiles } from "../../utils/api/highProfiles";
import { axiosErrorHandler } from "../../utils/errorHandle/axiosErrorHandler";
import styles from "./HighProfiles.module.css";

function HighProfiles() {
  const { width } = useWindowDimensions();
  const [highProfile, setHighProfile] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const assetUrl = useRef(null);
  const { i18n } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let mounted = true;
    const fetchHighProfile = () => {
      setLoading(true);
      getHighProfiles(i18n.language === "en" ? "english" : "nepali")
        .then((res) => {
          if (res.status === "success") {
            setHighProfile(res.data.high_profiles);
            assetUrl.current = res.data.asset_url;
          } else {
            setLoading(false);
          }
          setLoading(false);
        })
        .catch((err) => {
          axiosErrorHandler(err, notifyError);
          setLoading(false);
        });
    };
    if (mounted) {
      fetchHighProfile();
    }
    return () => (mounted = false);
  }, [i18n.language]);
  return loading ? (
    <Loading />
  ) : (
    <div className={styles.samitiContainer}>
      <Hero
        title={i18n.language === "en" ? "High Profile" : "उच्च व्यक्तित्व"}
      />
      <Container>
        <div className={styles.samitiInnerContainer}>
          {highProfile.map((element, index) => (
            <div
              className={styles.card}
              onClick={() => navigate(`/about/highprofile/${element.id}`)}
            >
              <div className={styles.cardHeader}>
                <img
                  className={styles.image}
                  src={assetUrl.current + element.image}
                  alt=""
                />
              </div>
              <div className={styles.cardBody}></div>
              <div className={styles.cardAbsolute}>
                <div className={styles.highprofillename}>{element.name}</div>

                <div className={styles.cardfooter}>
                  <div className={styles.iconcontain}>
                    <SocialMediaShare
                      url={
                        process.env.REACT_APP_URL +
                        "/highprofile/" +
                        element.id +
                        "/"
                      }
                    />
                  </div>
                  <div className={styles.button}>Read More</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
}

export default HighProfiles;

//  {highProfile.map((element, index) => (
//             <Row className={styles.overlay} key={element.id}>
//               <div className={styles.samitiImage}>
//                 <img
//                   src={assetUrl.current + element.image}
//                   alt=""
//                   className={styles.image}
//                 />
//               </div>
//               <Col md={8} className={styles.paddingMycol}>
//                 <div className={styles.samitiDescription}>
//                   <h4 className={styles.titleb}>{element.name}</h4>

//                   <p
//                     className={styles.descriptionText}
//                     dangerouslySetInnerHTML={{
//                       __html: element.description,
//                     }}
//                   ></p>
//                   <div className={styles.buttonContainer}>
//                     <Link to={`/about/highprofile/${element.id}`}>
//                       <div
//                         style={
//                           width >= 767
//                             ? index % 2 === 0
//                               ? { order: "-1" }
//                               : { order: "1" }
//                             : { order: "1" }
//                         }
//                         className={styles.button}
//                       >
//                         Read More
//                       </div>
//                     </Link>
//                     <div className={styles.memberIcons}>
//                       <SocialMediaShare
//                         url={
//                           process.env.REACT_APP_URL +
//                           "/highprofile/" +
//                           element.id +
//                           "/"
//                         }
//                       />
//                     </div>
//                   </div>
//                 </div>
//               </Col>
//             </Row>
//           ))}
