import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useTranslation } from "react-i18next";
import historyData from "../../assets/json/history.json";

const HistorySlider = () => {
  const { i18n } = useTranslation();
  const { t } = useTranslation("home");

  const historySliderSetting = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <IoIosArrowForward />,
    prevArrow: <IoIosArrowBack />,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  return (
    <section className="main-section">
      <Container>
        <Row>
          <Col md={12}>
            <div className="history">
              <div className="section-title">
                <h3 className="text-dark text-center px-2" style={{ marginBottom: "1em" }}>
                  {t("history")}
                </h3>
                <div className="history-wrapper">
                  <Slider {...historySliderSetting}>
                    {historyData.map((element, index) => (
                      <div className="history-list" key={element.id}>
                        <Row>
                          <Col lg={6}>
                            <div
                              className="history-bg-image m-auto"
                              style={{
                                backgroundImage: element.background_image,
                              }}
                            >
                              <div className="history-image-sm">
                                <img src={element.image} alt="" />
                              </div>
                            </div>
                          </Col>
                          <Col lg={6} className="m-auto">
                            <div className="text-center pe-4">
                              <p className="text-dark  history-title">
                                {i18n.language === "en" ? element.en_title : element.np_title}
                              </p>
                              <p className="history-date">
                                {i18n.language === "en" ? element.en_date : element.np_date}
                              </p>
                              <p className="history-content">
                                {i18n.language === "en" ? element.en_description : element.np_description}
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HistorySlider;
