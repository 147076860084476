import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Lightbox from "react-image-lightbox";
import { useNavigate } from "react-router-dom";
import photos from "../../assets/json/home-photos.json";

const Photos = () => {
  const { t } = useTranslation("home");
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [currentPhoto, setCurrentPhoto] = useState(1);

  const handleOpen = (id) => {
    setIsOpen(true);
    setCurrentPhoto(id);
  };

  return (
    <section className="main-section bg-dark">
      <Container>
        <Row>
          <Col md={12}>
            <div className="gallery-photos">
              <div className="section-title">
                <h3 className="text-white text-center px-2">{t("photo")}</h3>
              </div>
              <div className="photos-wrapper pt-3 gap-3">
                {photos.map((element, index) => (
                  <div className="gallery-image" key={element.id}>
                    <img src={element.image} alt="" onClick={() => handleOpen(index)} />
                  </div>
                ))}
              </div>

              <div className="text-center pt-5">
                <button className="btn btn-primary" onClick={() => navigate("/media/photos/")}>
                  {t("view_all")}
                </button>
              </div>
            </div>
          </Col>
        </Row>
        {isOpen && (
          <Lightbox
            style={{ zIndex: "2000", padding: "1em" }}
            mainSrc={photos[currentPhoto].image}
            nextSrc={photos[(currentPhoto + 1) % photos.length].image}
            prevSrc={photos[(currentPhoto + photos.length - 1) % photos.length].image}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() => setCurrentPhoto((currentPhoto + photos.length - 1) % photos.length)}
            onMoveNextRequest={() => setCurrentPhoto((currentPhoto + 1) % photos.length)}
          />
        )}
      </Container>
    </section>
  );
};

export default Photos;
