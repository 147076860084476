import { baseGetRequest } from "./base";

const getVatriSangathans = async (language) => {
  var url = `/api/vatrisangathans?language=${language}`;
  var response = await baseGetRequest(url);
  return response;
};

const getVatriSangathanById = async (id) => {
  var response = await baseGetRequest("/api/vatrisangathans/" + id);
  return response;
};

export { getVatriSangathanById, getVatriSangathans };
