import React, { useRef, useState, useEffect } from "react";
import Modal from "./Modal";

export default function ImageModal({ src, open, onClose }) {
  const imageRef = useRef(null);
  const [divRef, setDivRef] = useState(null);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (
        imageRef.current &&
        divRef &&
        !imageRef.current.contains(event.target) &&
        divRef.contains(event.target)
      ) {
        onClose();
      }
    }

    if (imageRef.current && divRef) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [imageRef, divRef]);
  return (
    <Modal open={open} onClose={onClose} setDivRef={setDivRef}>
      <img
        src={src}
        style={{
          margin: "auto",
          display: "block",
          width: "100%",
          maxWidth: "700px",
        }}
        alt=""
        ref={imageRef}
      />
    </Modal>
  );
}
