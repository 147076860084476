import React, { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { notifyError } from "../../App";
import { Loading } from "../../components/Loading";
import Pagination from "../../components/Pagination/Pagination";
import TableShow from "../../components/Table/Table";
import { getGeneralUsers } from "../../utils/api/users";
import { axiosErrorHandler } from "../../utils/errorHandle/axiosErrorHandler";
import styles from "./GeneralMember.module.css";

function GeneralMember() {
  const { t } = useTranslation("common");
  const { i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const lastPage = useRef(1);
  const snRef = useRef(null);
  const [generalMembers, setGeneralMembers] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let mounted = true;
    let language = i18n.language === "en" ? "english" : "nepali";
    const fetchGeneralMembers = async () => {
      setLoading(true);
      await getGeneralUsers(page, language)
        .then((res) => {
          if (res.status === "success") {
            if (page === 1) {
              lastPage.current = res.data.last_page;
              snRef.current = res.data.users.length;
            } else {
            }
            setGeneralMembers(res.data.users);
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          axiosErrorHandler(err, notifyError);
        });
    };
    if (mounted) {
      fetchGeneralMembers();
    }
    return () => (mounted = false);
  }, [page, i18n.language]);

  return (
    <div className={styles.containerWrapper}>
      <Container>
        <div className={styles.container}>
          <div className={styles.imageContainer}>
            <img
              src="/static/img/congress-flag-waving.png"
              className={styles.image}
              alt=""
            />
          </div>
          <div className={styles.titleContainer}>
            <div className={styles.topTitle}>{t("publishedBy")}</div>
            <div className={styles.partyName}>{t("partyName")}</div>
            <div className={styles.activeTitle}>{t("generalMemberName")}</div>
            <div className={styles.locationContainer}>
              <div className={styles.location}>
                <span className={styles.typeTitle}>{t("type")}: </span>
                <span className={styles.typeSubtitle}>{t("new")}</span>
              </div>
              <div className={styles.district}>
                <span className={styles.districtTitle}>{t("district")}: </span>
                <span className={styles.districtSubtitle}>
                  {t("districtName")}
                </span>
              </div>
            </div>
            <div className={styles.addressContainer}>
              <div className={styles.constitution}>
                <span className={styles.districtTitle}>
                  {t("representativeAssembly")}:{" "}
                </span>
                <span className={styles.districtSubtitle}>
                  {i18n.language === "en" ? "3" : "३"}
                </span>
              </div>
              <div className={styles.address}>
                <span className={styles.districtTitle}>
                  {t("StateAssembly")}:{" "}
                </span>
                <span className={styles.districtSubtitle}>
                  {i18n.language === "en" ? "2" : "२"}
                </span>
              </div>
            </div>
            <div className={styles.metropolitanContainer}>
              <div className={styles.metropolitan}>
                <span className={styles.districtTitle}>
                  {t("metropolitan")}:{" "}
                </span>
                <span className={styles.districtSubtitle}>
                  {t("metroName")}
                </span>
              </div>
              <div className={styles.ward}>
                <span className={styles.districtTitle}>{t("ward")}: </span>
                <span className={styles.districtSubtitle}>
                  {i18n.language === "en" ? "6" : "छ"}
                </span>
              </div>
            </div>
          </div>
          <div className={styles.photoContainer}>
            <img
              src="/static/img/congress_tree.png"
              className={styles.photo}
              alt=""
            />
          </div>
        </div>
        {loading ? (
          <Loading loaderStyle={styles.loaderStyle} />
        ) : (
          <TableShow
            data={generalMembers}
            headerList={["Sn", "name", "gender", "phone_number"]}
            length={snRef.current}
            page={page}
            t={t}
            language={i18n.language}
          />
        )}
        <Pagination page={page} setPage={setPage} lastPage={lastPage.current} />
      </Container>
    </div>
  );
}

export default GeneralMember;
