import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Hero from "../../components/Hero/Hero";
import Member from "../../components/Member/Member";
import styles from "./Commitee.module.css";

const getImage = (img) => {
  if (img && img.length > 0) {
    return img;
  } else {
    return "/static/img/user.png";
  }
};

function Committee() {
  const { t } = useTranslation("executiveCommittee");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Hero title={t("executiveMember")} />
      <Container className={styles.comitteeCont}>
        <Row>
          <div className="d-flex justify-content-center">
            <Col md={4}>
              {t("chairperson", { returnObjects: true }).map((element) => (
                <Member
                  key={element.id}
                  name={element.name}
                  post={element.designation}
                  image={getImage(element.image)}
                  fbLink={element.facebook}
                  phone={element.phone}
                />
              ))}
            </Col>
          </div>
        </Row>
        <Row>
          <div className=" d-flex justify-content-around">
            {t("subchairperson", { returnObjects: true }).map((element) => (
              <Col key={element.id} md={3} xs={6}>
                <Member
                  name={element.name}
                  post={element.designation}
                  image={getImage(element.image)}
                  fbLink={element.facebook}
                  phone={element.phone}
                />
              </Col>
            ))}
          </div>
        </Row>
        <Row>
          <div className="d-flex justify-content-around">
            {t("secretary", { returnObjects: true }).map((element) => (
              <Col key={element.id} md={3} xs={6}>
                <Member
                  name={element.name}
                  post={element.designation}
                  image={getImage(element.image)}
                  fbLink={element.facebook}
                  phone={element.phone}
                />
              </Col>
            ))}
          </div>
        </Row>
        <Row>
          <div className="d-flex justify-content-around">
            {t("subsecreatary", { returnObjects: true }).map((element) => (
              <Col key={element.id} md={3} xs={4}>
                <Member
                  name={element.name}
                  post={element.designation}
                  image={getImage(element.image)}
                  fbLink={element.facebook}
                  phone={element.phone}
                />
              </Col>
            ))}
            {t("treasurer", { returnObjects: true }).map((element) => (
              <Col key={element.id} md={3} xs={4}>
                <Member
                  name={element.name}
                  post={element.designation}
                  image={getImage(element.image)}
                  fbLink={element.facebook}
                  phone={element.phone}
                />
              </Col>
            ))}
          </div>
        </Row>
        <Row>
          {t("members.list", { returnObjects: true }).map((element) => (
            <Col key={element.id} md={3} xs={3}>
              <Member
                name={element.name}
                post={t("members.designation")}
                image={getImage(element.image)}
                fbLink={element.facebook}
                phone={element.phone}
              />
            </Col>
          ))}
          {t("invited_members.list", { returnObjects: true }).map((element) => (
            <Col key={element.id} md={3} xs={3}>
              <Member
                name={element.name}
                post={t("invited_members.designation")}
                image={getImage(element.image)}
                fbLink={element.facebook}
                phone={element.phone}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
}

export default Committee;
