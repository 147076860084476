import { baseRequest, baseGetRequest } from "./base";

const login = async (data) => {
  let loginData = {
    password: data.password,
  };

  if (
    isNaN(data.identification_number) ||
    data.identification_number.length > 10
  ) {
    loginData.identification_number = data.identification_number;
  } else {
    loginData.phone_number = data.identification_number;
  }

  var response = await baseRequest("/api/login", "POST", loginData);
  return response;
};

const logout = async () => {
  var response = await baseGetRequest("/api/logout");
  return response;
};

const updatePassword = async (data) => {
  var response = await baseRequest("/api/updatepassword", "POST", data);
  return response;
};

const forgotPassword = async (data) => {
  var response = await baseRequest("/api/forgotpassword", "POST", data);
  return response;
};
export { login, logout, updatePassword, forgotPassword };
