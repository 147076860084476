import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AiTwotoneCalendar } from "react-icons/ai";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Lightbox from "react-image-lightbox";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import { notifyError } from "../../App";
import { getBlogById } from "../../utils/api/blogs";
import { Loading } from "../../components/Loading";
import { axiosErrorHandler } from "../../utils/errorHandle/axiosErrorHandler";
import { timer } from "../../utils/timer/timer";
import { SocialMediaShare } from "../Share";
import styles from "./Index.module.css";
import "./style.scss";
import { Event, Notice } from "../UpcommingNoticeAndBlog";

function Blog(props) {
  const assetUrl = useRef(null);
  const [blogData, setBlogData] = useState({
    images: [],
  });
  const { i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  const { id } = useParams();

  const handleClick = (id) => {
    setIsOpen(true);
    setCurrentImage(id);
  };

  const blogsSliderSetting = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <IoIosArrowForward />,
    prevArrow: <IoIosArrowBack />,
  };
  const fetchBlogData = (id) => {
    setLoading(true);
    getBlogById(id)
      .then((res) => {
        assetUrl.current = res.data.asset_url;
        setBlogData(res.data.blog);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        axiosErrorHandler(err, notifyError);
      });
  };
  useEffect(() => {
    fetchBlogData(id);
  }, [id]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return loading ? (
    <Loading />
  ) : (
    <Container>
      <section className={styles.containerWrapper}>
        <div className={styles.container}>
          <Row>
            <div className="cc-event-details">
              <Col md={12}>
                <div className="events-wrapper">
                  <Slider {...blogsSliderSetting}>
                    {blogData.images.map((image, index) => {
                      return (
                        <div key={image.id} className="event-image-wrapper">
                          <img
                            src={`${assetUrl.current}/${image.image}`}
                            alt=""
                            className={styles.image}
                            style={{
                              height: "340px",
                              width: "100%",
                              objectFit: "cover",
                            }}
                            onClick={() => handleClick(index)}
                          />
                        </div>
                      );
                    })}
                  </Slider>
                </div>
              </Col>
            </div>
          </Row>

          <div className={styles.eventDetails}>
            <div className={styles.eventTitle}>
              {i18n.language === "en" ? blogData.english.title : blogData.nepali.title}
            </div>
            <div className={styles.eventDate}>
              <AiTwotoneCalendar />
              {timer(blogData.created_at, i18n.language)}
            </div>
            <div className={styles.socials}>
              <SocialMediaShare url={process.env.REACT_APP_URL + "/blog/" + blogData.id + "/"} />
            </div>

            <div
              className={styles.eventDescription}
              dangerouslySetInnerHTML={{
                __html:
                  i18n.language === "en"
                    ? blogData.english.description
                    : blogData.nepali.description,
              }}
            ></div>
          </div>

          {isOpen && (
            <Lightbox
              style={{ zIndex: "2000", padding: "1em" }}
              mainSrc={new URL(blogData.images[currentImage].image, assetUrl.current).href}
              nextSrc={
                new URL(
                  blogData.images[(currentImage + 1) % blogData.images.length].image,
                  assetUrl.current
                ).href
              }
              prevSrc={
                new URL(
                  blogData.images[
                    (currentImage + blogData.images.length - 1) % blogData.images.length
                  ].image,
                  assetUrl.current
                ).href
              }
              onMovePrevRequest={() =>
                setCurrentImage(
                  (currentImage + blogData.images.length - 1) % blogData.images.length
                )
              }
              onMoveNextRequest={() => setCurrentImage((currentImage + 1) % blogData.images.length)}
              onCloseRequest={() => setIsOpen(false)}
            />
          )}
        </div>
        <div className={styles.noticeRight}>
          <div className={styles.sticky}>
            <Notice />
            <Event />
          </div>
        </div>
      </section>
    </Container>
  );
}

export default Blog;
