import React from "react";
import styles from "./Parichaya.module.css";

function parichya() {
  return (
    <div className={styles.containerMain}>
      <div className={`container ${styles.container}`}>
        <div>
          <img className={styles.parichayaImg} src={"/parichaya/parichaya.jpg"} alt="" />
        </div>
        <div className={styles.textWrap}>
          <p className={styles.bold}>गौरवमय इतिहास,</p>
          <p className={styles.bold}>जिम्मेवार वर्तमान,</p>
          <p className={styles.bold}>र उज्यालो भविष्य।</p> <div className={styles.mr} />
          <p>
            जननेता वीपी कोइरालाको विचार र संस्कारको जगमा समुन्नत नेपालका लागि समर्पित एक अठोट,
            अभियान र आन्दोलन। https://nepalicongress.org/ ले नेपाली कांग्रेसलाई एक ‘डिजिटल हाउस’
            भित्र समग्रमा समेट्ने छ। नेपाली कांग्रेसको केन्द्र, प्रदेश, जिल्ला, भ्रातृ र शुभेच्छुक
            संस्था एवं विदेशस्थित जनसम्पर्कहरूलाई एउटै छहारीमुनि भेट्न सकिने छ।
          </p>{" "}
          <div className={styles.mr} />
          <p className={styles.bold}>
            गौरवमय इतिहास, जिम्मेवार वर्तमान र उज्यालो सुनिश्चित भविष्य’को पर्यायवाची हो नेपाली
            कांग्रेस।
          </p>
          <p className={styles.bold}>
            राष्ट्रियता, लोकतत्र र समाजवादका लागि सात दशकको अनवरत संघर्षको हो नेपाली कांग्रेस।
          </p>
          <p className={styles.bold}>
            युगपुरुष वीपी कोइरालाको विचार र संस्कारको जगमा समुन्नत नेपाल निर्माणका लागि दृढ अठोट
            राख्ने आन्दोलन हो नेपाली कांग्रेस।
          </p>
          <div className={styles.mr} />
          <p>
            हरेक राष्ट्रिय अन्तर्राष्ट्रिय समस्याहरूको समाधान दिने नेपाली कांग्रेसलाई विश्वले अब
            https://nepalicongress.org/ एक ‘डिजिटल हाउस’ भित्र प्रस्तुत गरेका छौं। समग्रतामा नेपाली
            कांग्रेसको केन्द्र, प्रदेश, जिल्ला तहका संरचना, भ्रातृ एवं शुभेच्छुक संघसंस्था तथा
            विदेशस्थित सम्पर्क समितिहरूलाई एउटै छहारीमा समेटिएको छ। निरन्तर सहयोग, समीक्षा एवं
            समालोचनात्मक विवेचनाहरूमा नै यसको सफलता रहेको वास्तविकतालाई हामी स्वीकार गर्दछौं।
          </p>
          <p className={styles.bold}>जय नेपाल</p>
          <p className={styles.imageContain}>
            ____________________
            <img className={styles.sign} src="/sign.jpg" alt="" />
          </p>
          <p className={styles.bold}>विश्वप्रकाश शर्मा</p>
          <p className={styles.bold}>प्रवक्ता/प्रमुख सूचना, सञ्चार तथा प्रचार विभाग </p>
          <div className={styles.mr} />
          <p>वेबसाइट संयोजन समिति :</p>
          <p>संयोजकः गोबर्धन राना मगर</p>
          <p>प्रविधि संयोजकः ई. दिपेश बिष्ट</p>
          <p>एड्मिन– प्रकाश शर्मा सचिव, नेपाली कांग्रेस केन्द्रीय कार्यालय</p>
        </div>
      </div>
    </div>
  );
}

export default parichya;
