import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../App";
import { Loading } from "../../components/Loading";
import UserDetailsForm from "../../components/User/UserDetailsForm";
import { signupUser } from "../../utils/api/users";
import { axiosErrorHandler } from "../../utils/errorHandle/axiosErrorHandler";
import { imgCompressor } from "../../utils/image";

const SignUp = () => {
  const navigate = useNavigate();
  const [error, setError] = useState({ children: [] });
  const [file, setFile] = useState({ raw: null, preview: "" });
  const [loading, setLoading] = useState(false);

  const handleFileChange = async (file) => {
    let compressedImage = await imgCompressor(file);
    setFile({
      raw: compressedImage,
      preview: URL.createObjectURL(compressedImage),
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //validation
    let err = { children: [] };
    if (data.nepali.name.trim().length === 0) {
      err.npName = "नाम जरुरी छ।";
    }
    if (data.english.name.trim().length === 0) {
      err.enName = "Name is required";
    }
    if (data.date_of_birth.trim().length === 0) {
      err.dob = "Dob is required";
    }
    if (data.gender.trim().length === 0) {
      err.gender = "Select a gender";
    }

    if (data.identification_number.length === 0) {
      err.identificationNumber = "Identification/क्रियाशील number is required";
    }
    if (data.phone_number.trim().length !== 10) {
      err.phoneNumber = "Enter a valid 10 digit phone number";
    }
    if (data.email.trim().length === 0) {
      err.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      err.email = "Enter a valid email.";
    }
    if (data.password.length < 8) {
      err.password = "Password must be atleast 8 characters long.";
    }
    if (data.confirm_password !== data.password) {
      err.confirmPassword = "Passwords donot match.";
    }

    for (let index = 0; index < data.children.length; index++) {
      if (data.children[index].english.name.trim().length === 0) {
        err.children[index] = { english: "Name is required." };
      }
      if (data.children[index].nepali.name.trim().length === 0) {
        err.children[index] = {
          ...err.children[index],
          nepali: "नाम जरुरि छ",
        };
      }
    }

    setError(err);
    if (Object.keys(err).length > 1 || err.children.length > 0) {
      return;
    }

    setLoading(true);
    signupUser(data.id, data, file.raw)
      .then((res) => {
        notifySuccess(res.message);
        setLoading(false);
        navigate("/login/");
      })
      .catch((err) => {
        setLoading(false);
        axiosErrorHandler(err, notifyError);
      });
  };

  const [data, setData] = useState({
    gender: "male",
    date_of_joining: "",
    family_contact_number: "",
    date_of_birth: "",
    category: "",
    phone_number: "",
    email: "",
    password: "",
    identification_number: "",
    confirm_password: "",
    english: {
      name: "",
      address: "",
      father_name: "",
      mother_name: "",
      grandfather_name: "",
      grandmother_name: "",
      partner_name: "",
    },
    nepali: {
      name: "",
      address: "",
      father_name: "",
      mother_name: "",
      grandfather_name: "",
      grandmother_name: "",
      partner_name: "",
    },
    children: [],
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <Container>
      <UserDetailsForm
        data={data}
        setData={setData}
        file={file}
        handleFileChange={handleFileChange}
        error={error}
        signup={true}
        setError={setError}
        onSubmit={handleSubmit}
      />
    </Container>
  );
};

export default SignUp;
