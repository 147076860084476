import React from "react";

function PDFViewerThumbnail({ fileUrl }) {
  const [numPages, setNumPages] = React.useState(null);

  return (
    <img
      style={{
        maxWidth: "200px",
      }}
      src={"/pdf.png"}
    />
  );
}

export default PDFViewerThumbnail;
