import cx from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { convertToNepali } from "../../utils/nepaliLocale/nepaliConvert";
import styles from "./Pagination.module.css";

function Pagination({ page, setPage, lastPage }) {
  const { i18n } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <AiOutlineArrowLeft
          fontSize="large"
          onClick={() => {
            page === 1 ? setPage(1) : setPage((prevPage) => prevPage - 1);
            window.scrollTo(0, 0);
          }}
        />
      </div>
      <div className={styles.pageNumberContainer}>
        {Array.from({ length: lastPage >= 5 ? 5 : lastPage }, (v, k) => {
          if (lastPage <= 5 || page <= 2) {
            return k + 1;
          } else if (page + 2 <= lastPage) {
            return page - 2 + k;
          } else {
            return lastPage - 5 + k + 1;
          }
        }).map((element) => (
          <div
            className={cx(
              styles.pageNumber,
              page === element ? styles.pageNumberActive : ""
            )}
            key={element}
            onClick={() => {
              setPage(element);
              window.scrollTo(0, 0);
            }}
          >
            {i18n.language === "en"
              ? element
              : convertToNepali(element.toString())}
          </div>
        ))}
      </div>

      <div className={styles.icon}>
        <AiOutlineArrowRight
          fontSize="large"
          onClick={() => {
            page === lastPage
              ? setPage(lastPage)
              : setPage((prevPage) => prevPage + 1);
            window.scrollTo(0, 0);
          }}
        />
      </div>
    </div>
  );
}

export default Pagination;
