const partyItems = [
  {
    enTitle: "Rules of the Nepali Congress 2076",
    npTitle: "नेपाली कांग्रेसको नियमावली २०७६",
    enDate: "October 16th, 2020",
    npDate: "आश्विन ३०, २०७७",
    id: "1",
    path: "/static/pdf/rules_and_regulations.pdf",
    shouldTranslate: true,
  },
];

export { partyItems };
