import cx from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { routes } from "../../common/routes";
import styles from "./Topnav.module.css";

function Topnav() {
  const { i18n } = useTranslation();
  const { t } = useTranslation("topNav");

  const navigate = useNavigate();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };
  return (
    <div className={styles.containerWrapper}>
      <div className={styles.container}>
        <div className={styles.rightNav}>
          <div className={styles.logoContainer}>
            <img
              src="/static/logo/logo.svg"
              className={styles.imageContainer}
              onClick={() => navigate(routes.home)}
              alt=""
            />
          </div>
          <div className={styles.kaski}>
            <div className={styles.nep}> {"कास्की"}</div>
            <div className={styles.eng}>{"KASKI"}</div>
          </div>
          <div className={styles.galleryContainer}>
            <div
              className={styles.gallery}
              onClick={() => navigate(routes.media.photoGallery)}
            >
              {t("gallery")}
            </div>
            <div
              className={styles.contact}
              onClick={() => navigate(routes.contactUs)}
            >
              {t("contact")}
            </div>
          </div>
        </div>

        <ul className={styles.linkContainer}>
          <div className={styles.languageContainer}>
            <div
              className={cx(
                styles.language,
                i18n.language === "en" ? styles.lang : null
              )}
              onClick={() => changeLanguage("en")}
            >
              English
            </div>
            <div
              className={cx(
                styles.language,
                i18n.language === "np" ? styles.lang : null
              )}
              onClick={() => changeLanguage("np")}
            >
              नेपाली
            </div>
          </div>
          <div className={styles.socialList}>
            <div className={styles.fbLink}>
              <a
                href="https://www.facebook.com/profile.php?id=100084816781171"
                target={"_blank"}
              >
                <img
                  src="/static/logo/facebook.svg"
                  className={styles.fb}
                  alt=""
                />
              </a>
            </div>
            <div className={styles.twitterLink}>
              <a
                href="https://www.youtube.com/channel/UCxiQInGBm1AyRtrTAerZjOA"
                target={"_blank"}
              >
                <img
                  src="https://www.freepnglogos.com/uploads/youtube-logo-red-hd-13.png"
                  className={styles.twitter}
                  alt=""
                />
              </a>
            </div>
          </div>
        </ul>
      </div>
    </div>
  );
}

export default Topnav;
