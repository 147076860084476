import React, { useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import { imgCompressor, validateImage } from "../../utils/image";
import styles from "./UpdatePictureModal.module.css";
import { MdModeEditOutline } from "react-icons/md";
import { getCookie } from "../../utils/cookie/cookies";
import { useTranslation } from "react-i18next";

const UpdatePictureModal = ({ show, setShow, profilePicture, setProfilePicture, onSave }) => {
  const { t } = useTranslation("userProfile");
  const handleClose = () => {
    setProfilePicture({
      raw: null,
      preview: getCookie("profile_picture") ? getCookie("profile_picture") : "/static/img/user.png",
    });
    setShow(false);
  };
  const handleImageUpload = async (e) => {
    var files = e.target.files[0];

    var res = validateImage(files);
    if (!(res === true)) {
      return;
    }

    let compressedImage = await imgCompressor(files);
    setProfilePicture({
      raw: compressedImage,
      preview: URL.createObjectURL(compressedImage),
    });
  };
  const imageUploadRef = useRef(null);

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>{t("update_profile_pic")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={styles.imageContainer}>
            <input
              type="file"
              onChange={handleImageUpload}
              hidden
              ref={imageUploadRef}
              accept="image/png, image/gif, image/jpeg"
            />
            <div className={styles.imageWrapper}>
              <img src={profilePicture.preview} alt="" className={styles.image} />
              <div className={styles.editIconContainer}>
                <div
                  className={styles.editIcon}
                  onClick={() => {
                    imageUploadRef.current.click();
                  }}
                >
                  <MdModeEditOutline size="sm" />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t("close")}
          </Button>
          {profilePicture.raw && (
            <Button variant="primary" onClick={onSave}>
              {t("save")}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UpdatePictureModal;
