import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { partyItemsEnglish } from "./data";
import styles from "./Kriyasil.module.css";
import { partyItemsNepali } from "./nep";

function Newkriyasil() {
  const { i18n } = useTranslation();

  const returnNameAccordingToLanguage = (ind1, ind2) => {
    if (i18n.language === "np") {
      return partyItemsNepali[ind1].sub[ind2].name;
    }

    return partyItemsEnglish[ind1].sub[ind2].name;
  };

  const returnTitle = (id) => {
    if (i18n.language === "np") {
      return partyItemsNepali[id].region;
    }

    return partyItemsEnglish[id].region;
  };

  return (
    <div className="container">
      <div className={styles.cardContainer}>
        {partyItemsEnglish.map((item, index) => {
          return (
            <div className={styles.card} key={index}>
              <div className={styles.cardTitle}> {returnTitle(index)}</div>
              <div className={styles.subWrap}>
                {item.sub.map((dat, i) => (
                  <div className={styles.cardPdfs} key={i}>
                    <Link to={"/viewkriyasil/" + item.folderPath + "/" + dat.filePath + "/new/"}>
                      <img src={"/pdf.png"} className={styles.pdf} alt="" />
                      {returnNameAccordingToLanguage(index, i)}
                    </Link>
                    <a
                      href={
                        "/websitepdf/activemembers" +
                        "/new/" +
                        item.folderPath +
                        "/" +
                        dat.filePath +
                        ".pdf"
                      }
                      download
                      target="_blank"
                    >
                      <text className={styles.download}> Download</text>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Newkriyasil;
