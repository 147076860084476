import cx from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AiTwotoneCalendar } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { getNotices } from "../../utils/api/notices";
import { timer } from "../../utils/timer/timer";
import styles from "./Notices.module.css";

const Notices = () => {
  const { i18n } = useTranslation();
  const { t } = useTranslation("home");

  const [notices, setNotices] = useState([]);
  const assetUrl = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    getNotices(1, i18n.language === "en" ? "english" : "nepali")
      .then((res) => {
        assetUrl.current = res.data.asset_url;
        setNotices(res.data.notices);
      })
      .catch((err) => {});
  }, [i18n.language]);

  return (
    <section className="main-section">
      <div className="message-container">
        <div className="section-title">
          <h3 className="text-dark text-left ps-2">{t("notice")}</h3>
        </div>
        <div className="message-wrapper">
          {notices.length > 0 && (
            <Row>
              <Col md={6} className="pb-3">
                <div className="notice-list-left">
                  <Link
                    to={`/notice/${notices[0].id}`}
                    state={{ noticeData: notices[0] }}
                    className="notice-list"
                  >
                    <div className="notice-image">
                      <img
                        src={assetUrl.current + notices[0].images[0].image}
                        alt=""
                      />
                    </div>
                    <div className="notice-title">
                      <h6>{notices[0].title}</h6>
                      <p
                        className={cx(
                          "mb-2",
                          styles.noticeDescription,
                          styles.noticeDescriptionLeft
                        )}
                        dangerouslySetInnerHTML={{
                          __html: notices[0]?.description?.slice(0, 150),
                        }}
                      ></p>
                      <p className="mb-0">
                        <AiTwotoneCalendar />
                        {timer(notices[0].created_at, i18n.language)}
                      </p>
                    </div>
                  </Link>
                </div>
              </Col>
              <Col md={6}>
                <div className="notice-list-right">
                  {notices.slice(1, 4).map((element) => (
                    <Link
                      to={`/notice/${element.id}`}
                      state={{ noticeData: element }}
                      className="notice-list"
                    >
                      <div className="notice-image">
                        <img
                          src={assetUrl.current + element.images[0].image}
                          alt=""
                        />
                      </div>
                      <div className="notice-title">
                        <h6>{element.title}</h6>
                        <p
                          className={cx(
                            "mb-2",
                            styles.noticeDescription,
                            styles.noticeDescriptionRight
                          )}
                          dangerouslySetInnerHTML={{
                            __html: element.description.slice(0, 150),
                          }}
                        ></p>
                        <p className="mb-0">
                          <AiTwotoneCalendar />
                          {timer(element.created_at, i18n.language)}
                        </p>
                      </div>
                    </Link>
                  ))}
                </div>
              </Col>
              <div className="text-center pt-4">
                <button
                  className="btn btn-primary"
                  onClick={() => navigate("/notices/")}
                >
                  {t("view_all")}
                </button>
              </div>
            </Row>
          )}
        </div>
      </div>
    </section>
  );
};

export default Notices;
