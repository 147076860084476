import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { logout } from "../../utils/api/auth";
import { clearCookie } from "../../utils/cookie/cookies";
import styles from "./ProfileBox.module.css";

const ProfileBox = ({ open, setOpen }) => {
  const { i18n } = useTranslation();
  const { t } = useTranslation("userProfile");
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
    } catch (e) {}
    setOpen(false);
    clearCookie("token");
    clearCookie("id");
    clearCookie("name");
    clearCookie("identification_number");
    clearCookie("profile_picture");
    navigate("/");
  };
  return (
    <div className={styles.container}>
      <div className={styles.optionsContainer}>
        <div
          className={styles.options}
          onClick={() => {
            navigate("/auth/user/details/");
            setOpen(false);
          }}
        >
          {i18n.language === "en" ? "Profile" : "प्रोफाइल"}
        </div>
        <div
          className={styles.options}
          onClick={() => {
            navigate("/auth/user/Feedback/");
          }}
        >
          {i18n.language === "en" ? "Feedback" : "प्रतिक्रिया"}
        </div>
        <div className={styles.options} onClick={handleLogout}>
          {t("logout")}
        </div>
      </div>
    </div>
  );
};

export default ProfileBox;
