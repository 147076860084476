import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AiTwotoneCalendar } from "react-icons/ai";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Lightbox from "react-image-lightbox";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import { notifyError } from "../../App";
import { Loading } from "../../components/Loading";

import { getNoticeById } from "../../utils/api/notices";
import { axiosErrorHandler } from "../../utils/errorHandle/axiosErrorHandler";
import { timer } from "../../utils/timer/timer";
import { SocialMediaShare } from "../Share";
import styles from "./Index.module.css";
import "./style.scss";

function Notice(props) {
  const assetUrl = useRef(null);
  const [noticeData, setNoticeData] = useState({
    images: [],
  });
  const { i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [currentImage, setCurrentImage] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const { id } = useParams();

  const noticesSliderSetting = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <IoIosArrowForward />,
    prevArrow: <IoIosArrowBack />,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
  };

  const handleClick = (id) => {
    setIsOpen(true);
    setCurrentImage(id);
  };
  const fetchNoticeData = (id) => {
    setLoading(true);
    getNoticeById(id)
      .then((res) => {
        assetUrl.current = res.data.asset_url;
        setNoticeData(res.data.notice);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        axiosErrorHandler(err, notifyError);
      });
  };
  useEffect(() => {
    fetchNoticeData(id);
  }, [id]);

  return loading ? (
    <Loading />
  ) : (
    <section className={styles.containerWrapper}>
      <div className={styles.container}>
        <Container>
          <Row>
            <Col md={4}>
              <div className={styles.eventDetails}>
                <div className={styles.eventTitle}>
                  {i18n.language === "en" ? noticeData.english.title : noticeData.nepali.title}
                </div>
                <div className={styles.eventDate}>
                  <AiTwotoneCalendar />
                  {timer(noticeData.created_at, i18n.language)}
                </div>
                <div className={styles.socials}>
                  <SocialMediaShare url={process.env.REACT_APP_URL + "/notice/" + noticeData.id + "/"} />
                </div>
              </div>
            </Col>
            <Col md={8}>
              <div className="events-wrapper">
                <div>
                  <Slider {...noticesSliderSetting} style={{ marginBottom: "4em" }}>
                    {noticeData.images.map((image, index) => {
                      return (
                        <div key={image.id}>
                          <div onClick={() => handleClick(index)} className="event-image-wrapper">
                            <img
                              src={`${assetUrl.current}/${image.image}`}
                              alt=""
                              className={styles.image}
                              style={{
                                height: "340px",
                                width: "100%",
                                objectFit: "cover",
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
                <div
                  className={styles.eventDescription}
                  dangerouslySetInnerHTML={{
                    __html: i18n.language === "en" ? noticeData.english.description : noticeData.nepali.description,
                  }}
                ></div>
              </div>
            </Col>
          </Row>
        </Container>
        {isOpen && (
          <Lightbox
            style={{ zIndex: "2000", padding: "1em" }}
            mainSrc={new URL(noticeData.images[currentImage].image, assetUrl.current).href}
            nextSrc={
              new URL(noticeData.images[(currentImage + 1) % noticeData.images.length].image, assetUrl.current).href
            }
            prevSrc={
              new URL(
                noticeData.images[(currentImage + noticeData.images.length - 1) % noticeData.images.length].image,
                assetUrl.current
              ).href
            }
            onMovePrevRequest={() =>
              setCurrentImage((currentImage + noticeData.images.length - 1) % noticeData.images.length)
            }
            onMoveNextRequest={() => setCurrentImage((currentImage + 1) % noticeData.images.length)}
            onCloseRequest={() => setIsOpen(false)}
          />
        )}
      </div>
    </section>
  );
}

export default Notice;
