import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AiTwotoneCalendar } from "react-icons/ai";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Lightbox from "react-image-lightbox";
import { Link, useParams } from "react-router-dom";
import Slider from "react-slick";
import { LoadingCard } from "..";
import { Loading } from "../../components/Loading";
import { notifyError } from "../../App";
import {
  getVatriSangathanById,
  getVatriSangathans,
} from "../../utils/api/vatriSangathan";
import { axiosErrorHandler } from "../../utils/errorHandle/axiosErrorHandler";
import { timer } from "../../utils/timer/timer";
import { SocialMediaShare } from "../Share";
import styles from "./Index.module.css";
import "./style.scss";
import cx from "classnames";

function Vatri(props) {
  const assetUrl = useRef(null);
  const [vatriData, setVatriData] = useState({
    images: [],
  });
  const [data, setData] = useState([]);
  const { i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [currentImage, setCurrentImage] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [t] = useTranslation("common");
  const [upcomingVatriLoading, setUpcomingVatriLoading] = useState(false);

  const { id } = useParams();

  const noticesSliderSetting = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <IoIosArrowForward />,
    prevArrow: <IoIosArrowBack />,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
  };

  const handleClick = (id) => {
    setIsOpen(true);
    setCurrentImage(id);
  };
  const fetchVatriData = (id) => {
    setLoading(true);
    getVatriSangathanById(id)
      .then((res) => {
        assetUrl.current = res.data.asset_url;
        setVatriData(res.data.vatri_sangathan);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        axiosErrorHandler(err, notifyError);
      });
  };

  useEffect(() => {
    setUpcomingVatriLoading(true);
    getVatriSangathans(i18n.language === "en" ? "english" : "nepali")
      .then((res) => {
        assetUrl.current = res.data.asset_url;
        setData(res.data.vatri_sangathans);
        setUpcomingVatriLoading(false);
      })
      .catch((err) => {
        setUpcomingVatriLoading(false);
      });
  }, [i18n.language]);

  useEffect(() => {
    fetchVatriData(id);
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <section className={styles.containerWrapper}>
      <div className={styles.container}>
        <Container>
          <Row>
            <Col md={4}>
              <div className={styles.eventDetails}>
                <div className={styles.eventTitle}>
                  {i18n.language === "en"
                    ? vatriData.english.title
                    : vatriData.nepali.title}
                </div>
                <div className={styles.eventDate}>
                  <AiTwotoneCalendar />
                  {timer(vatriData.created_at, i18n.language)}
                </div>
                <div className={styles.socials}>
                  <SocialMediaShare
                    url={
                      process.env.REACT_APP_URL +
                      "/vatriSangathan/" +
                      vatriData.id +
                      "/"
                    }
                  />
                </div>
              </div>
            </Col>
            <Col md={8}>
              <div className="events-wrapper">
                <div>
                  <Slider
                    {...noticesSliderSetting}
                    style={{ marginBottom: "4em" }}
                  >
                    {vatriData.images.map((image, index) => {
                      return (
                        <div key={image.id}>
                          <div
                            className={cx(
                              styles.imageContainer,
                              "events-wrapper"
                            )}
                            onClick={() => handleClick(index)}
                          >
                            <img
                              src={`${assetUrl.current}/${image.image}`}
                              alt=""
                              className={styles.image}
                              style={{
                                height: "340px",
                                width: "100%",
                                objectFit: "cover",
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
                <div
                  className={styles.eventDescription}
                  dangerouslySetInnerHTML={{
                    __html:
                      i18n.language === "en"
                        ? vatriData.english.description
                        : vatriData.nepali.description,
                  }}
                ></div>
              </div>
            </Col>
          </Row>
          <div className={styles.upcomingEventsContainer}>
            <div className={styles.upcomingEventsTitle}>
              {t("vatrisangathan")}
            </div>
            {upcomingVatriLoading
              ? [...Array(data.length > 0 ? data.length : 5)].map(
                  (element, index) => (
                    <LoadingCard
                      key={index}
                      cardStyle={styles.card}
                      cardDetailsStyle={styles.eventDetails}
                      photoStyle={styles.photos}
                    />
                  )
                )
              : data.map((data, index) => (
                  <div
                    className={styles.uCard}
                    key={index}
                    style={
                      index % 2 !== 0 ? { backgroundColor: "#efefef" } : null
                    }
                  >
                    <div className={styles.uPhotos}>
                      <img
                        src={
                          data.images.length > 0
                            ? `${assetUrl.current}/${data.images[0].image}`
                            : ""
                        }
                        className={styles.uImages}
                        alt=""
                      />
                    </div>

                    <div className={styles.uEventDetails}>
                      <div className={styles.uDetailsContainer}>
                        <div className={styles.uEventTitle}>{data.title}</div>
                        <div className={styles.uEventDate}>
                          <AiTwotoneCalendar />
                          {timer(data.created_at, i18n.language)}
                        </div>
                      </div>
                      <div
                        className={styles.uEventDescription}
                        dangerouslySetInnerHTML={{
                          __html:
                            i18n.language === "en"
                              ? data.description
                              : data.description,
                        }}
                      ></div>
                      <Link
                        to={`/about/vatriSangathan/${data.id}`}
                        onClick={() => {
                          window.scrollTo(0, 0);
                        }}
                      >
                        <button
                          className={styles.button}
                          style={
                            index % 2 !== 0
                              ? { backgroundColor: "#efefef" }
                              : null
                          }
                        >
                          Read More
                        </button>
                      </Link>
                    </div>
                  </div>
                ))}
          </div>
        </Container>
        {isOpen && (
          <Lightbox
            style={{ zIndex: "2000", padding: "1em" }}
            mainSrc={
              new URL(vatriData.images[currentImage].image, assetUrl.current)
                .href
            }
            nextSrc={
              new URL(
                vatriData.images[
                  (currentImage + 1) % vatriData.images.length
                ].image,
                assetUrl.current
              ).href
            }
            prevSrc={
              new URL(
                vatriData.images[
                  (currentImage + vatriData.images.length - 1) %
                    vatriData.images.length
                ].image,
                assetUrl.current
              ).href
            }
            onMovePrevRequest={() =>
              setCurrentImage(
                (currentImage + vatriData.images.length - 1) %
                  vatriData.images.length
              )
            }
            onMoveNextRequest={() =>
              setCurrentImage((currentImage + 1) % vatriData.images.length)
            }
            onCloseRequest={() => setIsOpen(false)}
          />
        )}
      </div>
    </section>
  );
}

export default Vatri;
