import React, { useEffect, useRef, useState } from "react";
import { Card, Container, Placeholder } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MdModeEditOutline } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { notifyError, notifySuccess } from "../../App";
import { UpdatePictureModal } from "../../components/User";
import {
  getProfileDetails,
  removeProfilePicture,
  updateProfilePicture,
} from "../../utils/api/users";
import { clearCookie, getCookie, setCookie } from "../../utils/cookie/cookies";
import { axiosErrorHandler } from "../../utils/errorHandle/axiosErrorHandler";
import styles from "./Profile.module.css";

const Profile = () => {
  const [profilePicture, setProfilePicture] = useState({
    raw: null,
    preview: getCookie("profile_picture")
      ? getCookie("profile_picture")
      : "/static/img/user.png",
  });
  const { i18n } = useTranslation();
  const { t } = useTranslation("userProfile");
  const [profile, setProfile] = useState({});

  const [showModal, setShowModal] = useState(false);

  const [showEditOption, setShowEditOption] = useState(false);
  const iconRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const assetUrl = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (iconRef.current && !iconRef.current.contains(event.target)) {
        setShowEditOption(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [iconRef]);

  const onProfilePictureUpdate = () => {
    let userId = getCookie("user_id");
    updateProfilePicture(userId, profilePicture.raw)
      .then((res) => {
        notifySuccess("Successfully updated");
        setCookie(
          "profile_picture",
          res.data.asset_url + res.data.profile_picture
        );
        setProfile({
          ...profile,
          profile_picture: res.data.profile_picture,
        });
        setShowModal(false);
      })
      .catch((err) => {
        axiosErrorHandler(err, notifyError);
      });
  };

  const onProfilePictureRemove = () => {
    let userId = getCookie("user_id");
    removeProfilePicture(userId)
      .then((res) => {
        notifySuccess(res.message);
        clearCookie("profile_picture");
        setProfilePicture({
          raw: "",
          preview: "/static/img/user.png",
        });
        setProfile({ ...profile, profile_picture: null });
      })
      .catch((err) => {
        axiosErrorHandler(err, notifyError);
      });
  };

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      setLoading(true);
      getProfileDetails(getCookie("user_id"))
        .then((res) => {
          assetUrl.current = res.data.asset_url;
          setProfile(res.data.user);
          if (res.data.user.profile_picture) {
            setCookie(
              "profile_picture",
              res.data.asset_url + res.data.user.profile_picture
            );
            setProfilePicture({
              raw: null,
              preview: res.data.asset_url + res.data.user.profile_picture,
            });
          } else {
            clearCookie("profile_picture");
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          axiosErrorHandler(err, notifyError);
        });
    }

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <Container>
      <UpdatePictureModal
        show={showModal}
        setShow={setShowModal}
        profilePicture={profilePicture}
        setProfilePicture={setProfilePicture}
        onSave={onProfilePictureUpdate}
      />
      <div className={styles.wrapper}>
        <div className={styles.title}>{t("user_profile")}</div>
        <div className={styles.imageContainer}>
          <div className={styles.imageWrapper}>
            <div className={styles.imageEditContainer}>
              <div
                className={styles.editIcon}
                onClick={() => {
                  setShowEditOption(!showEditOption);
                }}
                ref={iconRef}
              >
                <MdModeEditOutline size="lg" />
                {showEditOption && (
                  <div className={styles.editOptions}>
                    <div
                      className={styles.editOption}
                      onClick={() => {
                        setShowEditOption(false);
                        setShowModal(true);
                      }}
                    >
                      {t("update")}
                    </div>
                    {profile.profile_picture && (
                      <div
                        className={styles.editOption}
                        onClick={onProfilePictureRemove}
                      >
                        {t("remove")}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <img src={profilePicture.preview} alt="" className={styles.image} />
          </div>
        </div>
        <div className={styles.userName}>
          {getCookie(i18n.language === "en" ? "eng_name" : "np_name")}
        </div>
        <div className={styles.profileBody}>
          <div className={styles.profileItem}>
            <div className={styles.itemLeft}>{t("email")}</div>
            <div className={styles.itemMiddle}>
              {loading ? (
                <Placeholder as={Card.Title} animation="glow">
                  <Placeholder xs={6} />
                </Placeholder>
              ) : (
                profile.email
              )}
            </div>
            <div className={styles.itemRight}>
              {loading ? (
                <Placeholder as={Card.Title} animation="glow">
                  <Placeholder xs={3} />
                </Placeholder>
              ) : (
                <NavLink to="/auth/user/profile/edit/?title=email">
                  {t("edit")}
                </NavLink>
              )}
            </div>
          </div>
          <div className={styles.profileItem}>
            <div className={styles.itemLeft}>{t("phone")}</div>
            <div className={styles.itemMiddle}>
              {loading ? (
                <Placeholder as={Card.Title} animation="glow">
                  <Placeholder xs={6} />
                </Placeholder>
              ) : (
                profile.phone_number
              )}
            </div>
            <div className={styles.itemRight}>
              {loading ? (
                <Placeholder as={Card.Title} animation="glow">
                  <Placeholder xs={3} />
                </Placeholder>
              ) : (
                <NavLink to="/auth/user/profile/edit/?title=phone">
                  {t("edit")}
                </NavLink>
              )}
            </div>
          </div>
          <div className={styles.profileItem}>
            <div className={styles.itemLeft}>{t("password")}</div>
            <div className={styles.itemMiddle}></div>
            <div className={styles.itemRight}>
              {loading ? (
                <Placeholder as={Card.Title} animation="glow">
                  <Placeholder xs={3} />
                </Placeholder>
              ) : (
                <NavLink to="/auth/user/profile/edit/?title=password">
                  {t("edit")}
                </NavLink>
              )}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Profile;
