import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { notifyError, notifySuccess } from "../../../App";
import { updateEmail } from "../../../utils/api/users";
import { getCookie } from "../../../utils/cookie/cookies";
import { axiosErrorHandler } from "../../../utils/errorHandle/axiosErrorHandler";
import styles from "./EditEmail.module.css";

const EditEmail = ({ loading, setLoading }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({});
  const { t } = useTranslation("userProfile");

  const handleSubmit = (e) => {
    e.preventDefault();
    let err = {};

    if (
      email.trim().length === 0 ||
      !String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      err.email = "Enter a valid email";
    }

    if (password.length === 0) {
      err.password = "Password is required";
    }

    setError(err);

    if (Object.keys(err).length > 0) {
      return;
    }
    setLoading(true);
    updateEmail(getCookie("user_id"), {
      email: email,
      password: password,
    })
      .then((res) => {
        setLoading(false);
        notifySuccess(res.message);
        // navigate("/auth/user/profile");
      })
      .catch((err) => {
        axiosErrorHandler(err, notifyError);
        setLoading(false);
      });
  };
  return (
    <div>
      <form className={styles.forms} autoComplete="off" onSubmit={handleSubmit}>
        <h5 className={styles.editTitle}>Edit Email</h5>
        <div className={styles.formGroup}>
          <input
            className={styles.inputs}
            type="email"
            id="email"
            name="email"
            placeholder={t("password")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <label for="email" className={styles.labels}>
            {t("email")}
          </label>
          {error.email && <div className={styles.errorTitle}>{error.email}</div>}
        </div>
        <div className={styles.formGroup}>
          <input
            className={styles.inputs}
            type="password"
            id="password"
            name="password"
            placeholder={t("password")}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <label for="password" className={styles.labels}>
            {t("password")}
          </label>
          {error.password && <div className={styles.errorTitle}>{error.password}</div>}
        </div>
        <div className={styles.formGroup}>
          <Button onClick={handleSubmit}>{t("submit")}</Button>
        </div>
      </form>
    </div>
  );
};

export default EditEmail;
