import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import PDFViewer from "../../components/PDFViewer/PDFViewer";
import styles from "./WorkingConst.module.css";

function WorkingCOnst() {
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {true && (
        <section className={styles.containerWrapper}>
          <div className={styles.container}>
            <Container>
              <div className={styles.constitutionDetails}>
                <div className={styles.constitutionTitle}>
                  {/* {i18n.language === "en" ? item.enTitle : item.npTitle} */}
                </div>
                <div className={styles.constitutionDate}>
                  {/* <AiTwotoneCalendar /> */}
                  {/* {i18n.language === "en" ? item.enDate : item.npDate} */}
                </div>
                <PDFViewer src={searchParams.get("pdf") || ""} />
              </div>
            </Container>
          </div>
        </section>
      )}
    </div>
  );
}

export default WorkingCOnst;
