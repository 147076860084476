import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AiTwotoneCalendar } from "react-icons/ai";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { IoLocationOutline } from "react-icons/io5";
import Lightbox from "react-image-lightbox";
import { Link, useParams } from "react-router-dom";
import Slider from "react-slick";
import { LoadingCard } from "..";
import { notifyError } from "../../App";
import { getEventById, getUpcomingEvents } from "../../utils/api/events";
import { axiosErrorHandler } from "../../utils/errorHandle/axiosErrorHandler";
import { convertTo12hrs, timer } from "../../utils/timer/timer";
import { SocialMediaShare } from "../Share";
import styles from "./Index.module.css";

function Event(props) {
  const { t } = useTranslation("common");
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [upcomingEventsloading, setUpcomingEventsLoading] = useState(true);
  const activeRef = useRef(null);
  const assetUrl = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [eventData, setEventData] = useState({
    images: [],
    english: {},
    nepali: {},
    date: "",
    start_time: "",
  });
  const { i18n } = useTranslation();
  const { id } = useParams();
  const eventsSliderSetting = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    nextArrow: <IoIosArrowForward />,
    prevArrow: <IoIosArrowBack />,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchUpcomingEvents = async (page, language) => {
    setUpcomingEventsLoading(true);
    await getUpcomingEvents(page, language)
      .then((res) => {
        if (res.status === "success") {
          assetUrl.current = res.data.asset_url;
          setUpcomingEvents(res.data.events);
          setUpcomingEventsLoading(false);
        }
      })
      .catch((err) => {
        setUpcomingEventsLoading(false);
        axiosErrorHandler(err, notifyError);
      });
  };

  useEffect(() => {
    let mounted = true;
    let language = i18n.language === "en" ? "english" : "nepali";
    if (mounted) {
      fetchUpcomingEvents(1, language);
    }
    return () => (mounted = false);
  }, [i18n.language]);

  const fetchEventData = (id) => {
    setLoading(true);
    getEventById(id)
      .then((res) => {
        assetUrl.current = res.data.asset_url;
        setEventData(res.data.event);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        axiosErrorHandler(err, notifyError);
      });
  };

  const handleClick = (id) => {
    setIsOpen(true);
    setCurrentImage(id);
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      fetchEventData(id);
    }
    return () => (mounted = false);
  }, [id]);

  return (
    <section className={styles.containerWrapper}>
      <div className={styles.container}>
        <Container>
          {!loading ? (
            <Row style={{ marginBottom: "1em" }}>
              <Col md={4}>
                <div className={styles.eventHeader}>
                  <div className={styles.eventTitle} ref={activeRef}>
                    {i18n.language === "en" ? eventData.english.title : eventData.nepali.title}
                  </div>
                  <div className={styles.eventDate}>
                    <AiTwotoneCalendar />
                    {timer(eventData.date, i18n.language)}
                  </div>
                  <div className={styles.eventLocation}>
                    <IoLocationOutline />
                    {i18n.language === "en" ? eventData.english.location : eventData.nepali.location}
                  </div>
                  <div className={styles.dateTime}>
                    <div className={styles.startDate}>
                      <div className={styles.dateContainer}>
                        <AiTwotoneCalendar
                          style={{
                            color: "red",
                            display: "grid",
                            placeItems: "center",
                          }}
                        />
                        {i18n.language === "en" ? "Start Date" : "सुरू मिति"}
                      </div>
                      <div className={styles.dateDetails}>{timer(eventData.date, i18n.language)}</div>
                    </div>
                    <div className={styles.startTime}>
                      <div className={styles.timeContainer}>
                        <AiTwotoneCalendar
                          style={{
                            color: "red",
                            display: "grid",
                            placeItems: "center",
                          }}
                        />
                        {i18n.language === "en" ? "Start Time" : "सुरु समय"}
                      </div>
                      <div className={styles.timeDetails}>{convertTo12hrs(eventData.start_time, i18n.language)}</div>
                    </div>
                  </div>
                  <div className={styles.socials}>
                    <SocialMediaShare url={process.env.REACT_APP_URL + "/event/" + eventData.id + "/"} />
                  </div>
                </div>
              </Col>
              <Col md={8}>
                <div className={styles.eventWrapper}>
                  <div>
                    <Slider {...eventsSliderSetting}>
                      {eventData.images.map((image, index) => {
                        return (
                          <div
                            key={image.id}
                            className="event-image-wrapper"
                            style={{
                              backgroundColor: "black",
                            }}
                          >
                            <img
                              src={`${assetUrl.current}/${image.image}`}
                              alt=""
                              style={{
                                height: "340px",
                                width: "100%",
                                objectFit: "contain",
                              }}
                              onClick={() => handleClick(index)}
                            />
                          </div>
                        );
                      })}
                    </Slider>
                  </div>

                  <div className={styles.eventDetails}>
                    <div
                      className={styles.eventDescription}
                      dangerouslySetInnerHTML={{
                        __html: i18n.language === "en" ? eventData.english.description : eventData.nepali.description,
                      }}
                    ></div>
                  </div>
                </div>
              </Col>
            </Row>
          ) : (
            <div>Loading...</div>
          )}
          <div className={styles.upcomingEventsContainer}>
            <div className={styles.upcomingEventsTitle}>{t("upcomingEvents")}</div>
            {upcomingEventsloading ? (
              [...Array(upcomingEvents.length > 0 ? upcomingEvents.length : 5)].map((element, index) => (
                <LoadingCard
                  key={index}
                  cardStyle={styles.card}
                  cardDetailsStyle={styles.eventDetails}
                  photoStyle={styles.photos}
                />
              ))
            ) : upcomingEvents.length > 0 ? (
              upcomingEvents.map((upcomingEvent, index) => (
                <div
                  className={styles.uCard}
                  key={index}
                  style={index % 2 !== 0 ? { backgroundColor: "#efefef" } : null}
                >
                  <div className={styles.uPhotos}>
                    <img
                      src={
                        upcomingEvent.images.length > 0 ? `${assetUrl.current}/${upcomingEvent.images[0].image}` : ""
                      }
                      className={styles.uImages}
                      alt=""
                    />
                  </div>

                  <div className={styles.uEventDetails}>
                    <div className={styles.uDetailsContainer}>
                      <div className={styles.uEventTitle}>{upcomingEvent.title}</div>
                      <div className={styles.uEventDate}>
                        <AiTwotoneCalendar />
                        {timer(upcomingEvent.created_at, i18n.language)}
                      </div>
                    </div>
                    <div
                      className={styles.uEventDescription}
                      dangerouslySetInnerHTML={{
                        __html: i18n.language === "en" ? upcomingEvent.description : upcomingEvent.description,
                      }}
                    ></div>
                    <Link
                      to={`/event/${upcomingEvent.id}`}
                      state={{ eventData: upcomingEvent }}
                      onClick={() => {
                        window.scrollTo(0, 0);
                      }}
                    >
                      <button className={styles.button} style={index % 2 !== 0 ? { backgroundColor: "#efefef" } : null}>
                        Read More
                      </button>
                    </Link>
                  </div>
                </div>
              ))
            ) : (
              <div>{t("noMoreUpcoming")}</div>
            )}
          </div>
          {isOpen && (
            <Lightbox
              style={{ zIndex: "2000", padding: "1em" }}
              mainSrc={new URL(eventData.images[currentImage].image, assetUrl.current).href}
              nextSrc={
                new URL(eventData.images[(currentImage + 1) % eventData.images.length].image, assetUrl.current).href
              }
              prevSrc={
                new URL(
                  eventData.images[(currentImage + eventData.images.length - 1) % eventData.images.length].image,
                  assetUrl.current
                ).href
              }
              onMovePrevRequest={() =>
                setCurrentImage((currentImage + eventData.images.length - 1) % eventData.images.length)
              }
              onMoveNextRequest={() => setCurrentImage((currentImage + 1) % eventData.images.length)}
              onCloseRequest={() => setIsOpen(false)}
            />
          )}
        </Container>
      </div>
    </section>
  );
}

export default Event;
