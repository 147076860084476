import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  fallbackLng: "np",
  lng: "np",
  resources: {
    en: {
      common: require("./locales/en/common.json"),
      navbar: require("./locales/en/navbar.json"),
      login: require("./locales/en/login.json"),
      topNav: require("./locales/en/topNav.json"),
      footer: require("./locales/en/footer.json"),
      contact: require("./locales/en/contact.json"),
      feedback: require("./locales/en/feedback.json"),
      home: require("./locales/en/home.json"),
      aboutUs: require("./locales/en/aboutUs.json"),
      aboutParty: require("./locales/en/aboutParty.json"),
      userProfile: require("./locales/en/userProfile.json"),
      executiveCommittee: require("./locales/en/executiveCommittee.json"),
      resetPassword: require("./locales/en/resetPassword.json"),
      membersparty: require("./locales/en/membersparty.json"),
    },
    np: {
      common: require("./locales/np/common.json"),
      navbar: require("./locales/np/navbar.json"),
      login: require("./locales/np/login.json"),
      topNav: require("./locales/np/topNav.json"),
      footer: require("./locales/np/footer.json"),
      contact: require("./locales/np/contact.json"),
      feedback: require("./locales/np/feedback.json"),
      aboutUs: require("./locales/np/aboutUs.json"),
      home: require("./locales/np/home.json"),
      aboutParty: require("./locales/np/aboutParty.json"),
      userProfile: require("./locales/np/userProfile.json"),
      executiveCommittee: require("./locales/np/executiveCommittee.json"),
      resetPassword: require("./locales/np/resetPassword.json"),
      membersparty: require("./locales/np/membersparty.json"),
    },
  },
  ns: [
    "navbar",
    "topNav",
    "footer",
    "common",
    "contact",
    "home",
    "login",
    "aboutUs",
    "aboutParty",
    "userProfile",
    "executiveCommittee",
    "resetPassword",
    "feedback",
    "membersparty",
  ],
  defaultNS: "translations",
});

i18n.languages = ["en", "np"];

export default i18n;
