export const partyItemsEnglish = [
  {
    region: "Region No. 1 (1)",
    folderPath: "regionno1part1",
    sub: [
      {
        name: "Pokhara- 26",
        filePath: "pokhara26",
      },
      {
        name: "Pokhara- 27",
        filePath: "pokhara27",
      },
      {
        name: "Pokhara- 29",
        filePath: "pokhara29",
      },
      {
        name: "Pokhara- 30",
        filePath: "pokhara30",
      },
      {
        name: "Pokhara- 31",
        filePath: "pokhara31",
      },
      {
        name: "Pokhara- 32",
        filePath: "pokhara32",
      },
      {
        name: "Rupa Ga.Pa.- 1",
        filePath: "rupagapa1",
      },
      {
        name: "Rupa Ga.Pa.- 2",
        filePath: "rupagapa2",
      },
      {
        name: "Rupa Ga.Pa.- 3",
        filePath: "rupagapa3",
      },
      {
        name: "Rupa Ga.Pa.- 4",
        filePath: "rupagapa4",
      },
      {
        name: "Rupa Ga.Pa.- 5",
        filePath: "rupagapa5",
      },
      {
        name: "Rupa Ga.Pa.- 6",
        filePath: "rupagapa6",
      },
      {
        name: "Rupa Ga.Pa.- 7",
        filePath: "rupagapa7",
      },
    ],
  },
  {
    region: "Region no. 1 (2)",
    folderPath: "regionno1part2",
    sub: [
      {
        name: "Madi Ga.Pa.- 1",
        filePath: "madigapa1",
      },
      {
        name: "Madi Ga.Pa.- 2",
        filePath: "madigapa2",
      },
      {
        name: "Madi Ga.Pa.- 3",
        filePath: "madigapa3",
      },
      {
        name: "Madi Ga.Pa.- 4",
        filePath: "madigapa4",
      },
      {
        name: "Madi Ga.Pa.- 5",
        filePath: "madigapa5",
      },
      {
        name: "Madi Ga.Pa.- 6",
        filePath: "madigapa6",
      },
      {
        name: "Madi Ga.Pa.- 7",
        filePath: "madigapa7",
      },
      {
        name: "Madi Ga.Pa.- 8",
        filePath: "madigapa8",
      },
      {
        name: "Madi Ga.Pa.- 9",
        filePath: "madigapa9",
      },
      {
        name: "Madi Ga.Pa.- 10",
        filePath: "madigapa10",
      },
      {
        name: "Madi Ga.Pa.- 11",
        filePath: "madigapa11",
      },
      {
        name: "Madi Ga.Pa.- 12",
        filePath: "madigapa12",
      },
      {
        name: "Pokhara- 10",
        filePath: "pokhara10",
      },
      {
        name: "Pokhara- 12",
        filePath: "pokhara12",
      },
      {
        name: "Pokhara- 13",
        filePath: "pokhara13",
      },
      {
        name: "Pokhara- 14",
        filePath: "pokhara14",
      },
      {
        name: "Pokhara- 28",
        filePath: "pokhara28",
      },
    ],
  },

  {
    region: "Region no. 2 (1)",
    folderPath: "regionno2part1",
    sub: [
      {
        name: "Pokhara- 7",
        filePath: "pokhara7",
      },
      {
        name: "Pokhara- 15",
        filePath: "pokhara15",
      },
      {
        name: "Pokhara- 17",
        filePath: "pokhara17",
      },
      {
        name: "Pokhara- 21",
        filePath: "pokhara21",
      },
      {
        name: "Pokhara- 33",
        filePath: "pokhara33",
      },
    ],
  },

  {
    region: "Region no. 2 (2)",
    folderPath: "regionno2part2",
    sub: [
      {
        name: "Pokhara- 4",
        filePath: "pokhara4",
      },
      {
        name: "Pokhara- 8",
        filePath: "pokhara8",
      },
      {
        name: "Pokhara- 9",
        filePath: "pokhara9",
      },
      {
        name: "Pokhara- 11",
        filePath: "pokhara11",
      },
      {
        name: "Pokhara- 16",
        filePath: "pokhara16",
      },
      {
        name: "Pokhara- 20",
        filePath: "pokhara20",
      },
    ],
  },
  {
    region: "Region no. 3 (1)",
    folderPath: "regionno3part1",
    sub: [
      {
        name: "Machhapuchhre Ga.Pa.- 1",
        filePath: "machhapuchhregapa1",
      },
      {
        name: "Machhapuchhre Ga.Pa.- 2",
        filePath: "machhapuchhregapa2",
      },
      {
        name: "Machhapuchhre Ga.Pa.- 3",
        filePath: "machhapuchhregapa3",
      },
      {
        name: "Machhapuchhre Ga.Pa.- 4",
        filePath: "machhapuchhregapa4",
      },
      {
        name: "Machhapuchhre Ga.Pa.- 5",
        filePath: "machhapuchhregapa5",
      },
      {
        name: "Machhapuchhre Ga.Pa.- 6",
        filePath: "machhapuchhregapa6",
      },
      {
        name: "Machhapuchhre Ga.Pa.- 7",
        filePath: "machhapuchhregapa7",
      },
      {
        name: "Machhapuchhre Ga.Pa.- 8",
        filePath: "machhapuchhregapa8",
      },
      {
        name: "Machhapuchhre Ga.Pa.- 9",
        filePath: "machhapuchhregapa9",
      },
      {
        name: "Pokhara- 1",
        filePath: "pokhara1",
      },
      {
        name: "Pokhara- 2",
        filePath: "pokhara2",
      },
      {
        name: "Pokhara- 3",
        filePath: "pokhara3",
      },
      {
        name: "Pokhara- 18",
        filePath: "pokhara18",
      },
      {
        name: "Pokhara- 19",
        filePath: "pokhara19",
      },
      {
        name: "Pokhara- 25",
        filePath: "pokhara25",
      },
    ],
  },

  {
    region: "Region no. 3 (2)",
    folderPath: "regionno3part2",
    sub: [
      {
        name: "Annapurna Ga.Pa.- 1",
        filePath: "annapurnagapa1",
      },
      {
        name: "Annapurna Ga.Pa.- 2",
        filePath: "annapurnagapa2",
      },
      {
        name: "Annapurna Ga.Pa.- 3",
        filePath: "annapurnagapa3",
      },
      {
        name: "Annapurna Ga.Pa.- 4",
        filePath: "annapurnagapa4",
      },
      {
        name: "Annapurna Ga.Pa.- 5",
        filePath: "annapurnagapa5",
      },
      {
        name: "Annapurna Ga.Pa.- 6",
        filePath: "annapurnagapa6",
      },
      {
        name: "Annapurna Ga.Pa.- 7",
        filePath: "annapurnagapa7",
      },
      {
        name: "Annapurna Ga.Pa.- 8",
        filePath: "annapurnagapa8",
      },
      {
        name: "Annapurna Ga.Pa.- 9",
        filePath: "annapurnagapa9",
      },
      {
        name: "Annapurna Ga.Pa.- 10",
        filePath: "annapurnagapa10",
      },
      {
        name: "Annapurna Ga.Pa.- 11",
        filePath: "annapurnagapa11",
      },

      {
        name: "Pokhara- 5",
        filePath: "pokhara5",
      },
      {
        name: "Pokhara- 6",
        filePath: "pokhara6",
      },
      {
        name: "Pokhara- 22",
        filePath: "pokhara22",
      },
      {
        name: "Pokhara- 23",
        filePath: "pokhara23",
      },
      {
        name: "Pokhara- 24",
        filePath: "pokhara24",
      },
    ],
  },
];
