import cx from "classnames";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { notifyError, notifySuccess } from "../../App";
import { Loading } from "../../components/Loading";
import { sendFeedback } from "../../utils/api/feedback";
import { axiosErrorHandler } from "../../utils/errorHandle/axiosErrorHandler";
import styles from "./feedback.module.css";

function Feedback() {
  const [feedback, setFeedback] = useState();
  const [error, setError] = useState({});
  const { t } = useTranslation("feedback");
  const [loading, setLoading] = useState(false);
  const handleSubmit = (e) => {
    setError({});
    e.preventDefault();
    var err = {};
    if (feedback.subject === "") {
      err["subject"] = t("subject_error");
    }

    if (feedback.message === "") {
      err["message"] = t("message_error");
    }
    setError(err);

    if (err.length > 0) {
      return;
    }
    setLoading(true);

    sendFeedback(feedback)
      .then((res) => {
        if (res.status === "success") {
          setFeedback({
            subject: "",
            message: "",
          });
          notifySuccess("Feedback Sent Successfully.");
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        axiosErrorHandler(err, notifyError);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <Container>
      <main className={styles.mainContainer}>
        <section className={styles.hero}>
          <div className={styles.gifContainer}>
            <div className={styles.titleContainer}>
              <div className={styles.title}>{t("title")}</div>
              <div className={styles.subtitle}>{t("subtitle")}</div>
            </div>
          </div>
          <div className={styles.formContainer}>
            <div className={styles.formTitle}>{t("form_title")}</div>
            <form className={styles.forms} autoComplete="off" onSubmit={handleSubmit}>
              <div className={styles.formGroup}>
                <input
                  className={cx(styles.inputs, error.name ? styles.error : null)}
                  type="text"
                  id="subject"
                  placeholder="Subject"
                  value={feedback?.subject}
                  onChange={(e) => {
                    setFeedback({
                      ...feedback,
                      subject: e.target.value,
                    });
                  }}
                ></input>
                <label for="subject" className={styles.labels}>
                  {t("name_label")}
                </label>
                {/* {error.name && (
                  <div className={styles.errorTitle}>{error.name}</div>
                )} */}
              </div>
              <div className={styles.formGroup}>
                <textarea
                  className={cx(styles.inputs, styles.textInput, error.message ? styles.error : null)}
                  type="text"
                  id="message"
                  name="messgae"
                  placeholder="Message"
                  value={feedback?.message}
                  onChange={(e) =>
                    setFeedback({
                      ...feedback,
                      message: e.target.value,
                    })
                  }
                  rows="4"
                ></textarea>
                <label for="message" className={cx(styles.labels, styles.textInputLabel)}>
                  {t("message")}
                </label>
                {/* {error.message && (
                  <div className={styles.errorTitle}>{error.message}</div>
                )} */}
              </div>
              <button id="btn" className={styles.btn}>
                {t("button")}
              </button>
            </form>
          </div>
        </section>
        {/* <section className={styles.locationSection}>
          <div className={styles.location}>
            <div className={styles.locationTitle}>{t("location_title")}</div>
            <div className={styles.locationCompanyName}>{t("party_name")}</div>
            <div className={styles.locationStreet}>{t("location_name")}</div>
          </div>
          <div className={styles.mail}>
            <div className={styles.mailTitle}>{t("reach_out")}</div>
            <div className={styles.mailAddress}>{t("party_email")}</div>
            <div className={styles.faxNumber}>{t("party_fax")}</div>
            <div className={styles.phoneNumber}>{t("party_phone_number")}</div>
          </div>
          <div></div>
        </section> */}
      </main>
    </Container>
  );
}

export default Feedback;
