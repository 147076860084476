import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AiTwotoneCalendar } from "react-icons/ai";
import { useParams } from "react-router-dom";
import PDFViewer from "../../components/PDFViewer/PDFViewer";
import { partyItems } from "./partyConsititution";
import styles from "./PartyConst.module.css";

function PartyConst() {
  const { id } = useParams();
  const [item, setItem] = useState();
  const { i18n } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const temp = partyItems.filter((item) => item.id === id);
    if (temp.length > 0) {
      setItem(temp[0]);
    }
  });

  return (
    <div>
      {item && (
        <section className={styles.containerWrapper}>
          <div className={styles.container}>
            <Container>
              <div className={styles.constitutionDetails}>
                <div className={styles.constitutionTitle}>{i18n.language === "en" ? item.enTitle : item.npTitle}</div>
                <div className={styles.constitutionDate}>
                  <AiTwotoneCalendar />
                  {i18n.language === "en" ? item.enDate : item.npDate}
                </div>
                <PDFViewer src={item.path} />
              </div>
            </Container>
          </div>
        </section>
      )}
    </div>
  );
}

export default PartyConst;
