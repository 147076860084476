import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  EventSlider,
  HistoricalFigures,
  HistorySlider,
  Notices,
  Photos,
  PresidentMessage,
  MainBanner,
} from "../../components/Home";
import "./Home.css";

import VideosandFbpost from "./../../components/Home/VideosandFbpost";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <TimelineSlider /> */}
      <MainBanner />

      <HistoricalFigures />

      {/* <WardMessage /> */}
      <HistorySlider />

      <PresidentMessage />

      {/* message and event section  */}
      <section className="main-section">
        <div className="events-message-wrapper">
          <Container>
            <Row>
              <Col md={12}>
                <EventSlider />
              </Col>

              <Col md={12}>
                <Notices />
              </Col>
            </Row>
          </Container>
        </div>
      </section>

      {/* photos wrapper ********************************** */}
      <Photos />
      {/* banner-sm  */}
      <section className="main-section" style={{ marginTop: "50px", marginBottom: "50px" }}>
        <Container>
          <Row>
            <Col md={12}>
              <div className="banner-image">
                <img src="/static/img/banner-image.jpg" alt="" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Videos wrapper ********************************** */}
      {/* <Videos /> */}

      <VideosandFbpost />
      {/* <div className="container marginbottom">
        <div
          className="facebook"
          class="fb-page"
          data-href="https://www.facebook.com/nckaski"
          data-tabs="timeline"
          data-width={window.innerWidth > 700 ? 500 : window.innerWidth}
          data-height="500"
          data-small-header="false"
          data-adapt-container-width="true"
          data-hide-cover="false"
          data-show-facepile="true"
        >
          <blockquote
            cite="https://www.facebook.com/nckaski"
            class="fb-xfbml-parse-ignore"
          >
            <a href="https://www.facebook.com/nckaski">
              Nepali Congress, Kaski
            </a>
          </blockquote>
        </div>
      </div> */}
    </>
  );
}

export default Home;
