import React from "react";
import { convertToNepali } from "../../utils/nepaliLocale/nepaliConvert";

const NepaliInput = ({ id, inputClass, text, setText, placeholder }) => {
  const handleChange = (e) => {
    setText(convertToNepali(e.target.value));
  };

  return (
    <input
      className={inputClass}
      value={text}
      onChange={handleChange}
      id={id}
      placeholder={placeholder}
    />
  );
};

export default NepaliInput;
