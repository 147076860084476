import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../common/routes";
import { getCookie } from "../../utils/cookie/cookies";
import { ProfileBox } from "../User";
import styles from "./NavLogin.module.css";

const NavLogin = () => {
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  const iconRef = useRef(null);
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (iconRef.current && !iconRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [iconRef]);

  return (
    <div className={styles.navLogin} ref={iconRef}>
      <div className={styles.iconContainer} to={routes.login}>
        <div
          onClick={() => {
            getCookie("token") ? setShowMenu(!showMenu) : navigate("/login");
          }}
        >
          <div className={styles.imageContainer}>
            <img
              src={
                getCookie("profile_picture") ? getCookie("profile_picture") : "/static/img/user.png"
              }
              alt=""
              className={styles.image}
            />
          </div>
        </div>

        {showMenu && (
          <div className={styles.menu}>
            <ProfileBox open={showMenu} setOpen={setShowMenu} />
          </div>
        )}
      </div>
    </div>
  );
};

export default NavLogin;
