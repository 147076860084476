import cx from "classnames";
import React, { useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Loading } from "../../components/Loading";
import { forgotPassword } from "../../utils/api/auth";
import styles from "./ResetPassword.module.css";

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

function ResetPassword() {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const emailRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("resetPassword");

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    emailRef.current.focus();
    setEmailError(false);
    if (email === "") {
      setEmailError(true);
      toast.error("Invalid Email", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    if (validateEmail(email)) {
      forgotPassword({ email: email }).then((res) => {
        if (res.status === "success") {
          setLoading(false);
          toast.success("Email Sent Successfully", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        setLoading(false);
      });
    }
  };

  return loading ? (
    <Loading />
  ) : (
    <div className={styles.container}>
      <div className={styles.title}>{t("reset_password")}</div>
      <div className={styles.subtitle}>{t("password_subtitle")}</div>
      <ToastContainer />
      <form autoComplete="off" className={styles.form} onSubmit={handleSubmit}>
        <label for="email" className={styles.label}>
          {t("email")}
        </label>
        <input
          type="email"
          id="email"
          name="email"
          placeholder={t("your_email")}
          className={cx(styles.input, emailError ? styles.error : null)}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          ref={emailRef}
        />
        <div className={styles.buttonContainer}>
          <Button id="btn" type="submit">
            {t("submit")}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default ResetPassword;
