import { baseGetRequest } from "./base";

const getFinancialStatements = async (page, language) => {
  var url = `/api/financialstatements?language=${language}&page=${page}`;
  var response = await baseGetRequest(url);
  return response;
};

const getFinancialStatementById = async (id) => {
  var response = await baseGetRequest("/api/financialstatements/" + id);
  return response;
};

export { getFinancialStatements, getFinancialStatementById };
