import React from "react";
import styles from "./Member.module.css";
import { FaFacebookF } from "react-icons/fa";
import { BsTwitter, BsInstagram } from "react-icons/bs";
import { FiShare2 } from "react-icons/fi";

function Member({ image, name, post, fbLink, phone }) {
  return (
    <div>
      <div className={styles.memberCard}>
        <img src={image} className={styles.memberImage} alt="" />
        <p className={styles.memberName}>{name}</p>
        <p className={styles.memberName}>{phone}</p>
        <h4 className={styles.memberPost}> {post}</h4>
        <div className={styles.memberIcons}>
          <div className={styles.fbLink}>
            {/* <img src="/static/logo/facebook.svg" className={styles.fb} /> */}
            <a href={fbLink} target="__blank" className={styles.fa}>
              <FaFacebookF />
            </a>
          </div>
          <div className={styles.twitterLink}>
            {/* <img src="/static/logo/twitter.svg" className={styles.twitter} /> */}
            <div className={styles.tw}>
              <BsTwitter />
            </div>
          </div>
          <div className={styles.instaLink}>
            <div className={styles.in}>
              <BsInstagram />
            </div>
          </div>
          <div className={styles.shareLink}>
            <div className={styles.sh}>
              <FiShare2 />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Member;
