import React, { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AiTwotoneCalendar } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { Loading } from "../../components/Loading";
import { getFinancialStatementById } from "../../utils/api/financialStatement";
import { timer } from "../../utils/timer/timer";
import PDFViewer from "../PDFViewer/PDFViewer";
import styles from "./Index.module.css";
import "./style.scss";

function FinancialStatement(props) {
  const assetUrl = useRef(null);
  const [financialStatementData, setFinancialStatementData] = useState({
    files: [],
    english: { title: "" },
    nepali: { title: "" },
    created_at: "",
  });
  const { i18n } = useTranslation();
  const [loading, setLoading] = useState(true);

  const { id } = useParams();

  const fetchFinancialStatementData = (id) => {
    setLoading(true);
    getFinancialStatementById(id)
      .then((res) => {
        assetUrl.current = res.data.asset_url;
        setFinancialStatementData(res.data.financial_statement);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchFinancialStatementData(id);
  }, [id]);
  return loading ? (
    <Loading />
  ) : (
    <section className={styles.containerWrapper}>
      <div className={styles.container}>
        <Container>
          <div className={styles.eventDetails}>
            <div className={styles.eventTitle}>
              {i18n.language === "en"
                ? financialStatementData.english.title
                : financialStatementData.nepali.title}
            </div>
            <div className={styles.eventDate}>
              <AiTwotoneCalendar />
              {timer(financialStatementData.created_at, i18n.language)}
            </div>
            {financialStatementData.file && (
              <PDFViewer src={assetUrl.current + financialStatementData.file} />
            )}
          </div>
        </Container>
      </div>
    </section>
  );
}

export default FinancialStatement;
