import React from "react";

import ReactPlayer from "react-player";

import styles from "./VideoPlayer.module.css";

function Video({ src }) {
  return (
    <div>
      <div className={styles.videoPlayer}>
        <ReactPlayer width={"100%"} height={"100%"} controls={true} url={src} />
      </div>
    </div>
  );
}

export default Video;
