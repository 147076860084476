import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../App";
import { Loading } from "../../components/Loading";
import UserDetailsForm from "../../components/User/UserDetailsForm";
import { getUserById, updateRejectedUser } from "../../utils/api/users";
import { axiosErrorHandler } from "../../utils/errorHandle/axiosErrorHandler";
import { imgCompressor } from "../../utils/image";
import styles from "./UserDetails.module.css";

const ResubmitForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState({ children: [] });
  const [file, setFile] = useState({ raw: null, preview: "" });

  const [loading, setLoading] = useState(false);

  const handleFileChange = async (file) => {
    let compressedImage = await imgCompressor(file);
    setFile({
      raw: compressedImage,
      preview: URL.createObjectURL(compressedImage),
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    //validation
    let err = { children: [] };

    if (data.nepali.name.trim().length === 0) {
      err.npName = "नाम जरुरी छ।";
    }
    if (data.english.name.trim().length === 0) {
      err.enName = "Name is required";
    }
    if (data.date_of_birth.trim().length === 0) {
      err.dob = "Dob is required";
    }
    if (data.gender.trim().length === 0) {
      err.gender = "Select a gender";
    }
    setError(err);
    if (Object.keys(err).length > 0) {
      return;
    }

    setLoading(true);
    updateRejectedUser(data.id, data, file.raw, location.state.token)
      .then((res) => {
        notifySuccess(res.message);
        setLoading(false);
        navigate("/underreview/");
      })
      .catch((err) => {
        setLoading(false);
        axiosErrorHandler(err, notifyError);
      });
  };

  const [data, setData] = useState({
    rejection_message: "",
    identification_number: "",
    email: "",
    phone_number: "",
    gender: "",
    date_of_joining: "",
    date_of_birth: "",
    family_contact_number: "",
    category: "",
    english: {
      name: "",
      address: "",
      father_name: "",
      mother_name: "",
      grandfather_name: "",
      grandmother_name: "",
      partner_name: "",
    },
    nepali: {
      name: "",
      address: "",
      father_name: "",
      mother_name: "",
      grandfather_name: "",
      grandmother_name: "",
      partner_name: "",
    },
    children: [],
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setLoading(true);
      getUserById(location.state.userId, location.state.token)
        .then((res) => {
          setData(res.data.user);
          if (res.data.user.citizenship_document) {
            setFile({
              raw: null,
              preview: res.data.asset_url + res.data.user.citizenship_document,
            });
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          axiosErrorHandler(err, notifyError);
        });
    }

    return () => {
      mounted = false;
    };
  }, []);

  return !location.state ? (
    <Navigate to={"/login/"} />
  ) : loading ? (
    <Loading />
  ) : (
    <Container>
      <div></div>
      <div className={styles.rejectionForm}>Your form has been rejected.</div>
      <div className={styles.rejectionMessage}>Message:</div>
      <div
        className={styles.rejectionMessageBox}
        dangerouslySetInnerHTML={{
          __html: data.rejection_message,
        }}
      ></div>
      <UserDetailsForm
        data={data}
        setData={setData}
        file={file}
        handleFileChange={handleFileChange}
        onSubmit={handleSubmit}
        signup={true}
        error={error}
        setError={setError}
        resubmit={true}
      />
    </Container>
  );
};

export default ResubmitForm;
