export const partyItemsNepali = [
  {
    region: "क्षेत्र नं. १  क",
    folderPath: "regionno1part1",
    sub: [
      {
        name: "पोखरा -  २६",
        filePath: "pokhara26",
      },
      {
        name: "पोखरा -  २७",
        filePath: "pokhara27",
      },
      {
        name: "पोखरा -  २९",
        filePath: "pokhara29",
      },
      {
        name: "पोखरा -  ३०",
        filePath: "pokhara30",
      },
      {
        name: "पोखरा -  ३१ ",
        filePath: "pokhara31",
      },
      {
        name: "पोखरा -  ३२",
        filePath: "pokhara32",
      },
      {
        name: "रुपा गा.पा.- १ ",
        filePath: "rupagapa1",
      },
      {
        name: "रुपा गा.पा.- २",
        filePath: "rupagapa2",
      },
      {
        name: "रुपा गा.पा.- ३",
        filePath: "rupagapa3",
      },
      {
        name: "रुपा गा.पा.- ४",
        filePath: "rupagapa4",
      },
      {
        name: "रुपा गा.पा.- ५",
        filePath: "rupagapa5",
      },
      {
        name: "रुपा गा.पा.- ६",
        filePath: "rupagapa6",
      },
      {
        name: "रुपा गा.पा.- ७",
        filePath: "rupagapa7",
      },
    ],
  },
  {
    region: "क्षेत्र नं. १  ख",
    folderPath: "regionno1part2",
    sub: [
      {
        name: "माडी गा.पा.- १ ",
        filePath: "madigapa1",
      },
      {
        name: "माडी गा.पा.- २",
        filePath: "madigapa2",
      },
      {
        name: "माडी गा.पा.- ३",
        filePath: "madigapa3",
      },
      {
        name: "माडी गा.पा.- ४",
        filePath: "madigapa4",
      },
      {
        name: "माडी गा.पा.- ५",
        filePath: "madigapa5",
      },
      {
        name: "माडी गा.पा.- ६",
        filePath: "madigapa6",
      },
      {
        name: "माडी गा.पा.- ७",
        filePath: "madigapa7",
      },
      {
        name: "माडी गा.पा.- ८",
        filePath: "madigapa8",
      },
      {
        name: "माडी गा.पा.- ९",
        filePath: "madigapa9",
      },
      {
        name: "माडी गा.पा.- १ ०",
        filePath: "madigapa10",
      },
      {
        name: "माडी गा.पा.- १ १ ",
        filePath: "madigapa11",
      },
      {
        name: "माडी गा.पा.- १ २",
        filePath: "madigapa12",
      },
      {
        name: "पोखरा -  १ ०",
        filePath: "pokhara10",
      },
      {
        name: "पोखरा -  १ २",
        filePath: "pokhara12",
      },
      {
        name: "पोखरा -  १ ३",
        filePath: "pokhara13",
      },
      {
        name: "पोखरा -  १ ४",
        filePath: "pokhara14",
      },
      {
        name: "पोखरा -  २८",
        filePath: "pokhara28",
      },
    ],
  },

  {
    region: "क्षेत्र नं. २ क",
    folderPath: "regionno2part1",
    sub: [
      {
        name: "पोखरा -  ७",
        filePath: "pokhara7",
      },
      {
        name: "पोखरा -  १ ५",
        filePath: "pokhara15",
      },
      {
        name: "पोखरा -  १ ७",
        filePath: "pokhara17",
      },
      {
        name: "पोखरा -  २१ ",
        filePath: "pokhara21",
      },
      {
        name: "पोखरा -  ३३",
        filePath: "pokhara33",
      },
    ],
  },

  {
    region: "क्षेत्र नं. २ ख",
    folderPath: "regionno2part2",
    sub: [
      {
        name: "पोखरा -  ४",
        filePath: "pokhara4",
      },
      {
        name: "पोखरा -  ८",
        filePath: "pokhara8",
      },
      {
        name: "पोखरा -  ९",
        filePath: "pokhara9",
      },
      {
        name: "पोखरा -  १ १ ",
        filePath: "pokhara11",
      },
      {
        name: "पोखरा -  १ ६",
        filePath: "pokhara16",
      },
      {
        name: "पोखरा -  २०",
        filePath: "pokhara20",
      },
    ],
  },
  {
    region: "क्षेत्र नं. ३ क",
    folderPath: "regionno3part1",
    sub: [
      {
        name: "माछापुच्छ्रे गा.पा.- १ ",
        filePath: "machhapuchhregapa1",
      },
      {
        name: "माछापुच्छ्रे गा.पा.- २",
        filePath: "machhapuchhregapa2",
      },
      {
        name: "माछापुच्छ्रे गा.पा.- ३",
        filePath: "machhapuchhregapa3",
      },
      {
        name: "माछापुच्छ्रे गा.पा.- ४",
        filePath: "machhapuchhregapa4",
      },
      {
        name: "माछापुच्छ्रे गा.पा.- ५",
        filePath: "machhapuchhregapa5",
      },
      {
        name: "माछापुच्छ्रे गा.पा.- ६",
        filePath: "machhapuchhregapa6",
      },
      {
        name: "माछापुच्छ्रे गा.पा.- ७",
        filePath: "machhapuchhregapa7",
      },
      {
        name: "माछापुच्छ्रे गा.पा.- ८",
        filePath: "machhapuchhregapa8",
      },
      {
        name: "माछापुच्छ्रे गा.पा.- ९",
        filePath: "machhapuchhregapa9",
      },
      {
        name: "पोखरा -  १ ",
        filePath: "pokhara1",
      },
      {
        name: "पोखरा -  २",
        filePath: "pokhara2",
      },
      {
        name: "पोखरा -  ३",
        filePath: "pokhara3",
      },
      {
        name: "पोखरा -  १ ८",
        filePath: "pokhara18",
      },
      {
        name: "पोखरा -  १ ९",
        filePath: "pokhara19",
      },
      {
        name: "पोखरा -  २५",
        filePath: "pokhara25",
      },
    ],
  },

  {
    region: "क्षेत्र नं. ३ ख",
    folderPath: "regionno3part2",
    sub: [
      {
        name: "अन्नपूर्ण गा.पा.- १ ",
        filePath: "annapurnagapa1",
      },
      {
        name: "अन्नपूर्ण गा.पा.- २",
        filePath: "annapurnagapa2",
      },
      {
        name: "अन्नपूर्ण गा.पा.- ३",
        filePath: "annapurnagapa3",
      },
      {
        name: "अन्नपूर्ण गा.पा.- ४",
        filePath: "annapurnagapa4",
      },
      {
        name: "अन्नपूर्ण गा.पा.- ५",
        filePath: "annapurnagapa5",
      },
      {
        name: "अन्नपूर्ण गा.पा.- ६",
        filePath: "annapurnagapa6",
      },
      {
        name: "अन्नपूर्ण गा.पा.- ७",
        filePath: "annapurnagapa7",
      },
      {
        name: "अन्नपूर्ण गा.पा.- ८",
        filePath: "annapurnagapa8",
      },
      {
        name: "अन्नपूर्ण गा.पा.- ९",
        filePath: "annapurnagapa9",
      },
      {
        name: "अन्नपूर्ण गा.पा.- १ ०",
        filePath: "annapurnagapa10",
      },
      {
        name: "अन्नपूर्ण गा.पा.- १ १ ",
        filePath: "annapurnagapa11",
      },

      {
        name: "पोखरा -  ५",
        filePath: "pokhara5",
      },
      {
        name: "पोखरा -  ६",
        filePath: "pokhara6",
      },
      {
        name: "पोखरा -  २२",
        filePath: "pokhara22",
      },
      {
        name: "पोखरा -  २३",
        filePath: "pokhara23",
      },
      {
        name: "पोखरा -  २४",
        filePath: "pokhara24",
      },
    ],
  },
];
