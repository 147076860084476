import React, { useEffect, useRef, useState } from "react";
import { getPopupPhotos } from "../../utils/api/popupPhoto";
import ImageModal from "../Modal/ImageModal";

const PopupImage = () => {
  const [popupPhotos, setPopupPhotos] = useState([]);
  const assetUrl = useRef(null);
  useEffect(() => {
    getPopupPhotos()
      .then((res) => {
        assetUrl.current = res.data.asset_url;
        if (res.data.photos.length > 0) {
          setPopupPhotos(res.data.photos.map((v) => ({ ...v, isOpen: true })));
        }
      })
      .catch((err) => {});
  }, []);

  const closePhoto = (id) => {
    setPopupPhotos((prev) =>
      prev.filter((element) => {
        if (element.id === id) {
          element.isOpen = false;
        }
        return element;
      })
    );
  };

  return (
    <div>
      {popupPhotos.map((element) => (
        <ImageModal
          src={assetUrl.current + element.photo}
          key={element.id}
          open={element.isOpen}
          onClose={() => {
            closePhoto(element.id);
          }}
        />
      ))}
    </div>
  );
};

export default PopupImage;
