import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiTwotoneCalendar } from "react-icons/ai";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { LoadingCard } from "..";
import { notifyError } from "../../App";
import { getEvents } from "../../utils/api/events";
import { axiosErrorHandler } from "../../utils/errorHandle/axiosErrorHandler";
import { timer } from "../../utils/timer/timer";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const EventSlider = () => {
  const eventsSliderSetting = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <IoIosArrowForward />,
    prevArrow: <IoIosArrowBack />,
    autoplay: false,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 772,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 3000,
          dots: true,
        },
      },
    ],
  };
  const [loading, setLoading] = useState(true);
  const { i18n } = useTranslation();
  const { t } = useTranslation("home");
  const [events, setEvents] = useState([]);
  const assetUrl = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    let mounted = true;
    let language = i18n.language === "en" ? "english" : "nepali";
    const fetchEvents = async () => {
      setLoading(true);
      await getEvents(1, language)
        .then((res) => {
          if (res.status === "success") {
            assetUrl.current = res.data.asset_url;
            setEvents(res.data.events.slice(0, 6));
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          axiosErrorHandler(err, notifyError);
        });
    };
    if (mounted) {
      fetchEvents();
    }
    return () => (mounted = false);
  }, [i18n.language]);
  return (
    <div className="events-wrapper">
      <div className="section-title">
        <h3 className="text-dark text-left ">{t("event")}</h3>
        <div className="">
          <Slider {...eventsSliderSetting}>
            {loading
              ? [...Array(6)].map((element, index) => (
                  <LoadingCard key={index} />
                ))
              : events.slice(0, 3).map((event, index) => (
                  <div key={index}>
                    <Link to={`/event/${event.id}`}>
                      <div className="event-image">
                        <img
                          src={
                            event.images.length > 0
                              ? `${assetUrl.current}/${event.images[0].image}`
                              : ""
                          }
                          alt=""
                        />
                        <p className="event-title">{event.title}</p>
                        <p>
                          <AiTwotoneCalendar />
                          {timer(event.created_at, i18n.language)}
                        </p>
                      </div>
                    </Link>
                  </div>
                ))}
          </Slider>

          <div className="w-100 text-center pt-4">
            <button
              className="btn btn-primary mt-4"
              onClick={() => navigate("/events/")}
            >
              {t("view_all")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventSlider;
