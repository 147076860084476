import { Container } from "react-bootstrap";
import styles from "./Hero.module.css";

function Hero({ title }) {
  return (
    <div className={styles.container}>
      <img className={styles.image} src="/static/img/tree.jpg" alt="" />
      <div className={styles.titleContainer}>
        <Container>
          <div className={styles.title}>{title}</div>
        </Container>
      </div>
    </div>
  );
}

export default Hero;
