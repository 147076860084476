import React from "react";
import { Placeholder, Card } from "react-bootstrap";
import styles from "./LoadingCard.module.css";
import cx from "classnames";

const LoadingCard = ({ cardStyle, photoStyle, cardDetailsStyle }) => {
  return (
    <div className={cx(cardStyle)}>
      <div
        className={cx(photoStyle, styles.fadingClass)}
        style={{ background: "black", opacity: "0.6" }}
      ></div>
      <div className={cardDetailsStyle}>
        <Placeholder as={Card.Title} animation="glow">
          <Placeholder xs={8} />
        </Placeholder>
        <Placeholder as={Card.Title} animation="glow">
          <Placeholder xs={6} />
        </Placeholder>
      </div>
    </div>
  );
};

export default LoadingCard;
