import { baseGetRequest } from "./base";

const getHighProfiles = async (language) => {
  var url = `/api/highprofiles?language=${language}`;
  var response = await baseGetRequest(url);
  return response;
};

const getHighProfileById = async (id, language) => {
  var response = await baseGetRequest(
    `/api/highprofiles/${id}/details?language=${language}`
  );
  return response;
};

export { getHighProfiles, getHighProfileById };
