import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AiTwotoneCalendar } from "react-icons/ai";
import { Link } from "react-router-dom";
import { notifyError } from "../../App";
import { LoadingCard } from "../../components";
import Hero from "../../components/Hero/Hero";
import Pagination from "../../components/Pagination/Pagination";
import { SocialMediaShare } from "../../components/Share";
import { getNotices } from "../../utils/api/notices";
import { axiosErrorHandler } from "../../utils/errorHandle/axiosErrorHandler";
import { timer } from "../../utils/timer/timer";
import styles from "./Notices.module.css";
import "./style.scss";

function Notices() {
  const [notices, setNotices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const lastPage = useRef(1);
  const { i18n } = useTranslation();
  const { t } = useTranslation("common");

  const assetUrl = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let mounted = true;
    let language = i18n.language === "en" ? "english" : "nepali";
    const fetchNotices = async () => {
      setLoading(true);
      await getNotices(page, language)
        .then((res) => {
          if (res.status === "success") {
            if (page === 1) {
              lastPage.current = res.data.last_page;
              assetUrl.current = res.data.asset_url;
            } else {
            }
            setNotices(res.data.notices);
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          axiosErrorHandler(err, notifyError);
        });
    };
    if (mounted) {
      fetchNotices();
    }
    return () => (mounted = false);
  }, [page, i18n.language]);

  return (
    <main className={styles.containerWrapper}>
      <Hero title={t("noticeTitle")} />
      <Container>
        <div className={styles.cardContainer}>
          {loading ? (
            [...Array(notices.length > 0 ? notices.length : 5)].map((element, index) => (
              <LoadingCard
                key={index}
                cardStyle={styles.card}
                cardDetailsStyle={styles.eventDetails}
                photoStyle={styles.photos}
              />
            ))
          ) : notices.length > 0 ? (
            notices.map((notice, index) => {
              return (
                <div key={notice.id}>
                  <div
                    className={styles.card}
                    key={index}
                    style={index % 2 !== 0 ? { backgroundColor: "#efefef" } : null}
                  >
                    <div className={styles.photos}>
                      <img
                        src={
                          notice.images.length > 0
                            ? `${assetUrl.current}/${notice.images[0].image}`
                            : ""
                        }
                        alt=""
                        className={styles.images}
                      />
                    </div>

                    <div className={styles.eventDetails}>
                      <div className={styles.detailsContainer}>
                        <div className={styles.eventTitle}>{notice.title}</div>
                        <div className={styles.eventDate}>
                          <AiTwotoneCalendar />
                          {timer(notice.created_at, i18n.language)}
                        </div>
                      </div>
                      <div
                        className={styles.eventDescription}
                        dangerouslySetInnerHTML={{
                          __html: i18n.language === "en" ? notice.description : notice.description,
                        }}
                      ></div>
                      <div className={styles.bottomDiv}>
                        <Link to={`/notice/${notice.id}`} state={{ noticeData: notice }}>
                          <div className={styles.button}>{t("readMore")}</div>
                        </Link>

                        <div>
                          <SocialMediaShare
                            url={process.env.REACT_APP_URL + "/notice/" + notice.id + "/"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="no-data-to-show">{t("noMoreUpcoming")}</div>
          )}
        </div>
        <Pagination page={page} setPage={setPage} lastPage={lastPage.current} />
      </Container>
    </main>
  );
}

export default Notices;
