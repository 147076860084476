import { baseGetRequest } from "./base";

const getNotices = async (page, language) => {
  var url = `/api/notices?language=${language}&page=${page}`;
  var response = await baseGetRequest(url);
  return response;
};

const getNoticeById = async (id) => {
  var response = await baseGetRequest("/api/notices/" + id);
  return response;
};

export { getNotices, getNoticeById };
