import React, { useEffect, useState } from "react";
import styles from "./MainBanner.module.css";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { HomeContext } from "./../../App";
import { Loading } from "./../Loading";
import { useTranslation } from "react-i18next";

function MainBanner() {
  const { homeData, setHomeData } = React.useContext(HomeContext);
  const [active, setActive] = useState(0);
  const [trigger, setTrigger] = useState("");
  const { i18n } = useTranslation();

  const [lang, setLanguage] = useState(i18n.language);

  const decrease = () => {
    setActive((a) => {
      return (
        (a + homeData.homeMainBannerData.length - 1) %
        homeData.homeMainBannerData.length
      );
    });
    setTrigger(active);
  };

  const increase = () => {
    setActive((a) => {
      return (a + 1) % homeData.homeMainBannerData.length;
    });
    setTrigger(active);
  };

  useEffect(() => {
    if (homeData.homeMainBannerData.length > 0) {
      const interval = setInterval(increase, 4000);
      return () => clearInterval(interval);
    }
  }, [homeData.homeMainBannerData.length, trigger]);

  console.log(homeData.homeMainBannerData.length);

  if (homeData.homeMainBannerData.length < 1) {
    return <Loading />;
  }
  return (
    <div className={styles.container}>
      <div className={styles.left} onClick={() => decrease()}>
        <AiOutlineLeft />
      </div>
      <div className={styles.right} onClick={() => increase()}>
        <AiOutlineRight />
      </div>
      <div className={styles.insideContainer}>
        <div className={styles.rightImage}>
          <div
            key={"right11" + active}
            className={styles.absoluteBackgroundLeft1}
            style={{
              background: `url(${
                homeData.url + homeData.homeMainBannerData[active]?.photo
              })`,
            }}
          ></div>
          <div
            key={"right1" + active}
            className={styles.absoluteBackgroundLeft}
            style={{
              background: `url(${
                homeData.url + homeData.homeMainBannerData[active]?.photo || ""
              })`,
            }}
          ></div>
        </div>
        <div className={styles.leftImage}>
          <div className={styles.textWrap}>
            <div className={styles.title}>
              {i18n.language === "np"
                ? homeData.homeMainBannerData[active]?.np_title
                : homeData.homeMainBannerData[active]?.en_title}
            </div>
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{
                __html:
                  i18n.language === "np"
                    ? homeData.homeMainBannerData[active]?.np_description
                    : homeData.homeMainBannerData[active]?.en_description,
              }}
            ></div>
          </div>
          <div
            className={styles.absoluteBackground}
            style={{
              background: `url(${
                homeData.url + homeData?.homeMainBannerData[active]?.photo
              })`,
            }}
          ></div>
          <div className={styles.glass}></div>
        </div>
      </div>
    </div>
  );
}

export default MainBanner;
