import styles from "./index.module.css";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { axiosErrorHandler } from "../../utils/errorHandle/axiosErrorHandler";
import { useNavigate } from "react-router-dom";
import { getNotices } from "../../utils/api/notices";
import { notifyError } from "../../App";
import { timer } from "../../utils/timer/timer";

function Notice() {
  const [notices, setNotices] = useState([]);
  const lastPage = useRef(1);
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const assetUrl = useRef(null);
  useEffect(() => {
    let mounted = true;
    let language = i18n.language === "en" ? "english" : "nepali";
    const fetchNotices = async () => {
      await getNotices(1, language)
        .then((res) => {
          if (res.status === "success") {
            lastPage.current = res.data.last_page;
            assetUrl.current = res.data.asset_url;
            setNotices(res.data.notices);
          }
        })
        .catch((err) => {
          axiosErrorHandler(err, notifyError);
        });
    };
    if (mounted) {
      fetchNotices();
    }
    return () => (mounted = false);
  }, [i18n.language]);
  return (
    <div className={styles.container}>
      <h3>{i18n.language === "en" ? "Recent Notices" : "हालको सूचनाहरू"}</h3>
      {notices.length > 0 ? (
        notices
          .filter((dat, index) => index < 4)
          .map((notice, index) => (
            <div className={styles.card} onClick={() => navigate(`/notice/${notice.id}`)}>
              <div className={styles.imageWrapper}>
                <img
                  className={styles.image}
                  src={
                    notice.images.length > 0 ? `${assetUrl.current}/${notice.images[0].image}` : ""
                  }
                  alt=""
                ></img>
              </div>
              <div className={styles.left}>
                <div className={styles.title}>{notice.title}</div>
                <div className={styles.description}> {timer(notice.created_at, i18n.language)}</div>
              </div>
            </div>
          ))
      ) : (
        <div>{i18n.language === "en" ? "No more recent notices" : "कुनै हालको सूचनाहरू छैनन्"}</div>
      )}
    </div>
  );
}

export default Notice;
