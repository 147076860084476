import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Hero from "../../components/Hero/Hero";
import { Loading } from "../../components/Loading";
import ToleSamiti from "../../components/Tolesamiti/ToleSamiti";
import { getVatriSangathans } from "../../utils/api/vatriSangathan";

function VatriSangathan() {
  const { i18n } = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const assetUrl = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setLoading(true);
    getVatriSangathans(i18n.language === "en" ? "english" : "nepali")
      .then((res) => {
        assetUrl.current = res.data.asset_url;
        setData(res.data.vatri_sangathans);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [i18n.language]);

  return loading ? (
    <Loading />
  ) : (
    <>
      <Hero title={i18n.language === "en" ? "Our Vatri Sangathan" : "हाम्रो भात्री संगठन"} />
      <Container>
        <ToleSamiti data={data} assetUrl={assetUrl.current} />
      </Container>
    </>
  );
}

export default VatriSangathan;
