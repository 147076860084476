import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Hero from "../../components/Hero/Hero";
import PDFViewer from "../../components/PDFViewer/PDFViewer";
import styles from "./ConstitutionOfNepal.module.css";

function ConstitutionOfNepal() {
  const { t } = useTranslation("common");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.containerWrapper}>
      <Hero title={t("constitutionOfNepal")} />
      <Container className={styles.container}>
        <div className={styles.constitutionTitle}>{t("constitutionOfNepal")}</div>
        <div className={styles.pdfContainer}>
          <PDFViewer src="/static/pdf/nepal_consititution.pdf" />
        </div>
      </Container>
    </div>
  );
}

export default ConstitutionOfNepal;
