const routes = {
  home: "/",
  aboutParty: {
    partyPhilosophy: "/aboutparty/philosophy/",
    partyHistory: "/aboutparty/history/",
    partyConstitution: "/aboutparty/constitution/",
    nepalConstitution: "aboutparty/nepalconstitution/",
    partyRules: "/aboutparty/rules/",
    parichaya: "/aboutparty/parichaya/",
  },
  media: {
    photoGallery: "/media/photos/",
    videoGallery: "/media/videos/",
    pressRelease: "/media/pressreleases/",
    news: "/media/blogs/",
  },
  kriyasil: {
    new: "/kriyasil/new/",
    old: "/kriyasil/old/",
  },
  events: "/events/",
  notices: "/notices/",
  about: {
    presidentMessage: "/presidentmessage/2/",
    executiveCommittee: "/about/committee/",
    activeMembers: "/about/activemembers/",
    members: "/about/members/",
    generalMembers: "/about/generalmembers/",
    wardHistory: "/about/wardhistory/",
    annualPlan: "about/annualplan/",
    vatriSangathan: "/about/vatrisangathan/",
    highProfile: "/about/highprofile/",
  },
  contactUs: "/contactus/",
  terms: "/terms/",
  privacyPolicy: "/privacypolicy/",
  user: {
    profile: "/auth/user/profile/",
  },
  working_committe: "/workingcommitte",
};

export { routes };
