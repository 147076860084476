import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AiOutlineDelete } from "react-icons/ai";
import { FaCamera } from "react-icons/fa";
import { GrUpdate } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../App";
import { Loading } from "../../components/Loading";
import { UpdatePictureModal } from "../../components/User";
import { EditEmail, EditPassword, EditPhone } from "../../components/User/edit";
import UserDetailsForm from "../../components/User/UserDetailsForm";
import { logout } from "../../utils/api/auth";
import {
  getProfileDetails,
  getUserById,
  removeProfilePicture,
  updateProfilePicture,
  updateUser,
} from "../../utils/api/users";
import { clearCookie, getCookie, setCookie } from "../../utils/cookie/cookies";
import { axiosErrorHandler } from "../../utils/errorHandle/axiosErrorHandler";
import { imgCompressor } from "../../utils/image";
import { convertToNepali } from "../../utils/nepaliLocale/nepaliConvert";
import Contribution from "../contribution/Contribution";
import styles from "./UserDetails.module.css";

const UserDetails = () => {
  const navigate = useNavigate();
  const [error, setError] = useState({ children: [] });
  const { i18n } = useTranslation();
  const [file, setFile] = useState({ raw: null, preview: "" });
  const [profilePicture, setProfilePicture] = useState({
    raw: null,
    preview: getCookie("profile_picture") ? getCookie("profile_picture") : "/static/img/user.png",
  });
  const [profile, setProfile] = useState({});

  const [showModal, setShowModal] = useState(false);

  const [showEditOption, setShowEditOption] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const iconRef = useRef(null);
  const assetUrl = useRef(null);
  const [loading, setLoading] = useState(false);
  const handleFileChange = async (file) => {
    let compressedImage = await imgCompressor(file);
    setFile({
      raw: compressedImage,
      preview: URL.createObjectURL(compressedImage),
    });
  };

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (iconRef.current && !iconRef.current.contains(event.target)) {
        setShowEditOption(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [iconRef]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onProfilePictureUpdate = () => {
    let userId = getCookie("user_id");
    updateProfilePicture(userId, profilePicture.raw)
      .then((res) => {
        notifySuccess("Successfully updated");
        setCookie("profile_picture", res.data.asset_url + res.data.profile_picture);
        setProfile({
          ...profile,
          profile_picture: res.data.profile_picture,
        });
        setShowModal(false);
      })
      .catch((err) => {
        axiosErrorHandler(err, notifyError);
      });
  };

  const onProfilePictureRemove = () => {
    let userId = getCookie("user_id");
    removeProfilePicture(userId)
      .then((res) => {
        notifySuccess(res.message);
        clearCookie("profile_picture");
        setProfilePicture({
          raw: "",
          preview: "/static/img/user.png",
        });
        setProfile({ ...profile, profile_picture: null });
      })
      .catch((err) => {
        axiosErrorHandler(err, notifyError);
      });
  };

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      setLoading(true);
      getProfileDetails(getCookie("user_id"))
        .then((res) => {
          assetUrl.current = res.data.asset_url;
          setProfile(res.data.user);
          if (res.data.user.profile_picture) {
            setCookie("profile_picture", res.data.asset_url + res.data.user.profile_picture);
            setProfilePicture({
              raw: null,
              preview: res.data.asset_url + res.data.user.profile_picture,
            });
          } else {
            clearCookie("profile_picture");
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          axiosErrorHandler(err, notifyError);
        });
    }

    return () => {
      mounted = false;
    };
  }, [refresh]);

  const { t } = useTranslation("userProfile");

  const handleSubmit = (e) => {
    e.preventDefault();

    //validation
    let err = { children: [] };
    if (data.nepali.name.trim().length === 0) {
      err.npName = "नाम जरुरी छ।";
    }
    if (data.english.name.trim().length === 0) {
      err.enName = "Name is required";
    }
    if (!data.date_of_birth || data.date_of_birth.trim().length === 0) {
      err.dob = "Dob is required";
    }
    if (data.gender.trim().length === 0) {
      err.gender = "Select a gender";
    }
    for (let index = 0; index < data.children.length; index++) {
      if (data.children[index].english.name.trim().length === 0) {
        err.children[index] = { english: "Name is required." };
      }
      if (data.children[index].nepali.name.trim().length === 0) {
        err.children[index] = {
          ...err.children[index],
          nepali: "नाम जरुरि छ",
        };
      }
    }
    setError(err);
    if (Object.keys(err).length > 1 || err.children.length > 0) {
      return;
    }

    setLoading(true);
    updateUser(data.id, data, file.raw)
      .then((res) => {
        notifySuccess(res.message);
        setCookie("eng_name", data.english.name);
        setCookie("np_name", data.nepali.name);
        setRefresh(!refresh);
      })
      .catch((err) => {
        setLoading(false);
        axiosErrorHandler(err, notifyError);
      });
  };

  const handleLogOut = async () => {
    setLoading(true);
    try {
      await logout();
    } catch (e) {
      console.log(e);
    }
    clearCookie("token");
    clearCookie("id");
    clearCookie("name");
    clearCookie("identification_number");
    clearCookie("profile_picture");
    navigate("/");
  };

  const [data, setData] = useState({
    gender: "",
    date_of_joining: "",
    family_contact_number: "",
    date_of_birth: "",
    category: "",
    english: {
      name: "",
      address: "",
      father_name: "",
      mother_name: "",
      grandfather_name: "",
      grandmother_name: "",
      partner_name: "",
    },
    nepali: {
      name: "",
      address: "",
      father_name: "",
      mother_name: "",
      grandfather_name: "",
      grandmother_name: "",
      partner_name: "",
    },
    children: [],
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setLoading(true);
      getUserById(getCookie("user_id"))
        .then((res) => {
          setData(res.data.user);
          if (res.data.user.citizenship_document) {
            setFile({
              raw: null,
              preview: res.data.asset_url + res.data.user.citizenship_document,
            });
          }
          if (res.data.user.profile_picture) {
            setProfilePicture({
              raw: null,
              preview: res.data.asset_url + res.data.user.profile_picture,
            });
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          axiosErrorHandler(err, notifyError);
        });
    }

    return () => {
      mounted = false;
    };
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <Container className="py-4 profile-section-wrapper">
      <UpdatePictureModal
        show={showModal}
        setShow={setShowModal}
        profilePicture={profilePicture}
        setProfilePicture={setProfilePicture}
        onSave={onProfilePictureUpdate}
      />
      {/* <div className={styles.profile}>
        <div className={styles.imageContainer}>
          <div className={styles.imageWrapper}>
            <img src={profilePicture ? profilePicture : "/static/img/user.png"} alt="" className={styles.image} />
          </div>
        </div>
        <div className={styles.profileDetails}>
          <div>{getCookie(i18n.language === "en" ? "eng_name" : "np_name")}</div>
          {i18n.language === "en" ? data.identification_number : convertToNepali(data.identification_number)}
          <div style={{ fontSize: "0.75em" }}></div>
          <div style={{ marginTop: "0.5em" }}>
            <Button
              style={{ padding: "0.2em 1.5em", fontSize: "0.85em" }}
              onClick={() => {
                navigate("/auth/user/profile/");
              }}
            >
              Edit profile
            </Button>
          </div>
        </div>
      </div>
      <UserDetailsForm
        data={data}
        setData={setData}
        file={file}
        handleFileChange={handleFileChange}
        error={error}
        onSubmit={handleSubmit}
      /> */}

      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Row>
          <Col lg={3} className="mt-1">
            <div className={styles.profileNavWrapper}>
              <div className={styles.profile}>
                <div className={styles.imageContainer}>
                  <div className={styles.imageWrapper}>
                    <img src={profilePicture.preview} alt="" className={styles.image} />
                    <button className={styles.cameraIcon} ref={iconRef}>
                      <FaCamera />

                      <div
                        className={styles.updateProfile}
                        onClick={() => {
                          setShowEditOption(false);
                          setShowModal(true);
                        }}
                      >
                        <GrUpdate />
                      </div>
                      {profile.profile_picture && (
                        <div className={styles.removeProfile} onClick={onProfilePictureRemove}>
                          <AiOutlineDelete />
                        </div>
                      )}
                    </button>
                  </div>
                </div>
                <div className={styles.profileDetails}>
                  <div>{getCookie(i18n.language === "en" ? "eng_name" : "np_name")}</div>
                  {i18n.language === "en"
                    ? data.identification_number
                    : convertToNepali(data.identification_number)}
                  {/* <div style={{ fontSize: "0.75em" }}></div> */}
                  <div style={{ marginTop: "0.5em" }}>
                    {/* <Button
                      style={{ padding: "0.2em 1.5em", fontSize: "0.85em" }}
                      eventKey="first"
                    >
                      Edit profile
                    </Button> */}
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item>
                        <Nav.Link
                          eventKey="editProfile"
                          className="btn  btn-primary bg-primary text-white d-flex justify-content-center "
                          style={{ padding: "5px", fontSize: "0.85em" }}
                          onClick={() => {
                            window.scrollTo(0, 0);
                          }}
                        >
                          {t("edit_profile")}
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                </div>
              </div>

              <Nav variant="pills" className="flex-column pt-2">
                <Nav.Item>
                  <Nav.Link
                    eventKey="first"
                    className={styles.navLink}
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    {t("general_information")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="second"
                    className={styles.navLink}
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    {t("contributions")}
                  </Nav.Link>
                </Nav.Item>
              </Nav>

              <Button className="btn btn-red py-2 w-100 mt-3 log-off-btn" onClick={handleLogOut}>
                {t("logout")}
              </Button>
            </div>
          </Col>

          <Col lg={9} className="mt-1">
            <Tab.Content>
              <Tab.Pane eventKey="editProfile" className={styles.tabContent}>
                <div>
                  <div className={styles.tabTitle}>
                    <h4>{t("edit_profile")}</h4>
                  </div>
                  <div className={styles.tabContentDetails}>
                    <EditEmail loading={loading} setLoading={setLoading} />
                    <EditPassword loading={loading} setLoading={setLoading} />
                    <EditPhone loading={loading} setLoading={setLoading} />
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="first" className={styles.tabContent}>
                <div>
                  <div className={styles.tabTitle}>
                    <h4>{t("general_information")}</h4>
                  </div>
                  <div className={styles.tabContentDetails}>
                    <UserDetailsForm
                      data={data}
                      setData={setData}
                      file={file}
                      handleFileChange={handleFileChange}
                      error={error}
                      setError={setError}
                      onSubmit={handleSubmit}
                    />
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="second" className={styles.tabContent}>
                <div>
                  {/* <div className={styles.tabTitle}>
                    <h4>{t("contributions")}</h4>
                  </div> */}
                  <div className={styles.tabContentDetails}>
                    <Contribution />
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </Container>
  );
};

export default UserDetails;
