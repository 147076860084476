import { baseGetRequest } from "./base";

const getBlogs = async (page, language) => {
  var url = `/api/blogs?language=${language}&page=${page}`;
  var response = await baseGetRequest(url);
  return response;
};

const getBlogById = async (id) => {
  var response = await baseGetRequest("/api/blogs/" + id);
  return response;
};

export { getBlogs, getBlogById };
