import React, { useEffect } from "react";
import { useState } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Loading } from "../../components/Loading";
import { EditEmail, EditPassword, EditPhone } from "../../components/User/edit";
import styles from "./EditProfile.module.css";

const EditProfile = () => {
  const location = useLocation();
  const url = new URLSearchParams(location.search);
  const title = url.get("title");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("userProfile");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <Container>
      <div className={styles.wrapper}>
        <div className={styles.title}>{t("edit_" + title)}</div>
      </div>
      {title === "email" ? (
        <EditEmail loading={loading} setLoading={setLoading} />
      ) : title === "password" ? (
        <EditPassword loading={loading} setLoading={setLoading} />
      ) : title === "phone" ? (
        <EditPhone loading={loading} setLoading={setLoading} />
      ) : null}
    </Container>
  );
};

export default EditProfile;
