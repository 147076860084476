import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "./ActiveMember.module.css";
import Newkriyasil from "./../kriyasil/Newkriyasil";
import Oldkriyasil from "./../kriyasil/Oldkriyasil";

function ActiveMember() {
  const { t } = useTranslation("common");
  const { i18n } = useTranslation();
  const [tab, setTab] = useState("new");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.containerWrapper}>
      <Container>
        <div className={styles.container}>
          <div className={styles.imageContainer}>
            <img src="/static/img/congress-flag-waving.png" className={styles.image} alt="" />
          </div>
          <div className={styles.titleContainer}>
            <div className={styles.topTitle}>{t("publishedBy")}</div>
            <div className={styles.partyName}>{t("partyName")}</div>
            <div className={styles.activeTitle}>{t("activeMemberName")}</div>
            <div className={styles.locationContainer}>
              <div className={styles.location}>
                <span className={styles.typeTitle}>{t("type")}: </span>
                <span className={styles.typeSubtitle}>{t(`${tab}`)}</span>
              </div>
              <div className={styles.district}>
                <span className={styles.districtTitle}>{t("district")}: </span>
                <span className={styles.districtSubtitle}>{t("districtName")}</span>
              </div>
            </div>
          </div>
          <div className={styles.photoContainer}>
            <img src="/static/img/congress_tree.png" className={styles.photo} alt="" />
          </div>
        </div>
        <div className={styles.tabWrapper}>
          <div className={styles.tabContain}>
            {["new", "renew"].map((dat, i) => {
              return (
                <div
                  onClick={() => setTab(dat)}
                  key={i}
                  className={dat === tab ? styles.active : null}
                >
                  {t(`${dat}`)} {i18n.language === "np" ? "कृयासिल सदस्य" : "Active Members"}
                </div>
              );
            })}
          </div>
          <div>{tab === "new" ? <Newkriyasil /> : <Oldkriyasil />}</div>
        </div>
      </Container>
    </div>
  );
}

export default ActiveMember;
