import ReactDOM from "react-dom";
import styles from "./Modal.module.css";

function Modal({ open, children, onClose, setDivRef }) {
  const modalRoot = document.getElementById("portal");
  if (!open) return null;
  return ReactDOM.createPortal(
    <div
      className={styles.containerWrapper}
      ref={(ref) => {
        setDivRef(ref);
      }}
    >
      <button className={styles.button} onClick={onClose}>
        &times;
      </button>
      {children}
    </div>,
    modalRoot
  );
}

export default Modal;
