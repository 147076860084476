import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { notifyError, notifySuccess } from "../../../App";
import { updatePhone } from "../../../utils/api/users";
import { getCookie } from "../../../utils/cookie/cookies";
import { axiosErrorHandler } from "../../../utils/errorHandle/axiosErrorHandler";
import { NumberInput } from "../../Input";
import styles from "./EditPhone.module.css";

const EditPhone = ({ loading, setLoading }) => {
  const [phone, setPhone] = useState("");
  const { t } = useTranslation("userProfile");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({});
  const handleSubmit = (e) => {
    e.preventDefault();
    setError({});
    let err = {};
    if (phone.trim().length !== 10 || isNaN(phone.trim())) {
      err.phone = "Enter a valid phone number";
    }
    if (password.length === 0) {
      err.password = "Password is required";
    }

    if (Object.keys(err).length > 0) {
      setError(err);
      return;
    }
    setLoading(true);
    updatePhone(getCookie("user_id"), {
      phone_number: phone,
      password: password,
    })
      .then((res) => {
        setLoading(false);
        notifySuccess(res.message);
        // navigate("/auth/user/profile/");
      })
      .catch((err) => {
        setLoading(false);
        axiosErrorHandler(err, notifyError);
      });
  };
  return (
    <div>
      <form className={styles.forms} autoComplete="off" onSubmit={handleSubmit}>
        <h5 className={styles.editTitle}>Edit Phone</h5>
        <div className={styles.formGroup}>
          <NumberInput
            inputClass={styles.inputs}
            id="phone"
            name="phone"
            placeholder={t("phone")}
            text={phone}
            setText={(val) => setPhone(val)}
            required
          />
          <label for="phone" className={styles.labels}>
            {t("phone")}
          </label>
          {error.phone && <div className={styles.errorTitle}>{error.phone}</div>}
        </div>
        <div className={styles.formGroup}>
          <input
            className={styles.inputs}
            type="password"
            id="phonepassword"
            name="password"
            placeholder={t("password")}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <label for="phonepassword" className={styles.labels}>
            {t("password")}
          </label>
          {error.password && <div className={styles.errorTitle}>{error.password}</div>}
        </div>
        <div className={styles.formGroup}>
          <Button onClick={handleSubmit}>{t("submit")}</Button>
        </div>
      </form>
    </div>
  );
};

export default EditPhone;
