import cx from "classnames";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { notifyError, notifySuccess } from "../../App";
import { Loading } from "../../components/Loading";
import { sendMessage } from "../../utils/api/connect";
import { axiosErrorHandler } from "../../utils/errorHandle/axiosErrorHandler";
import styles from "./Contact.module.css";

function Contact() {
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState({});
  const { t } = useTranslation("contact");
  const [loading, setLoading] = useState(false);
  const handleSubmit = (e) => {
    setError({});
    e.preventDefault();
    var err = {};
    if (name === "") {
      err["name"] = t("name_error");
    }
    if (address === "") {
      err["address"] = t("address_error");
    }
    if (phone === "") {
      err["phone"] = t("phone_number_error_empty");
    } else if (phone.trim().length < 10) {
      err["phone"] = t("phone_number_error_length");
    }
    if (message === "") {
      err["message"] = t("message_error");
    }
    setError(err);
    if (name && address && phone && message) {
      setLoading(true);
      sendMessage({
        name: name,
        address: address,
        phone_number: phone,
        message: message,
      })
        .then((res) => {
          if (res.status === "success") {
            setName("");
            setAddress("");
            setPhone("");
            setMessage("");
            notifySuccess(res.message);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          axiosErrorHandler(err, notifyError);
        });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <Container>
      <main className={styles.mainContainer}>
        <section className={styles.hero}>
          <div className={styles.gifContainer}>
            <div className={styles.titleContainer}>
              <div className={styles.title}>{t("title")}</div>
              <div className={styles.subtitle}>{t("subtitle")}</div>
            </div>
          </div>
          <div className={styles.formContainer}>
            <div className={styles.formTitle}>{t("form_title")}</div>
            <form
              className={styles.forms}
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <div className={styles.formGroup}>
                <input
                  className={cx(
                    styles.inputs,
                    error.name ? styles.error : null
                  )}
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                ></input>
                <label for="name" className={styles.labels}>
                  {t("name_label")}
                </label>
                {error.name && (
                  <div className={styles.errorTitle}>{error.name}</div>
                )}
              </div>

              <div className={styles.formGroup}>
                <input
                  className={cx(
                    styles.inputs,
                    error.address ? styles.error : null
                  )}
                  type="address"
                  id="address"
                  name="address"
                  placeholder="address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                ></input>
                <label for="address" className={styles.labels}>
                  {t("address_label")}
                </label>
                {error.address && (
                  <div className={styles.errorTitle}>{error.address}</div>
                )}
              </div>
              <div className={styles.formGroup}>
                <input
                  className={cx(
                    styles.inputs,
                    error.phone ? styles.error : null
                  )}
                  type="tel"
                  id="phone"
                  name="phone"
                  placeholder="Phone no."
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                ></input>
                <label for="phone" className={styles.labels}>
                  {t("phone_number_label")}
                </label>
                {error.phone && (
                  <div className={styles.errorTitle}>{error.phone}</div>
                )}
              </div>
              <div className={styles.formGroup}>
                <textarea
                  className={cx(
                    styles.inputs,
                    styles.textInput,
                    error.message ? styles.error : null
                  )}
                  type="text"
                  id="message"
                  name="messgae"
                  placeholder="Message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  rows="4"
                ></textarea>
                <label
                  for="message"
                  className={cx(styles.labels, styles.textInputLabel)}
                >
                  {t("message")}
                </label>
                {error.message && (
                  <div className={styles.errorTitle}>{error.message}</div>
                )}
              </div>
              <button id="btn" className={styles.btn}>
                {t("button")}
              </button>
            </form>
          </div>
        </section>
        <section className={styles.locationSection}>
          <div className={styles.location}>
            <div className={styles.locationTitle}>{t("location_title")}</div>
            <div className={styles.locationCompanyName}>{t("party_name")}</div>
            <div className={styles.locationStreet}>{t("location_name")}</div>
          </div>
          <div className={styles.mail}>
            <div className={styles.mailTitle}>{t("reach_out")}</div>
            <div className={styles.mailAddress}>{t("party_email")}</div>
            <div className={styles.faxNumber}>{t("party_fax")}</div>
            <div className={styles.phoneNumber}>{t("party_phone_number")}</div>
          </div>
          <div></div>
        </section>
      </main>
    </Container>
  );
}

export default Contact;
