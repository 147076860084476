import cx from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { notifyError } from "../../App";
import Hero from "../../components/Hero/Hero";
import { Loading } from "../../components/Loading";
import Pagination from "../../components/Pagination/Pagination";
import { getImages } from "../../utils/api/imageGallery";
import { axiosErrorHandler } from "../../utils/errorHandle/axiosErrorHandler";
import styles from "./Photo.module.css";

function Photo() {
  const [photos, setPhotos] = useState([]);
  const [currentPhoto, setCurrentPhoto] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = useState(1);
  const lastPage = useRef(1);
  const assetUrl = useRef(null);
  const { t } = useTranslation(["navbar", "common"]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClick = (id) => {
    setIsOpen(true);
    setCurrentPhoto(id);
  };

  useEffect(() => {
    let mounted = true;
    const fetchPhotos = () => {
      setLoading(true);
      getImages(page)
        .then((res) => {
          if (res.status === "success") {
            if (page === 1) {
              lastPage.current = res.data.last_page;
              assetUrl.current = res.data.asset_url;
            } else {
            }
            setPhotos(res.data.photos);
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          axiosErrorHandler(err, notifyError);
        });
    };
    if (mounted) {
      fetchPhotos();
    }
    return () => (mounted = false);
  }, [page]);
  return loading ? (
    <Loading />
  ) : photos.length > 0 ? (
    <div className={styles.containerWrapper}>
      <Hero title={t("photo_gallery")} />
      <Container>
        <div className={styles.allImages}>
          {photos.map((photo, index) => (
            <div key={photo.id} className={styles.photoContainer} onClick={() => handleClick(index)}>
              <img
                src={new URL(photo.photo, assetUrl.current).href}
                className={cx(styles.photo, loaded ? styles.loadedImage : styles.notLoadedImage)}
                onLoad={() => {
                  setLoaded(true);
                }}
                alt=""
              />
            </div>
          ))}
          {isOpen && (
            <Lightbox
              style={{ zIndex: "2000", padding: "1em" }}
              mainSrc={new URL(photos[currentPhoto].photo, assetUrl.current).href}
              nextSrc={new URL(photos[(currentPhoto + 1) % photos.length].photo, assetUrl.current).href}
              prevSrc={new URL(photos[(currentPhoto + photos.length - 1) % photos.length].photo, assetUrl.current).href}
              onCloseRequest={() => setIsOpen(false)}
              onMovePrevRequest={() => setCurrentPhoto((currentPhoto + photos.length - 1) % photos.length)}
              onMoveNextRequest={() => setCurrentPhoto((currentPhoto + 1) % photos.length)}
            />
          )}
        </div>
        <Pagination page={page} setPage={setPage} lastPage={lastPage.current} />
      </Container>
    </div>
  ) : (
    <div>{t("noMoreUpcoming", { ns: "common" })}</div>
  );
}

export default Photo;
