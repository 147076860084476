import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import useWindowDimensions from "../../common/useWindowDimensions";
import { SocialMediaShare } from "../../components/Share";
import styles from "./ToleSamiti.module.css";

function ToleSamiti({ data, assetUrl }) {
  const { width } = useWindowDimensions();
  return (
    <div className={styles.samitiContainer}>
      <Container>
        <div className={styles.samitiInnerContainer}>
          {data.map((element, index) => (
            <Row className={styles.overlay} key={element.id}>
              <Col md={4} className={styles.paddingMycol}>
                <div className={styles.samitiImage}>
                  <img src={assetUrl + element.images[0].image} alt="" />
                </div>
              </Col>
              <Col md={8} className={styles.paddingMycol}>
                <div className={styles.samitiDescription}>
                  <h4 className={styles.titleb}>{element.title}</h4>

                  <p
                    className={styles.descriptionText}
                    dangerouslySetInnerHTML={{
                      __html: element.description,
                    }}
                  ></p>
                  <div className={styles.buttonContainer}>
                    <Link to={`/about/vatriSangathan/${element.id}`}>
                      <div
                        style={
                          width >= 767
                            ? index % 2 === 0
                              ? { order: "-1" }
                              : { order: "1" }
                            : { order: "1" }
                        }
                        className={styles.button}
                      >
                        Read More
                      </div>
                    </Link>
                    <div className={styles.memberIcons}>
                      <SocialMediaShare
                        url={process.env.REACT_APP_URL + "/vatriSangathan/" + element.id + "/"}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          ))}
        </div>
      </Container>
    </div>
  );
}

export default ToleSamiti;
