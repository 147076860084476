import React, { useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  FaFacebookF,
  FaGlobeAsia,
  FaInstagram,
  FaTwitter,
} from "react-icons/fa";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import styles from "./President.module.css";
import cx from "classnames";
import "./syles.css";

const HistoricalFigures = () => {
  const { t } = useTranslation("home");
  const navigate = useNavigate();
  const ref = useRef();

  const presidentMessageSliderSetting = {
    dots: true,
    infinite: true,

    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    nextArrow: <IoIosArrowForward />,
    prevArrow: <IoIosArrowBack />,
    autoplay: false,
    autoplaySpeed: 4000,
    className: "center",
    // centerMode: true,

    // centerPadding: "60px",
  };

  return (
    <section className="main-section president-message">
      <Container>
        <div id="modify" className="high-order-message">
          <Slider {...presidentMessageSliderSetting} ref={ref}>
            {t("presidentMessage", { returnObjects: true }).map((element) => (
              <Row
                className={cx("d-inline-flex", styles.marginBottom)}
                key={element.id}
              >
                <Col
                  lg={5}
                  className="d-flex padding-left-message align-items-center justify-content-center"
                >
                  <div
                    id="card-presedent"
                    className={cx("president-message-wrapper", styles.absolute)}
                  >
                    <div
                      className="p-message-content"
                      style={{ postion: "relative" }}
                    >
                      <img
                        className={styles.image}
                        src={element.image}
                        alt=""
                      />

                      <div className="section-title">
                        <h3>{element.title}</h3>
                        <h4>{element.name}</h4>
                        <h6 className="text-primary">{t("jaynepal")}</h6>
                      </div>
                      <p className={cx("pt-2", styles.presidentmessage)}>
                        {element.description}{" "}
                      </p>
                      <div className="text-center pt-2">
                        <button
                          className="btn btn-primary text-center"
                          onClick={() =>
                            navigate(`/presidentmessage/${element.id}/`)
                          }
                        >
                          {t("readmore")}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="president-social-media">
                    <a
                      href={element.facebook}
                      target="_blank"
                      className="president-social"
                    >
                      {" "}
                      <FaFacebookF />{" "}
                    </a>
                    <a
                      href={element.instagram}
                      target="_blank"
                      className="president-social"
                    >
                      {" "}
                      <FaInstagram />{" "}
                    </a>
                    <a
                      href={element.twitter}
                      target="_blank"
                      className="president-social"
                    >
                      {" "}
                      <FaTwitter />{" "}
                    </a>
                    <a
                      href={element.globe}
                      target="_blank"
                      className="president-social"
                    >
                      {" "}
                      <FaGlobeAsia />{" "}
                    </a>
                  </div>
                </Col>
                <Col lg={7} className="float-end">
                  <div className="president-image">
                    <img id="imagepresedent" src={element.image} alt="" />
                  </div>
                </Col>
              </Row>
            ))}
          </Slider>
        </div>
      </Container>
    </section>
  );
};

export default HistoricalFigures;

//  <Row className="d-inline-flex" key={element.id}>
//    <Col
//      lg={5}
//      className="d-flex padding-left-message align-items-center justify-content-center"
//    >
//      <div className="president-message-wrapper">
//        <div className="p-message-content">
//          <div className="section-title">
//            <h3>{element.title}</h3>
//            <h4>{element.name}</h4>
//            <h6 className="text-primary">{t("jaynepal")}</h6>
//          </div>
//          <p className="pt-2">{element.description} </p>
//          <div className="text-center pt-2">
//            <button
//              className="btn btn-primary text-center"
//              onClick={() => navigate(`/presidentmessage/${element.id}/`)}
//            >
//              {t("readmore")}
//            </button>
//          </div>
//        </div>
//      </div>
//      <div className="president-social-media">
//        <a href="#" target="_blank" className="president-social">
//          {" "}
//          <FaFacebookF />{" "}
//        </a>
//        <a href="#" target="_blank" className="president-social">
//          {" "}
//          <FaInstagram />{" "}
//        </a>
//        <a href="#" target="_blank" className="president-social">
//          {" "}
//          <FaTwitter />{" "}
//        </a>
//        <a href="#" target="_blank" className="president-social">
//          {" "}
//          <FaGlobeAsia />{" "}
//        </a>
//      </div>
//    </Col>
//    <Col lg={7} className="float-end">
//      <div className="president-image">
//        <img src={element.image} alt="" />
//      </div>
//    </Col>
//  </Row>;
