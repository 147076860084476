import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./assets/style/style.scss";
import AuthRoutes from "./AuthRoutes";
import { Event, Footer, HeaderNavbar, Topnav } from "./components";
import Blog from "./components/Blog";
import FinancialStatement from "./components/FinancialStatement";
import HighProfile from "./components/HighProfile";
import Notice from "./components/Notice";
import PopupImage from "./components/PopupImage";
import PressRelease from "./components/PressRelease";
import Vatri from "./components/VatriSangathan";
import { Events, Home, Login, NotFound, Photo, Video } from "./pages";
import { PartyHistory } from "./pages/aboutParty";
import ActiveMember from "./pages/activeMember/ActiveMember";
import Blogs from "./pages/blogs";
import Committee from "./pages/committee/Committee";
import Member from "./pages/committee/Member";
import ConstitutionOfNepal from "./pages/constitutionOfNepal/ConstitutionOfNepal";
import Contact from "./pages/contact/Contact";
import Contribution from "./pages/contribution/Contribution";
import FinancialStatements from "./pages/financialStatement";
import GeneralMember from "./pages/generalMember/GeneralMember";
import HighProfiles from "./pages/highProfile/HighProfiles";
import Notices from "./pages/notices";
import PartyConstitution from "./pages/partyConsititution";
import Workingcommitte from "./pages/workingCommitte";
import WorkingConst from "./pages/workingCommitte/WorkingConst";
import PartyConst from "./pages/partyConsititution/PartyConst";
import { PresidentMessage } from "./pages/presidentMessage";
import PressReleases from "./pages/pressReleases";
import ResetPassword from "./pages/resetPassword/ResetPassword";
import RulesAndRegulation from "./pages/rulesAndRegulation";
import RulesConst from "./pages/rulesAndRegulation/RulesConst";
import UnderReview from "./pages/underReview/UnderReview";
import Feedback from "./pages/user/feedback";
import Parichaya from "./pages/parichaya/Parichaya";
import NewKriyasil from "./pages/kriyasil/Newkriyasil";
import Oldkriyasil from "./pages/kriyasil/Oldkriyasil";
import ViewKriyasil from "./pages/kriyasil/ViewKriyasil";
import { EditProfile, Profile, ResubmitForm, SignUp, UserDetails } from "./pages/user";
import VatriSangathan from "./pages/vatriSangathan/VatriSangathan";
import { WardPresident } from "./pages/wardPresident";
import { getHeaderSection } from "./utils/api/mainBanner";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

export const HomeContext = React.createContext();

const notifySuccess = (message) => toast.success(message);
const notifyError = (message) => toast.error(message);

function App() {
  const [homeData, setHomeData] = React.useState({
    homeMainBannerData: [],
    show: true,
    url: "",
  });

  React.useEffect(() => {
    getHeaderSection()
      .then((res) => {
        setHomeData({
          ...homeData,
          homeMainBannerData: res.data.header_sections,
          url: res.data.asset_url,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="App">
      <HomeContext.Provider value={{ homeData, setHomeData }}>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Router>
          <PopupImage />
          <Topnav />
          <HeaderNavbar />
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/media/videos/" element={<Video />} />
            <Route path="/media/photos/" element={<Photo />} />
            <Route path="/contactus/" element={<Contact />} />
            <Route path="/events/" element={<Events />} />
            <Route path="/event/:id" element={<Event />} />
            <Route path="/notices/" element={<Notices />} />
            <Route path="/notice/:id/" element={<Notice />} />
            <Route path="/media/blogs/" element={<Blogs />} />
            <Route path="/blog/:id/" element={<Blog />} />
            <Route path="/media/pressreleases/" element={<PressReleases />} />
            <Route path="/pressRelease/:id/" element={<PressRelease />} />
            <Route path="/login" element={<Login />} />
            <Route path="/resetPassword/" element={<ResetPassword />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/about/committee/" element={<Committee />} />
            <Route path="/about/members/" element={<Member />} />
            <Route path="/aboutparty/history/" element={<PartyHistory />} />
            <Route path="/about/presidentmessage/" element={<WardPresident />} />
            <Route path="/about/vatriSangathan/" element={<VatriSangathan />} />
            <Route path="/about/vatriSangathan/:id/" element={<Vatri />} />
            <Route path="/about/highprofile/" element={<HighProfiles />} />
            <Route path="/about/highprofile/:id/" element={<HighProfile />} />
            <Route path="/resubmit/" element={<ResubmitForm />} />
            <Route path="/aboutparty/nepalconstitution/" element={<ConstitutionOfNepal />} />
            <Route path="/aboutparty/constitution/" element={<PartyConstitution />} />
            <Route path="/workingcommitte" element={<Workingcommitte />} />
            <Route path="/workingcommitte/pdfview" element={<WorkingConst />} />
            <Route path="/aboutparty/constitution/:id/" element={<PartyConst />} />
            <Route path="/presidentmessage/:id/" element={<PresidentMessage />} />
            <Route path="/aboutparty/rules/" element={<RulesAndRegulation />} />
            <Route path="/aboutparty/rules/:id/" element={<RulesConst />} />
            <Route path="/about/activemembers/" element={<ActiveMember />} />
            <Route path="/about/generalmembers/" element={<GeneralMember />} />
            <Route path="/underreview/" element={<UnderReview />} />
            <Route path="/aboutparty/parichaya/" element={<Parichaya />} />
            <Route path="/kriyasil/new/" element={<NewKriyasil />} />
            <Route path="/kriyasil/old/" element={<Oldkriyasil />} />
            <Route path="/viewkriyasil/:region/:pdf/:type/" element={<ViewKriyasil />} />
            <Route
              path="/auth/*"
              element={
                <AuthRoutes redirectTo="/login">
                  <Routes>
                    <Route path="/financialstatements/" element={<FinancialStatements />} />
                    <Route path="/financialstatement/:id/" element={<FinancialStatement />} />
                    <Route path="/user/profile" element={<Profile />} />
                    <Route path="/user/profile/edit/" element={<EditProfile />} />
                    <Route path="/user/details/" element={<UserDetails />} />
                    <Route path="/user/Feedback/" element={<Feedback />} />

                    <Route path="/user/contributions/" element={<Contribution />} />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </AuthRoutes>
              }
            />

            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
        </Router>
      </HomeContext.Provider>
    </div>
  );
}

export default App;

export { notifyError, notifySuccess };
