import { getNepaliNumber } from "../../utils/nepaliLocale/nepaliNumerization";

const accessor = (item, header, t, page, itemIndex, length, language) => {
  if (header === "Sn") {
    return language === "en"
      ? (page - 1) * length + itemIndex + 1
      : getNepaliNumber((page - 1) * length + itemIndex + 1 + "");
  } else if (header === "gender") {
    return t(item[header]);
  } else if (header === "identification_number") {
    return language === "en" ? item[header] : getNepaliNumber(item[header]);
  } else if (header === "phone_number") {
    if (item[header]) {
      return language === "en" ? item[header] : getNepaliNumber(item[header]);
    }
  } else {
    return item[header];
  }
};

export { accessor };
