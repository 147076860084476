import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./UnderReview.module.css";

function UnderReview() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.containerWrapper}>
      <div className={styles.container}>
        <div className={styles.title}>Your application is under review.</div>

        <div className={styles.linkContainer}>
          Go back to{" "}
          <div onClick={() => navigate("/")} className={styles.link}>
            Home
          </div>
        </div>
      </div>
    </div>
  );
}

export default UnderReview;
