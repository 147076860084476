import cx from "classnames";
import React from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AiOutlineFilePdf, AiTwotoneCalendar } from "react-icons/ai";
import { Link } from "react-router-dom";
import Hero from "../../components/Hero/Hero";
import PDFViewerThumbnail from "../../components/PDFViewer/PDFViewerThumbnail";
import { partyItems } from "./rules";
import styles from "./RulesAndRegulation.module.css";

function RulesAndRegulation() {
  const { t } = useTranslation("common");
  const { i18n } = useTranslation();
  return (
    <main className={styles.containerWrapper}>
      <Hero title={t("rulesAndRegulations")} />
      <Container>
        <div className={styles.cardContainer}>
          {partyItems.map((item, index) => {
            return (
              <div className={styles.card} key={index}>
                <div className={styles.photos}>
                  <PDFViewerThumbnail fileUrl={item.path} />
                </div>
                <div className={styles.eventDetails}>
                  <div className={styles.detailsContainer}>
                    <div className={styles.eventTitle}>{i18n.language === "en" ? item.enTitle : item.npTitle}</div>
                    <div className={styles.eventDate}>
                      <AiTwotoneCalendar />
                      {i18n.language === "en" ? item.enDate : item.npDate}
                    </div>
                  </div>
                  <div className={styles.buttonContainer}>
                    <Link to={`/aboutparty/rules/${item.id}`}>
                      <button className={styles.button}>{t("viewMore")}</button>
                    </Link>
                    <a href={item.path} download target="_blank">
                      <button className={cx(styles.buttonIconContainer, styles.button)}>
                        <div className={styles.pdfDownload}>
                          <AiOutlineFilePdf fontSize="20px" />
                        </div>
                        <div className={styles.downloadTitle}>{t("download")}</div>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Container>
    </main>
  );
}

export default RulesAndRegulation;
