import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { NavDropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import useWindowDimensions from "../../common/useWindowDimensions";
import { getCookie } from "../../utils/cookie/cookies";

const NavDropdownItem = ({ item, onItemClick }) => {
  const { t } = useTranslation("navbar");
  const { width } = useWindowDimensions();
  const dropdownShow = width > 992 ? { show: true } : {};
  const [showDropdown, setShowDropdown] = useState(false);
  return (
    <NavDropdown
      title={t(item.title)}
      id="collasible-nav-dropdown"
      onMouseEnter={() => {
        setShowDropdown(true);
      }}
      onMouseLeave={() => {
        setShowDropdown(false);
      }}
      {...dropdownShow}
      onSelect={(e) => {
        // console.log(e);
      }}
    >
      {showDropdown &&
        item.dropdown.map((dropdownElement, dropdownIndex) => {
          if (dropdownElement.shouldHide) {
            if (getCookie("token")) {
              return (
                <NavLink
                  className={cx("nav-link nav-dropdown-item")}
                  key={dropdownElement.path}
                  to={dropdownElement.path}
                  onClick={onItemClick}
                >
                  {t(dropdownElement.title)}
                </NavLink>
              );
            }
          } else {
            return (
              <NavLink
                className={cx("nav-link nav-dropdown-item")}
                key={dropdownElement.path}
                to={dropdownElement.path}
                onClick={onItemClick}
              >
                {t(dropdownElement.title)}
              </NavLink>
            );
          }
        })}
    </NavDropdown>
  );
};

export default NavDropdownItem;
