import React from "react";
import { Table } from "react-bootstrap";
import { accessor } from "../../pages/activeMember/accessor";
import styles from "./Table.module.css";

function TableShow({ data, headerList, length, page, t, language }) {
  return (
    <div className={styles.container}>
      <Table striped bordered hover>
        <thead>
          <tr>
            {headerList.map((header, index) => (
              <th key={index}>{t(header)}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item, itemIndex) => (
              <tr key={item.id}>
                {headerList.map((header, index) => {
                  return (
                    <td key={index}>
                      {accessor(item, header, t, page, itemIndex, length, language)}
                    </td>
                  );
                })}
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
}

export default TableShow;
