import { routes } from "../../common/routes";

const footerItems = [
  {
    header: "quick_links",
    shouldTranslate: true,
    md: 2,
    list: [
      {
        shouldTranslate: true,
        title: "nepal_constitution",
        path: routes.aboutParty.nepalConstitution,
      },
      {
        shouldTranslate: true,
        title: "rules",
        path: routes.aboutParty.partyRules,
      },
      {
        shouldTranslate: true,
        title: "party_constitution",
        path: routes.aboutParty.partyConstitution,
      },
    ],
  },
  {
    header: "office",
    shouldTranslate: true,
    md: 1,
    list: [
      {
        title: "contact_us",
        shouldTranslate: true,
        path: routes.contactUs,
      },
      {
        title: "news",
        shouldTranslate: true,
        path: routes.media.news,
      },
      {
        title: "events",
        shouldTranslate: true,
        path: routes.events,
      },
    ],
  },
  {
    header: "website",
    shouldTranslate: true,
    md: 2,
    list: [
      {
        title: "terms",
        shouldTranslate: true,
        path: routes.terms,
      },
      {
        title: "privacy_policy",
        shouldTranslate: true,
        path: routes.privacyPolicy,
      },
    ],
  },
  {
    header: "media",
    shouldTranslate: true,
    md: 1,
    list: [
      {
        title: "photos",
        shouldTranslate: true,
        path: routes.media.photoGallery,
      },
      {
        title: "videos",
        shouldTranslate: true,
        path: routes.media.videoGallery,
      },
    ],
  },
  {
    header: "contact",
    shouldTranslate: true,
    md: 3,
    list: [
      {
        title: "tel",
        shouldTranslate: true,
        value: "phone_number",
      },
      {
        title: "email",
        shouldTranslate: true,
        value: "email_address",
      },
    ],
  },
];

export { footerItems };
