import { routes } from "../../common/routes";

const navItems = [
  { title: "home", path: routes.home, shouldTranslate: true },
  {
    title: "about_party",
    shouldTranslate: true,
    dropdown: [
      {
        title: "party_history",
        path: routes.aboutParty.partyHistory,
        shouldTranslate: true,
      },
      {
        title: "party_constitution",
        path: routes.aboutParty.partyConstitution,
        shouldTranslate: true,
      },
      {
        title: "nepal_constitution",
        path: routes.aboutParty.nepalConstitution,
        shouldTranslate: true,
      },
      {
        title: "party_rules",
        path: routes.aboutParty.partyRules,
        shouldTranslate: true,
      },
    ],
  },
  {
    title: "media",
    shouldTranslate: true,
    dropdown: [
      {
        title: "photo_gallery",
        path: routes.media.photoGallery,
        shouldTranslate: true,
      },
      {
        title: "video_gallery",
        path: routes.media.videoGallery,
        shouldTranslate: true,
      },
      {
        title: "press_release",
        path: routes.media.pressRelease,
        shouldTranslate: true,
      },
      {
        title: "news_and_blogs",
        path: routes.media.news,
        shouldTranslate: true,
      },
    ],
  },

  { title: "events", path: routes.events, shouldTranslate: true },
  { title: "notices", path: routes.notices, shouldTranslate: true },
  {
    title: "about_us",
    shouldTranslate: true,
    dropdown: [
      {
        title: "parichaya",
        path: routes.aboutParty.parichaya,
        shouldTranslate: true,
      },
      {
        title: "president_message",
        path: routes.about.presidentMessage,
        shouldTranslate: true,
      },
      {
        title: "executive_committee",
        path: routes.about.executiveCommittee,
        shouldTranslate: true,
      },
      {
        title: "kaski_party",
        path: routes.about.members,
        shouldTranslate: true,
      },
      // {
      //   title: "high_profile",
      //   path: routes.about.highProfile,
      //   shouldTranslate: true,
      // },
      {
        title: "active_members",
        path: routes.about.activeMembers,
        shouldTranslate: true,
      },

      // {
      //   title: "general_members",
      //   path: routes.about.generalMembers,
      //   shouldTranslate: true,
      // },
      // {
      //   title: "annual_plan",
      //   path: routes.about.annualPlan,
      //   shouldTranslate: true,
      // },
      {
        title: "vatri_sangathan",
        path: routes.about.vatriSangathan,
        shouldTranslate: true,
      },
      {
        title: "financial_statements",
        path: "/auth/financialstatements/",
        shouldTranslate: true,
        shouldHide: true,
      },
    ],
  },
  {
    title: "working_committe",
    path: routes.working_committe,
    shouldTranslate: true,
  },
];

export { navItems };
