import "bootstrap/dist/css/bootstrap.min.css";
import cx from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AiOutlineFilePdf, AiTwotoneCalendar } from "react-icons/ai";
import { Link } from "react-router-dom";
import { LoadingCard } from "../../components";
import Hero from "../../components/Hero/Hero";
import Pagination from "../../components/Pagination/Pagination";
import PDFViewerThumbnail from "../../components/PDFViewer/PDFViewerThumbnail";
import { getFinancialStatements } from "../../utils/api/financialStatement";
import { timer } from "../../utils/timer/timer";
import styles from "./FinancialStatements.module.css";

function FinancialStatements() {
  const [financialStatements, setFinancialStatements] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const lastPage = useRef(1);
  const { i18n } = useTranslation();
  const { t } = useTranslation("common");

  const assetUrl = useRef(null);

  useEffect(() => {
    let mounted = true;
    let language = i18n.language === "en" ? "english" : "nepali";
    const fetchFinancialStatements = async () => {
      setLoading(true);
      await getFinancialStatements(page, language)
        .then((res) => {
          if (res.status === "success") {
            if (page === 1) {
              lastPage.current = res.data.last_page;
              assetUrl.current = res.data.asset_url;
            } else {
            }
            setFinancialStatements(res.data.financial_statements);
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          //toast Error
        });
    };
    if (mounted) {
      fetchFinancialStatements();
    }
    return () => (mounted = false);
  }, [page, i18n.language]);

  return (
    <main className={styles.containerWrapper}>
      <Hero title={t("financialStatementTitle")} />
      <Container>
        <div className={styles.cardContainer}>
          {loading ? (
            [...Array(financialStatements.length > 0 ? financialStatements.length : 5)].map((element, index) => (
              <LoadingCard
                key={index}
                cardStyle={styles.card}
                cardDetailsStyle={styles.eventDetails}
                photoStyle={styles.photos}
              />
            ))
          ) : financialStatements.length > 0 ? (
            financialStatements.map((financialStatement, index) => {
              return (
                <div className={styles.card} key={index}>
                  <div className={styles.photos}>
                    <PDFViewerThumbnail fileUrl={assetUrl.current + financialStatement.file} />
                  </div>
                  <div className={styles.eventDetails}>
                    <div className={styles.detailsContainer}>
                      <div className={styles.eventTitle}>{financialStatement.title}</div>
                      <div className={styles.eventDate}>
                        <AiTwotoneCalendar />
                        {timer(financialStatement.created_at, i18n.language)}
                      </div>
                    </div>
                    <div className={styles.buttonContainer}>
                      <Link
                        to={`/auth/financialstatement/${financialStatement.id}`}
                        state={{ financialStatementData: financialStatement }}
                      >
                        <button className={styles.button}>{t("viewMore")}</button>
                      </Link>
                      <a href={assetUrl.current + financialStatement.file} download target="_blank" rel="noreferrer">
                        <button className={cx(styles.buttonIconContainer, styles.button)}>
                          <div className={styles.pdfDownload}>
                            <AiOutlineFilePdf fontSize="20px" />
                          </div>
                          <div className={styles.downloadTitle}>{t("download")}</div>
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="no-data-to-show">{t("noMoreUpcoming")}</div>
          )}
        </div>
        <Pagination page={page} setPage={setPage} lastPage={lastPage.current} />
      </Container>
    </main>
  );
}

export default FinancialStatements;
