import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Member from "../../components/Member/Member";
import styles from "./Commitee.module.css";

const getImage = (img) => {
  if (img && img.length > 0) {
    return img;
  } else {
    return "/static/img/user.png";
  }
};

function Members() {
  const { t } = useTranslation("membersparty");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <Hero title={t("executiveMember")} /> */}
      <Container className={styles.comitteeCont} style={{ padding: "50px 0" }}>
        <Row>
          {t("members.list", { returnObjects: true })?.map((element) => (
            <Col key={element.id} md={3} xs={3}>
              <Member
                name={element.name}
                post={element.post}
                image={getImage(element.image)}
                fbLink={element.facebook}
                phone={element.phone}
              />
            </Col>
          ))}
        </Row>
        {/* <Row>
          <h1 className="text-center mt-4">
            {i18n.language === "en" ? "Advisory Committee" : "सल्लाहकार समिति"}
          </h1>

          {t("advisors.list", { returnObjects: true }).map((element) => (
            <Col key={element.id} md={3} xs={6}>
              <Member
                name={element.name}
                post={t("advisors.designation")}
                image={getImage(element.image)}
                fbLink={element.facebook}
                phone={element.phone}
              />
            </Col>
          ))}
        </Row> */}

        {/* <Row>
          {t("mediaadvisor.list", { returnObjects: true }).map((element) => (
            <Col key={element.id} md={3} xs={6}>
              <Member
                name={element.name}
                post={t("mediaadvisor.designation")}
                image={getImage(element.image)}
                fbLink={element.facebook}
              />
            </Col>
          ))}
        </Row> */}

        {/* <Row>

            <Member />
            <Member />
            <Member />
            <Member />
            <Member />




            </Row> */}
      </Container>
    </>
  );
}

export default Members;
