import React from "react";
import { useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { getCookie } from "../../utils/cookie/cookies";
import NavDropdownItem from "./NavDropdownItem";
import useWindowDimensions from "../../common/useWindowDimensions";
import { navItems } from "./navItems";
import NavLogin from "./NavLogin";

const HeaderNavbar = () => {
  const { t } = useTranslation("navbar");
  const [expanded, setExpanded] = useState(false);
  const { width } = useWindowDimensions();

  const collapseNavbar = () => {
    if (width <= 992) {
      setExpanded(!expanded);
    }
  };
  return (
    <Navbar
      collapseOnSelect
      e
      expand="lg"
      bg="primary"
      variant="dark"
      sticky="top"
      className="bottom-header p-0"
      style={{ zIndex: "2" }}
      expanded={expanded}
    >
      <Container id="navbar-container">
        {/* <Nav.Link href="/">Home</Nav.Link> */}
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={collapseNavbar}
        />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            {navItems.map((element, index) => {
              if (element.dropdown) {
                return (
                  <NavDropdownItem
                    item={element}
                    key={element.title}
                    onItemClick={collapseNavbar}
                  />
                );
              } else if (element.shouldHide) {
                if (getCookie("token")) {
                  return (
                    <NavLink
                      className="nav-link"
                      key={element.path}
                      to={element.path}
                      onClick={collapseNavbar}
                    >
                      {t(element.title)}
                    </NavLink>
                  );
                }
              } else {
                return (
                  <NavLink
                    className="nav-link"
                    key={element.path}
                    to={element.path}
                    onClick={collapseNavbar}
                  >
                    {t(element.title)}
                  </NavLink>
                );
              }
            })}
          </Nav>
        </Navbar.Collapse>
        <NavLogin />
      </Container>
    </Navbar>
  );
};

export default HeaderNavbar;
