export const partyItems = {
  en: [
    {
      title: "General Committee and Convention Representatives",
      sub: [
        {
          name: "General Committee Member",
          pdf: "/websitepdf/samiti/Mahasamiti Sadasya.pdf",
        },
        {
          name: "Convention Delegate",
          pdf: "/websitepdf/samiti/Mahadhibesan Pratinidhi List Final.pdf",
        },

        {
          name: "Province Convention Delegate",
          pdf: "/websitepdf/samiti/pradeshsabhakaryasamitiwithregions specified.pdf",
        },

        {
          name: "Regional Convention Delegate",
          pdf: "/websitepdf/samiti/chetriyapratinidhi.pdf",
        },
      ],
    },
    {
      title: "Regional / Province Assembly Working Committee",
      sub: [
        {
          name: "Regional Working Committee, Region No. 1, Kaski",
          pdf: "/websitepdf/chetriyapradeshsabha/Region No.1 Working Committee.pdf",
        },
        {
          name: "Regional Working Committee, Region No. 2, Kaski",
          pdf: "/websitepdf/chetriyapradeshsabha/Region No. 2 Working Committee.pdf",
        },
        {
          name: "Regional Working Committee, Region No. 3, Kaski",
          pdf: "/websitepdf/chetriyapradeshsabha/Region No. 3 Working Committee.pdf",
        },
        {
          name: "Provincial Assembly Working Committee, Area No. 1(a), Kaski",
          pdf: "/websitepdf/chetriyapradeshsabha/1a.pdf",
        },
        {
          name: "Provincial Assembly Working Committee, Area No. 1(b), Kaski",
          pdf: "/websitepdf/chetriyapradeshsabha/1b.pdf",
        },
        {
          name: "Provincial Assembly Working Committee, Area No. 2(a), Kaski",
          pdf: "/websitepdf/chetriyapradeshsabha/2a.pdf",
        },
        {
          name: "Provincial Assembly Working Committee, Area No. 2(b), Kaski",
          pdf: "/websitepdf/chetriyapradeshsabha/2b.pdf",
        },
        {
          name: "Provincial Assembly Working Committee, Area No. 3(a), Kaski",
          pdf: "/websitepdf/chetriyapradeshsabha/3a.pdf",
        },
        {
          name: "Provincial Assembly Working Committee, Area No. 3(b), Kaski",
          pdf: "/websitepdf/chetriyapradeshsabha/3b.pdf",
        },
      ],
    },
    {
      title: "Metropolitan / Village Municipal Working Committee",

      sub: [
        {
          name: "Pokhara Metropolitan Working Committee",
          pdf: "/websitepdf/palikalist/Pokhara Metropolitan Committee with contact.pdf",
        },
        {
          name: "Rupa Village Municipal Working Committee",
          pdf: "/websitepdf/palikalist/Rupa Working Committee.pdf",
        },
        {
          name: "Madi Village Municipal Working Committee",
          pdf: "/websitepdf/palikalist/Madi Ga.Pa.Working Committee.pdf",
        },
        {
          name: "Annapurna Village Municipal Working Committee",
          pdf: "/websitepdf/palikalist/Annapurna Ga.Pa.Working Committee.pdf",
        },
        {
          name: "Macchapuchhre Village Municipal Working Committee",
          pdf: "/websitepdf/palikalist/MacchaPucchre Ga.Pa.Working Committee.pdf",
        },
      ],
    },
  ],
  np: [
    {
      title: "महासमिति तथा महाधिवेशन प्रतिनिधीहरु",
      sub: [
        {
          name: "महासमिति सदस्य",
          pdf: "/websitepdf/samiti/Mahasamiti Sadasya.pdf",
        },
        {
          name: "महाधिवेशन प्रतिनिधी",
          pdf: "/websitepdf/samiti/Mahadhibesan Pratinidhi List Final.pdf",
        },

        {
          name: "प्रदेश अधिवेशन प्रतिनिधी",
          pdf: "/websitepdf/samiti/pradeshsabhakaryasamitiwithregions specified.pdf",
        },

        {
          name: "क्षेत्रीय अधिवेशन प्रतिनिधी",
          pdf: "/websitepdf/samiti/chetriyapratinidhi.pdf",
        },
      ],
    },
    {
      title: "क्षेत्रीय / प्रदेश सभा कार्यसमिति",
      sub: [
        {
          name: "क्षेत्रीय कार्यसमिति, क्षेत्र नं. १, कास्की",
          pdf: "/websitepdf/chetriyapradeshsabha/Region No.1 Working Committee.pdf",
        },
        {
          name: "क्षेत्रीय कार्यसमिति, क्षेत्र नं. २, कास्की",
          pdf: "/websitepdf/chetriyapradeshsabha/Region No. 2 Working Committee.pdf",
        },
        {
          name: "क्षेत्रीय कार्यसमिति, क्षेत्र नं. ३, कास्की",
          pdf: "/websitepdf/chetriyapradeshsabha/Region No. 3 Working Committee.pdf",
        },
        {
          name: "प्रदेश सभा कार्यसमिति, क्षेत्र नं. १(क), कास्की",
          pdf: "/websitepdf/chetriyapradeshsabha/1a.pdf",
        },
        {
          name: "प्रदेश सभा कार्यसमिति, क्षेत्र नं. १(ख), कास्की",
          pdf: "/websitepdf/chetriyapradeshsabha/1b.pdf",
        },
        {
          name: "प्रदेश सभा कार्यसमिति, क्षेत्र नं. २(क), कास्की",
          pdf: "/websitepdf/chetriyapradeshsabha/2a.pdf",
        },
        {
          name: "प्रदेश सभा कार्यसमिति, क्षेत्र नं. २(ख), कास्की",
          pdf: "/websitepdf/chetriyapradeshsabha/2b.pdf",
        },
        {
          name: "प्रदेश सभा कार्यसमिति, क्षेत्र नं. ३(क), कास्की",
          pdf: "/websitepdf/chetriyapradeshsabha/3a.pdf",
        },
        {
          name: "प्रदेश सभा कार्यसमिति, क्षेत्र नं. ३(ख), कास्की",
          pdf: "/websitepdf/chetriyapradeshsabha/3b.pdf",
        },
      ],
    },
    {
      title: "महानगर / गाउँ पालिका कार्य समिति",

      sub: [
        {
          name: "पोखरा महानगरपालिका कार्य समिति",
          pdf: "/websitepdf/palikalist/Pokhara Metropolitan Committee with contact.pdf",
        },
        {
          name: "रूपा गाउँ पालिका कार्य समिति",
          pdf: "/websitepdf/palikalist/Rupa Working Committee.pdf",
        },
        {
          name: "मादी गाउँ पालिका कार्य समिति",
          pdf: "/websitepdf/palikalist/Madi Ga.Pa.Working Committee.pdf",
        },
        {
          name: "अन्नपूर्ण गाउँ पालिका कार्य समिति",
          pdf: "/websitepdf/palikalist/Annapurna Ga.Pa.Working Committee.pdf",
        },
        {
          name: "माछापुच्र्छे गाउँ पालिका कार्य समिति",
          pdf: "/websitepdf/palikalist/MacchaPucchre Ga.Pa.Working Committee.pdf",
        },
      ],
    },
  ],
};
