import { baseGetRequest, baseRequest } from "./base";

const getUserById = async (id, token) => {
  var response = await baseGetRequest("/api/users/" + id, token);
  return response;
};

const getActiveUsers = async (page, language) => {
  var response = await baseGetRequest(
    `/api/showusers?language=${language}&page=${page}&user_type=active`
  );
  return response;
};

const getGeneralUsers = async (page, language) => {
  var response = await baseGetRequest(
    `/api/showusers?language=${language}&page=${page}&user_type=general`
  );
  return response;
};

const getBasicDetails = async (id) => {
  var response = await baseGetRequest("/api/users/" + id);
  return response;
};

const getContributions = async (userId) => {
  var response = await baseGetRequest(`/api/users/${userId}/contributions`);
  return response;
};

const getResponsibilities = async (userId) => {
  var response = await baseGetRequest(`/api/users/${userId}/responsibilities`);
  return response;
};

const updateProfilePicture = async (userId, picture) => {
  let data = new FormData();
  data.append("profile_picture", picture);
  var response = await baseRequest(
    `/api/users/${userId}/profilepicture`,
    "POST",
    data
  );
  return response;
};

const removeProfilePicture = async (userId) => {
  var response = await baseRequest(
    `/api/users/${userId}/profilepicture`,
    "DELETE"
  );
  return response;
};

const getProfileDetails = async (userId) => {
  var response = await baseGetRequest(`/api/users/${userId}/profile`);
  return response;
};

const updatePhone = async (userId, data) => {
  var response = await baseRequest(`/api/users/${userId}/phone`, "POST", data);
  return response;
};

const updateEmail = async (userId, data) => {
  var response = await baseRequest(`/api/users/${userId}/email`, "POST", data);
  return response;
};

const updateUser = async (id, bodyData, citizenship) => {
  const data = new FormData();

  let body = JSON.parse(JSON.stringify(bodyData));
  delete body["profile_picture"];
  delete body["citizenship_document"];
  delete body["user_type"];
  delete body["identification_number"];
  delete body["email"];
  delete body["phone_number"];

  Object.keys(body.nepali).forEach((key) => {
    if (body.nepali[key] !== null) {
      data.append("nepali[" + key + "]", body.nepali[key]);
    }
  });

  delete body["nepali"];

  Object.keys(body.english).forEach((key) => {
    if (body.english[key] !== null) {
      data.append("english[" + key + "]", body.english[key]);
    }
  });
  delete body["english"];

  body.children.forEach((element, index) => {
    data.append(
      "children[data][" + index + "][english][name]",
      element.english.name
    );
    data.append(
      "children[data][" + index + "][nepali][name]",
      element.nepali.name
    );
    if (element.id) {
      data.append("children[data][" + index + "][id]", element.id);
    }
  });

  body.removeId?.forEach((element) => {
    data.append("children[delete_ids][]", element);
  });

  delete body["removeId"];

  delete body["children"];

  Object.keys(body).forEach((key) => {
    if (body[key] !== null) {
      data.append(key, body[key]);
    }
  });

  if (citizenship) {
    data.append("citizenship_document", citizenship);
  }

  var response = await baseRequest(
    "/api/users/" + id + "/update",
    "POST",
    data
  );
  return response;
};

const updateRejectedUser = async (id, bodyData, citizenship, token) => {
  const data = new FormData();

  let body = JSON.parse(JSON.stringify(bodyData));
  delete body["profile_picture"];
  delete body["citizenship_document"];
  delete body["user_type"];

  Object.keys(body.nepali).forEach((key) => {
    if (body.nepali[key] !== null) {
      data.append("nepali[" + key + "]", body.nepali[key]);
    }
  });

  delete body["nepali"];

  Object.keys(body.english).forEach((key) => {
    if (body.english[key] !== null) {
      data.append("english[" + key + "]", body.english[key]);
    }
  });
  delete body["english"];

  Object.keys(body).forEach((key) => {
    if (body[key] !== null) {
      data.append(key, body[key]);
    }
  });

  if (citizenship) {
    data.append("citizenship_document", citizenship);
  }

  var response = await baseRequest(
    "/api/users/" + id + "/updaterejected",
    "POST",
    data,
    token
  );
  return response;
};

const signupUser = async (id, bodyData, citizenship) => {
  const data = new FormData();

  let body = JSON.parse(JSON.stringify(bodyData));

  Object.keys(body.nepali).forEach((key) => {
    if (body.nepali[key] !== null) {
      data.append("nepali[" + key + "]", body.nepali[key]);
    }
  });

  delete body["nepali"];

  Object.keys(body.english).forEach((key) => {
    if (body.english[key] !== null) {
      data.append("english[" + key + "]", body.english[key]);
    }
  });
  delete body["english"];

  body.children.forEach((element, index) => {
    data.append(
      "children[data][" + index + "][english][name]",
      element.english.name
    );
    data.append(
      "children[data][" + index + "][nepali][name]",
      element.nepali.name
    );
    if (element.id) {
      data.append("children[data][" + index + "][id]", element.id);
    }
  });

  delete body["children"];

  Object.keys(body).forEach((key) => {
    if (body[key] != null) {
      data.append(key, body[key]);
    }
  });

  if (citizenship) {
    data.append("citizenship_document", citizenship);
  }

  var response = await baseRequest("/api/signup", "POST", data);
  return response;
};

export {
  getUserById,
  getBasicDetails,
  getContributions,
  getResponsibilities,
  updateProfilePicture,
  removeProfilePicture,
  getProfileDetails,
  updatePhone,
  updateEmail,
  updateUser,
  getActiveUsers,
  getGeneralUsers,
  signupUser,
  updateRejectedUser,
};
