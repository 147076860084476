import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./NotFound.module.css";

function NotFound() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.containerWrapper}>
      <div className={styles.container}>
        <div className={styles.title}>404</div>
        <div className={styles.subtitle}>Page Not Found</div>
        <div className={styles.description}>
          The resource request could not be found on this server
        </div>
        <div className={styles.linkContainer}>
          Go back to{" "}
          <div onClick={() => navigate("/")} className={styles.link}>
            Home
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
