import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import PDFViewer from "../../components/PDFViewer/PDFViewer";
import styles from "./Kriyasil.module.css";
import { useState } from "react";

function ViewKriyasil() {
  const { region, pdf, type } = useParams();
  const [numPages, setNumPages] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  console.log(
    "/websitepdf/activemembers/" + type + "/" + region + "/" + pdf + ".pdf"
  );

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <div>
      {true && (
        <section className={styles.containerWrapper}>
          <div className={styles.container}>
            <Container>
              <div className={styles.constitutionDetails}>
                <div className={styles.constitutionTitle}>
                  {/* {i18n.language === "en" ? item.enTitle : item.npTitle} */}
                </div>
                <div className={styles.constitutionDate}>
                  {/* <AiTwotoneCalendar /> */}
                  {/* {i18n.language === "en" ? item.enDate : item.npDate} */}
                </div>

                <PDFViewer
                  showAllPage={true}
                  src={
                    "/websitepdf/activemembers/" +
                      type +
                      "/" +
                      region +
                      "/" +
                      pdf +
                      ".pdf" || "/committe/2_8.pdf"
                  }
                />
              </div>
            </Container>
          </div>
        </section>
      )}
    </div>
  );
}

export default ViewKriyasil;
