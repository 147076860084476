import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import historicalFigureData from "../../assets/json/historical-figures.json";
import { useTranslation } from "react-i18next";

const HistoricalFigures = () => {
  const { i18n } = useTranslation();
  const { t } = useTranslation("home");

  return (
    <section className="main-section">
      <Container>
        <Row>
          <Col md={12}>
            <div className="bg-primary historical-figure ">
              <div className="section-title d-flex text-center justify-content-center align-items-center w-100">
                <img height={100} src="/static/img/waving-flag.gif" alt="" />
                <h3 className="text-white text-center px-2">
                  {t("historical_figure_title")}
                </h3>
                <img height={100} src="/static/img/waving-flag.gif" alt="" />
              </div>

              <div className="figure-wrapper">
                {historicalFigureData.map((element, index) => (
                  <div className="figure-list" key={element.id}>
                    <div className="figure-image">
                      <img src={element.image} alt="" />
                    </div>
                    <p>
                      {i18n.language === "en"
                        ? element.en_name
                        : element.np_name}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HistoricalFigures;
