import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { partyItems } from "./data";
import styles from "./WorkingCommitte.module.css";

function WorkingCommitte() {
  const { i18n } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className={styles.containerWrapper}>
      <Container>
        <div className={styles.cardContainer}>
          {partyItems[i18n.language === "np" ? "np" : "en"].map((item, index) => {
            return (
              <div className={styles.card} key={index}>
                <div className={styles.cardTitle}> {item.title}</div>
                {item.sub.map((dat, i) => (
                  <div className={styles.cardPdfs} key={i}>
                    <Link to={{ pathname: `/workingcommitte/pdfview?pdf=${dat.pdf}` }}>
                      <img src={"/pdf.png"} className={styles.pdf} alt="" />
                      {dat.name}
                    </Link>
                    <a href={dat.pdf} download target="_blank">
                      <text className={styles.download}> Download</text>
                    </a>
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      </Container>
    </main>
  );
}

export default WorkingCommitte;
