import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AiTwotoneCalendar } from "react-icons/ai";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import { Loading } from "../../components/Loading";
import { notifyError } from "../../App";
import { getPressReleaseById } from "../../utils/api/pressReleases";
import { axiosErrorHandler } from "../../utils/errorHandle/axiosErrorHandler";
import { timer } from "../../utils/timer/timer";
import styles from "./Index.module.css";
import "./style.scss";

function PressRelease(props) {
  const assetUrl = useRef(null);
  const [pressReleaseData, setPressReleaseData] = useState({
    images: [],
  });
  const { i18n } = useTranslation();
  const [loading, setLoading] = useState(true);

  const { id } = useParams();

  const pressReleasesSliderSetting = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <IoIosArrowForward />,
    prevArrow: <IoIosArrowBack />,
  };
  const fetchpressReleaseData = (id) => {
    setLoading(true);
    getPressReleaseById(id)
      .then((res) => {
        assetUrl.current = res.data.asset_url;
        setPressReleaseData(res.data.press_release);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        axiosErrorHandler(err, notifyError);
      });
  };
  useEffect(() => {
    fetchpressReleaseData(id);
  }, [id]);
  return loading ? (
    <Loading />
  ) : (
    <section className={styles.containerWrapper}>
      <div className={styles.container}>
        <Container>
          <Row>
            <div className="cc-event-details">
              <Col md={12}>
                <div className="events-wrapper">
                  <Slider {...pressReleasesSliderSetting}>
                    {pressReleaseData.images.map((image, index) => {
                      return (
                        <div key={image.id} className={styles.imageContainer}>
                          <img
                            src={`${assetUrl.current}/${image.image}`}
                            alt=""
                            className={styles.image}
                          />
                        </div>
                      );
                    })}
                  </Slider>
                </div>
              </Col>
            </div>
          </Row>

          <div className={styles.eventDetails}>
            <div className={styles.eventTitle}>
              {i18n.language === "en"
                ? pressReleaseData.english.title
                : pressReleaseData.nepali.title}
            </div>
            <div className={styles.eventDate}>
              <AiTwotoneCalendar />
              {timer(pressReleaseData.created_at, i18n.language)}
            </div>
            {/* <div></div> */}
            {/* <div
              className={styles.eventDescription}
              dangerouslySetInnerHTML={{
                __html:
                  i18n.language === "en" ? pressReleaseData.english.description : pressReleaseData.nepali.description,
              }}
            ></div> */}
          </div>
        </Container>
      </div>
    </section>
  );
}

export default PressRelease;
